import React, { useEffect, useState } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import SectionMain from "../../SectionMain";
import Title from "../../Title";
import icone from "../../../assets/images/icone-meditabem.png";
import ButtonMedita from "../../ButtonMedita";

const useStyles = makeStyles({
  root: {
    flexDirection: "column",
    justifyContent: "space-between",
    width: 288,
  },
});

const StyledLabel = withStyles({
  label: {
    fontSize: 14,
  },
})(FormControlLabel);

const RateChangePlan = ({
  alignItems = "center",
  plans = [],
  setSelectPlan,
  setchangePlan,
  selectPlan,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(parseInt(event.target.value));
  };

  const finish = () => {
    if (value) {
      const planSelect = plans.filter((p) => p.id === value)[0];
      setSelectPlan(planSelect);
      setchangePlan(false);
    }
  };

  useEffect(() => {
    if (selectPlan.id) setValue(selectPlan.id);
  }, [selectPlan]);

  const align = {
    left: "left",
    center: "center",
  };

  return (
    <>
      <img alt="MeditaBem" src={icone} height="50%" width="50px" />
      <SectionMain noPadding position={align[alignItems]}>
        <SectionMain
          position="center"
          direction="column"
          className={classes.root}
        >
          <Title fontWeight="600" size="large" align={align[alignItems]}>
            Planos disponíveis
          </Title>

          <FormControl component="fieldset" color="primary">
            <RadioGroup
              aria-label="plan"
              name="plan1"
              value={value}
              onChange={handleChange}
            >
              {plans
                .filter((p) => p.id !== 1)
                .map((plan) => (
                  <div
                    style={{
                      margin: "10px",
                      backgroundColor: "#f4f4f4",
                      alignItems: "flex-start",
                      borderRadius: "22px",
                    }}
                  >
                    <StyledLabel
                      id="form-label"
                      value={plan.id}
                      control={<Radio aria-selected={true} />}
                      label={
                        <>
                          <Title fontWeight="600" size="medium" align="start">
                            {plan.name}
                          </Title>
                          <Title fontWeight="300" size="medium" align="start">
                            {plan.price}
                          </Title>
                        </>
                      }
                    />
                  </div>
                ))}
            </RadioGroup>
          </FormControl>
        </SectionMain>
      </SectionMain>
      <ButtonMedita width={"50%"} type="button" onClick={finish}>
        Continuar
      </ButtonMedita>
    </>
  );
};

export default RateChangePlan;
