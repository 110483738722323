import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  makeStyles,
  Select
} from "@material-ui/core";
import React from "react";
import SectionMain from "../SectionMain";
import Title from "../Title";

const useStyles = makeStyles({
  root: {
    borderRadius: "40px",
    border: "solid 1px #d3d3d3",
    padding: "0 17px",
    borderColor: ({ errorMessage }) => (errorMessage ? "red" : "none"),
    height: ({ id }) => id === "description" && "100%",
    boxShadow: "1px 1px 10px -2px rgba(0,0,0,0.3)",
    marginTop: "0 !important",
  },
  errorMessage: {
    height: 18,
  },
  leftIcon: {
    color: "#008393",
    marginRight: 10,
  },
  rightIcon: {
    color: "#008393",
  },
  formHelperText: {
    color: ({ errorColor }) => errorColor,
    paddingLeft: 12,
  },
  labelInput: {
    top: "-5px",
    left: "30px",
    color: "#6f6f6f",
    opacity: 0.4,
    fontSize: "16px",
    "&.MuiFormLabel-filled": {
      display: "none",
    },
    "&.Mui-focused": {
      display: "none",
    },
  },
});

const options = [
  { label: "Acre (AC)", value: "AC" },
  { label: "Alagoas (AL)", value: "AL" },
  { label: "Amapá (AM)", value: "AM" },
  { label: "Amazonas (AM)", value: "AM" },
  { label: "Bahia (BA)", value: "BA" },
  { label: "Ceará (CE)", value: "CE" },
  { label: "Espírito Santo (ES)", value: "ES" },
  { label: "Goiás (GO)", value: "GO" },
  { label: "Maranhão (MA)", value: "MA" },
  { label: "Mato Grosso (MT)", value: "MT" },
  { label: "Mato Grosso do Sul (MS)", value: "MS" },
  { label: "Minas Gerais (MG)", value: "MG" },
  { label: "Pará (PA)", value: "PA" },
  { label: "Paraíba (PB)", value: "PB" },
  { label: "Paraná (PR)", value: "PR" },
  { label: "Pernambuco (PE)", value: "PE" },
  { label: "Piauí (PI)", value: "PI" },
  { label: "Rio de Janeiro (RJ)", value: "RJ" },
  { label: "Rio Grande do Norte (RN)", value: "RN" },
  { label: "Rio Grande do Sul (RS)", value: "RS" },
  { label: "Rondônia (RO)", value: "RO" },
  { label: "Roraima (RR)", value: "RR" },
  { label: "Santa Catarina (SC)", value: "SC" },
  { label: "São Paulo (SP)", value: "SP" },
  { label: "Sergipe (SE)", value: "SE" },
  { label: "Tocantins (TO)", value: "TO" },
  { label: "Distrito Federal (DF)", value: "DF" },
];

const InputSelect = ({
  label,
  errorMessage,
  width,
  errorColor = "red",
  leftIcon,
  rigthElement,
  maxLength,
  style,
  elementId = "input-select",
  ...props
}) => {
  const classes = useStyles({ errorMessage, id: props.id, errorColor });
  return (
    <FormControl fullWidth error={errorMessage} style={{ width }}>
      <InputLabel htmlFor={elementId} className={classes.labelInput}>
        {label}
      </InputLabel>
      <Select
        className={classes.root}
        maxLength={maxLength}
        error={errorMessage}
        placeholder={label}
        InputProps={{
          elementId,
          startAdornment: (
            <InputAdornment className={classes.leftIcon} position="start">
              {leftIcon}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment className={classes.rightIcon} position="end">
              {rigthElement}
            </InputAdornment>
          ),
        }}
        autoFocus={false}
        autoComplete="off"
        displayEmpty
        {...props}
      >
        <option aria-label="None" value="" />
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <SectionMain className={classes.errorMessage}>
        {errorMessage && (
          <FormHelperText
            id="component-error-text"
            className={classes.formHelperText}
          >
            <Title size="xsmall" color={errorColor}>
              {errorMessage}
            </Title>
          </FormHelperText>
        )}
      </SectionMain>
    </FormControl>
  );
};

export default InputSelect;
