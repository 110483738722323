import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppLayoutUI from "./components/AppLayoutUI";
import "./index.css";
import AboutUsPage from "./pages/about_us";
import CategoriesPage from "./pages/categories";
import ContactPage from "./pages/contact";
import PartnersPage from "./pages/partners";
import PartnersPanelDesktop from "./pages/partners_panel";
import RegisterPartnerPage from "./pages/register_partner";
import RegisterUserPage from "./pages/register_user";
import TermsPage from "./pages/terms";
import VideoPage from "./pages/video";
import AppContext from "./store/AppContext";
import { AuthContext } from "./store/AuthContext";
import PrivacyPage from "./pages/privacyPolicy";
import PlanUserPage from "./pages/plans_user";
import { useUpdateUser } from "./hooks/useUpdateUser";
import AccountPage from "./pages/account";
import HomePage from "./screens/home";

const App = () => {
  const [appState, setState] = useState({
    user: null,
    loading: false,
    video: { partner: "" },
    partnerForm: { first_name: "", email: "" },
    userForm: { name: "", email: "" },
    route: "/",
    filter: "",
    activeCategory: "",
    orientation: "left",
    search: "",
    path: "",
    onReloadData: null,
    partners: false,
    drawerMenu: false,
    colorSchema: { primary: "#282828", secondary: "#666" },
    theme: { light: "#282828", dark: "#fff" },
  });

  const setAppState = (value) => setState({ ...appState, ...value });

  const [token, setToken] = useState(null);
  const [data, setData] = useState({});

  const UpdateUser = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useUpdateUser();
  };
  useEffect(() => {
    UpdateUser();
  }, []);

  return (
    <AppContext.Provider value={{ ...appState, setAppState }}>
      <AuthContext.Provider
        value={{
          token,
          data,
          setToken,
          setData,
        }}
      >
        <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
          <Router>
            <AppLayoutUI>
              <Switch>
                <Route path="/" exact component={HomePage} />
                <Route path="/sobre-nos" exact component={AboutUsPage} />
                <Route path="/experimente" exact component={CategoriesPage} />
                <Route path="/parceiros" exact component={PartnersPage} />
                <Route path="/termos-de-uso" exact component={TermsPage} />
                <Route path="/painel" exact component={PartnersPanelDesktop} />

                <Route path="/cadastre-se" exact component={RegisterUserPage} />

                <Route
                  path="/ser-parceiro"
                  exact
                  component={RegisterPartnerPage}
                />
                <Route path="/contato" exact component={ContactPage} />
                <Route path="/video" exact component={VideoPage} />
                <Route
                  path="/medita-bem-planos"
                  exact
                  component={PlanUserPage}
                />
                <Route path="/minha-conta" exact component={AccountPage} />
                <Route
                  path="/politica-de-privacidade"
                  exact
                  component={PrivacyPage}
                />
              </Switch>
            </AppLayoutUI>
            <Switch></Switch>
          </Router>
        </SnackbarProvider>
      </AuthContext.Provider>
    </AppContext.Provider>
  );
};

export default App;
