import React from "react";
import Modal from "react-modal";
import { IconButton, makeStyles } from "@material-ui/core";
import { CheckCircleOutline, Close, Sync } from "@material-ui/icons";
import ButtonMedita from "../ButtonMedita";
import { useState } from "react";
import { useSnackbar } from "notistack";
import api from "../../core/api";

const useStyles = makeStyles({
  modal: {
    width: "400px",
    background: "#fff",
    margin: "40px auto",
    borderRadius: "20px",
    padding: "25px",
    textAlign: "center",
  },
  divider: {
    height: "1px",
    background: "#CDCED2",
    margin: "10px",
  },
  iosLikeButton: {
    background: "none",
    border: "none",
    display: "block",
    margin: "0 auto",
    color: "#0076FF",
    fontSize: "17px",
    fontWeight: "600",
  },
  modalTitle: {
    fontWeight: "600",
    margin: "10px",
  },
  p: {
    padding: 0,
    margin: 5,
    fontWeight: "bold",
    color: "#454545",
  },

  formMobile: {
    width: "auto",
    padding: 20,
  },
  buttonEnviar: {
    textTransform: "inherit",
    width: "247px !important",
    marginBottom: 20,
  },
});

const ModalForgotPassword = ({ isOpen, onClose, email }) => {
  const classes = useStyles();
  const [formStep, setFormStep] = useState(0);
  const [loadingSubscription, setLoadingSubscription] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const snackbar = (message) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const recoverPassword = async () => {
    try {
      if (email) {
        await api.post("/auth/forgot", { email }).then(async (res) => {
          setFormStep(1);
        });
      } else {
        snackbar(`Digite seu e-mail`);
      }
    } catch (e) {
      // console.log(e);
      snackbar("Ops.. algo de errado aconteceu. Tente novamente mais tarde!");
    }
  };

  const onCloseModal = () => {
    onClose();
    setFormStep(0);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      //   contentLabel="Cadastro"
      className={classes.modal}
      id="modal-result"
    >
      <div style={{ display: "flex" }}>
        <IconButton size="large" onClick={onCloseModal}>
          <Close />
        </IconButton>
      </div>

      {formStep === 0 ? (
        <>
          <h2 className={classes.modalDeleteTitle}>Redefinir senha?</h2>
          <p>
            Um link para alteração de senha será enviado para o e-mail
            <p className={classes.p}>{email}</p>
          </p>
          <ButtonMedita
            type="button"
            variant="outlined"
            onClick={onCloseModal}
            className={classes.buttonEnviar}
          >
            Cancelar
          </ButtonMedita>
          <ButtonMedita
            type="button"
            onClick={recoverPassword}
            disabled={loadingSubscription}
            className={classes.buttonEnviar}
          >
            {loadingSubscription ? (
              <>
                <span style={{ color: "white" }}>Aguarde... </span>
                <IconButton style={{ color: "white" }} size="large">
                  <Sync />
                </IconButton>
              </>
            ) : (
              "Enviar e-mail"
            )}
          </ButtonMedita>
        </>
      ) : (
        <>
          <h2>
            <IconButton
              style={{
                fontSize: "medium",
              }}
              onClick={onCloseModal}
            >
              <CheckCircleOutline
                style={{
                  fontSize: 80,
                  color: "green",
                }}
              />
            </IconButton>
          </h2>
          <h2 className={classes.modalDeleteTitle}>Link do e-mail enviado</h2>
          <p>
            Enviamos um email para <p className={classes.p}>{email}</p>com um
            link para você poder entrar novamente na sua conta do MeditaBem.
            <p>
              Por favor, verifique sua caixa de spam para confirmar se ele não
              foi direcionado incorretamente e inclua nosso e-mail nos seus
              contatos para receber nossas mensagens. Obrigado.
            </p>
          </p>
          <ButtonMedita
            type="button"
            variant="outlined"
            className={classes.buttonEnviar}
            onClick={onCloseModal}
          >
            OK
          </ButtonMedita>
        </>
      )}
    </Modal>
  );
};

export default ModalForgotPassword;
