import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Input,
  TextField,
  makeStyles,
  styled,
} from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
  },
  codeInput: {
    border: "none",
    backgroundColor: "transparent",
    fontSize: "24px",
    width: "45px",
    height: "52px",
    textAlign: "center",
    margin: "0 4px",
    borderBottom: "2px solid #ccc",
    outline: "none",
    padding: 0,
    border: (theme) => theme.borderColor || "1px solid #515151",

    "&.Mui-focused": {
      "webkit-appearance": "none",
    },
  },
});

export function CodeInput({ onChange, amount = 6, number, status }) {
  const [code, setCode] = useState(Array.from({ length: amount }, () => ""));
  const [error, setError] = useState(false);
  const inputRefs = useRef([0, 1, 2, 3, 4, 5]);

  const handleChange = (index, v) => {
    const value = v.split(" ").join("");
    if (value.length > 1) return PasteCode(value);
    const newCode = [...code];
    newCode[index] = value.toUpperCase();
    setCode(newCode);
    onChange(newCode.join(""));

    // Move focus to the next input
    if (value && index < amount - 1) {
      // eslint-disable-next-line no-unused-expressions
      inputRefs.current[index + 1]?.focus();
    }

    if (!value) {
      // eslint-disable-next-line no-unused-expressions
      inputRefs.current[index - 1]?.focus();
    }
    if (index === amount - 1) {
      // eslint-disable-next-line no-unused-expressions
      inputRefs.current[index]?.blur();
    }
  };

  const PasteCode = (v) => {
    var myString = v.toLowerCase();
    var myArray = myString.split("");
    const newCode = [...code];
    myArray.slice(0, 6).map(function (item, indexOf) {
      newCode[indexOf] = item.toUpperCase();
    });
    setCode(newCode);
    onChange(newCode.join(""));
  };

  const clearCode = () => {
    onChange("");
    setCode([]);
  };

  useEffect(() => {
    if (status === "error") {
      setError(true);
    }
  }, [status]);

  const classes = useStyles({
    borderColor: error ? "1px solid red" : "1px solid #515151",
  });

  return (
    <>
      <div className={classes.container}>
        {Array.from({ length: amount }).map((_, index) => (
          // eslint-disable-next-line react/react-in-jsx-scope
          <TextField
            id="input"
            variant="outlined"
            key={index}
            className={classes.codeInput}
            inputMode={number ? "numeric" : "text"}
            inputRef={(el) => (inputRefs.current[index] = el)}
            value={code[index] || ""}
            maxLength={0}
            length={0}
            onChange={(e) => handleChange(index, e.target.value.toUpperCase())}
          />
        ))}
      </div>
      {code.length > 0 && (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Button
          type="link"
          style={{ float: "right", margin: ".1rem" }}
          onClick={clearCode}
        >
          Limpar código
        </Button>
      )}
    </>
  );
}

export default CodeInput;
