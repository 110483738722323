import React from 'react';
import { makeStyles } from '@material-ui/core';
import SectionMain from '../../../components/SectionMain';
import Title from '../../../components/Title';
import FooterSection from '../../../components/FooterSection';

const useStyles = makeStyles({
  container: { padding: '20px 4vw', justifyContent: 'center' },
  banner: {
    background: '#f8f9fa',
    height: 400,
    justifyContent: 'space-between',
    padding: '10px 6vw',
  },
  leftSection: { padding: '25px 0', maxHeight: 400 },
  rightSection: { justifyContent: 'center' },
  image: { width: '45vw', maxWidth: 400 },
  title: {
    flexDirection: 'column',
    letterSpacing: 1,
    justifyContent: 'center',
    padding: '0 0 30px',
  },
  cursive: {
    color: '#008393',
    letterSpacing: 2,
    fontFamily: 'adobe-caslon-pro, serif',
    fontStyle: 'italic',
    fontSize: 38,
  },
  text: { flexDirection: 'column', alignItems: 'center' },
  subText: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 195,
    padding: '20px 4vw',
  },
});

const TermsPageDesktop = () => {
  const classes = useStyles();

  return (
    <SectionMain noPadding>
      <SectionMain className={classes.container}>
        <SectionMain className={classes.title}>
          <Title
            fontWeight='800'
            size='medium'
            align='center'
            capitalize='uppercase'
          >
            {'TERMOS DE USO E PRIVACIDADE'}
          </Title>
        </SectionMain>
        <SectionMain className={classes.text}>
          <Title
            fontWeight='800'
            size='small'
            align='left'
            capitalize='uppercase'
          >
            MEDITA - TERMOS DE USO E POLÍTICA DE PRIVACIDADE
          </Title>
          <br />
          Esta Política de Uso e Privacidade descreve as regras aplicáveis para
          utilização das plataformas da Medita e do aplicativo MeditaBem,
          disponibilizado pela empresa Medita Ação Social e Bem Estar para
          dispositivos móveis com sistemas Android e iOS. <br />
          <br />A Medita é a detentora e proprietária de plataformas como site,
          portal e o aplicativo MeditaBem, que oferecem conteúdos próprios e de
          terceiros em áudio, vídeo e texto relacionados a temas de bem-estar e
          qualidade de vida, abrangendo o público infanto-juvenil e adulto.
          Inclui Meditações; yoga; exercícios de diversas modalidades; terapias
          energéticas; autoconhecimento; publicação de artigos, matérias e
          similares; divulgação de cursos e eventos promovidos por terceiros e
          relacionados a esses temas.
          <br />
          <br /> A adesão aos serviços implica na aceitação total das condições
          e normas descritas nesse instrumento de “Termo de Uso e Política de
          Privacidade”. Ao acessar o site e/ou baixar e utilizar o aplicativo
          MeditaBem, o usuário reconhece que leu e concorda com as condições
          presentes no Termo de Uso e Política de Privacidade e que tem ciência
          de que o mesmo poderá ser alterado a qualquer momento, mediante
          publicação da versão atualizada no site da Medita, no aplicativo
          MeditaBem e também na Play Store (Android) e na App Store (iOS), sendo
          de sua responsabilidade, como usuário, consultá-lo regularmente. O uso
          posterior do site ou serviços do aplicativo a cada atualização
          caracteriza o consentimento automático em relação à Política do Termo
          e Uso e Privacidade e suas alterações.
          <br />
          <br />
          <Title
            fontWeight='800'
            size='small'
            align='left'
            capitalize='uppercase'
          >
            DOS DIREITOS AUTORAIS
          </Title>
          <br />
          O aplicativo MeditaBem é de propriedade da Medita Ação Social e Bem
          Estar, neste instrumento denominada Medita, que detém todos os
          direitos sobre o mesmo e sobre os conteúdos de sua autoria, conforme
          as leis de propriedade intelectual e direitos autorais. Conteúdos
          elaborados por autores e professores são de propriedade intelectual
          destes, devidamente cedidos para edição, quando é o caso, e publicação
          nas plataformas da Medita, como o site e app MeditaBem, por sistema de
          parceria comercial. O uso do aplicativo é de caráter pessoal e
          individual, não podendo ser utilizado para fins comerciais ou
          compartilhado em grupos de dispositivos ou pessoas, sob qualquer
          hipótese, sem a prévia e expressa autorização da Medita Ação Social e
          Bem Estar e, ainda, quando aplicável, do autor de um determinado
          conteúdo. Estão proibidas a reprodução, ainda que a título de
          referência, de seu design e/ou formato de apresentação de conteúdos,
          bem como é vetada toda e qualquer forma de modificação, reprodução,
          total ou parcial, permanente ou provisória, de forma gratuita ou
          onerosa, de conteúdos de áudio, vídeo ou texto presentes no site,
          aplicativo e/ou no portal MeditaBem sem a prévia e expressa
          autorização da Medita Ação Social e Bem Estar, que detem o direito de
          cancelar o acesso aos serviços sem prévio consentimento do usuário e
          tomar as medidas judiciais cabíveis. A Medita Ação Social e Bem Estar
          pode, ainda, cancelar os serviços a qualquer tempo, sem prévia
          comunicação e a seu exclusivo critério, na hipótese de ser
          identificado eventual mau uso do serviço por parte do usuário, sem que
          seja devido qualquer tipo de indenização, pagamento ou restituição a
          que título for.
          <br />
          <br />
          <Title
            fontWeight='800'
            size='small'
            align='left'
            capitalize='uppercase'
          >
            DA PRIVACIDADE, CONFIDENCIALIDADE E PROTEÇÃO DE DADOS
          </Title>
          <br />
          Para baixar o aplicativo MeditaBem, o usuário deve estar
          necessariamente cadastrado e logado no App Store ou na Play Store,
          conforme seu dispositivo móvel, tendo, para isso, previamente
          concordado com a sua Política de Privacidade e os Termos de Uso. O
          usuário reconhece que a App Store ou Play Store é a única detentora
          dos dados pessoais, cadastrais e de faturamento dos usuários e,
          portanto, única responsável pela segurança, privacidade e
          confidencialidade dos mesmos. Medita e seus desenvolvedores não se
          responsabilizam por quaisquer danos ou perdas, de qualquer natureza,
          decorrentes do acesso a dados pessoais de usuários. Qualquer assunto
          relacionado à segurança e privacidade de dados deve ser tratada pelo
          usuário diretamente com a App Store ou Play Store. Da mesma forma,
          havendo possibilidade de se logar por meio de sua conta do Facebook ou
          Gmail, declara o usuário concordar com os procedimentos padrão de
          coleta e uso de dados da plataforma, em consonância com sua Política
          de Privacidade e Termos de Uso vigentes. As lojas App Store e Play
          Store utilizam “cookies" para personalizar a experiência no aplicativo
          e armazenar dados de acesso ao app para que o usuário não tenha que
          digitá-las todas as vezes que se logar ao aplicativo ou acessar a
          assinatura. Cookies são pequenos arquivos de computador que são
          transferidos para o disco rígido e contém informações como o seu nome
          de usuário e as atividades que você realizou enquanto usou o
          aplicativo. A Medita não conecta a informação armazenada em cookies
          pelas appstores a nenhuma informação pessoal dos usuários do app
          MeditaBem. <br />
          <br /> Para o site, a Medita coleta dados de cadastro, navegação e
          contato nos canais de comunicação existentes, que integram o banco de
          dados do site e são armazenados em ambiente seguro. A Medita utiliza
          cookies para identificar o usuário e obter dados de acesso, navegação
          em páginas e links clicados com intuito de personalizar a experiência
          do usuário no site de acordo com o seu perfil, sendo que o usuário
          pode bloqueá-los nas configurações de seu navegador de internet,
          condição em que algumas funcionalidades do site tornam-se limitadas.{' '}
          <br /> <br />
          O usuário declara ter ciência e concordar que dados fornecidos, de
          contato, uso e navegação podem ser utilizados para responder suas
          questões, enviar newsletters, mensagens sobre novidades ou assuntos
          previamente selecionados e relacionados aos serviços do aplicativo,
          site, mídias sociais e plataformas da Medita, bem como informar sobre
          a manutenção dos serviços, alterações ou atualizações, a critério
          desta. A Medita não fornece ou transfere dados cadastrais e pessoais
          dos usuários de suas plataformas a terceiros, porém, detém o direito
          de informar a autores, professores e empresas parceiras ou anunciantes
          que participam do aplicativo todos e quaisquer dados estatísticos
          relacionados ao uso dos conteúdos e ferramentas do app, site e todas
          as plataformas e mídias da Medita e MeditaBem, com o objetivo de
          fomentar e direcionar os temas de acordo com o interesse da comunidade
          de usuários, manter campanhas publicitárias próprias e de terceiros
          parceiros comerciais e melhorar continuamente os serviços do
          aplicativo e plataformas. O usuário pode solicitar a suspensão do
          envio de newsletter a qualquer momento, quando a receber, clicando no
          campo específico para se descadastrar.
          <br />
          <br />
          <Title
            fontWeight='800'
            size='small'
            align='left'
            capitalize='uppercase'
          >
            DO CADASTRO NO APP APP MEDITABEM VIA APP STORE OU PLAY STORE
          </Title>
          <br />
          O usuário deve baixar o aplicativo MeditaBem nas lojas App Store (para
          iOS) ou Play Store (para Android). Para utilizar aplicativo, faz-se
          necessário dispositivo móvel e acesso à Internet, podendo o desempenho
          dos Serviços ser afetado pela qualidade desses. O aplicativo é
          ofertado pelo sistema Freemium, ou seja, parte dos conteúdos são
          gratuitos e parte é desbloqueada e disponibilizada ao usuário mediante
          assinatura, mensal ou anual e pagamento por cartão de crédito
          cadastrado diretamente na App Store ou Play Store, sendo, portanto, a
          detenção e processamento dos dados cadastrais e do cartão de crédito
          de única responsabilidade da mesma.
          <br />
          <br />
          <Title
            fontWeight='800'
            size='small'
            align='left'
            capitalize='uppercase'
          >
            DA PERSONALIZAÇÃO DE CONFIGURAÇÕES DA TELA INICIAL DO MEDITABEM
          </Title>
          <br />
          Para personalizar sua tela inicial, o usuário deve submeter certas
          informações que lhe identifiquem pessoalmente e que podem incluir seu
          apelido, endereço de e-mail e indicação de modalidades de atividades e
          práticas de bem-estar de sua preferência. O questionário da tela de
          cadastro tem por fim exclusivo melhorar e personalizar a experiência
          do usuário durante o uso do aplicativo. O usuário é responsável por
          assegurar a acurácia da Informação Pessoal que apresentar à MeditaBem,
          sendo que qualquer informação imprecisa afetará o uso do aplicativo.
          Ao aceitar essa Política de Privacidade, o usuário declara que está
          ciente e que concorda com a coleta e tratamento de seus dados para
          personalização do MeditaBem.
          <br />
          <br />
          <Title
            fontWeight='800'
            size='small'
            align='left'
            capitalize='uppercase'
          >
            DO CANCELAMENTO DA ASSINATURA DO APP MEDITABEM
          </Title>
          <br />
          A assinatura pode ser mensal ou anual e é renovada automaticamente
          pelas app stores ao término de cada período, exceto se ocorrer o
          cancelamento diretamente na App Store (para iOS) ou Play Store (para
          Android) nas 24 horas que antecedem o término da sua vigência.
          <br />
          <br />
          <ol style={{ padding: '0px 10px' }}>
            <li>
              Cancelamento da contratação via App Store (para iOS): No menu
              “ajustes" do próprio dispositivo móvel com sistema iOS selecionar
              a opção “iTunes e App Store”; clicar em Assinaturas; na sequência,
              selecionar a assinatura do MeditaBem, clicar em cancelar e
              desativar a opção de renovação automática.
              <br />
              <br />
            </li>
            <li>
              Cancelamento da contratação via Play Store (para Android): No
              smartphone ou tablet Android, abrir a Play Store; verificar se
              você fez login com a conta do Google correta; clicar em Menu
              Assinaturas; na sequência, selecionar a assinatura do Meditabem,
              clicar em cancelar e desativar a opção de renovação automática.
              <br />
              <br />
            </li>
          </ol>
          <br />
          <br />
          <Title
            fontWeight='800'
            size='small'
            align='left'
            capitalize='uppercase'
          >
            DA ISENÇÃO DE RESPONSABILIDADE:
          </Title>
          <br />
          O MeditaBem, bem como o site Medita, é uma plataforma de bem-estar que
          divulga conteúdos de autoria de terceiros em site, portal e
          aplicativo. Os conteúdos de qualquer natureza publicados, como links,
          vídeos, textos, áudios, imagens, podcasts e outros tipos de arquivos
          em geral, são de responsabilidade única e exclusiva do autor do
          conteúdo, não havendo quaisquer responsabilidade, solidária ou
          subsidiária, a qualquer título, da Medita no que tange às boas
          práticas relacionadas a direitos autorais, respeito às lei vigentes,
          autenticidade, veracidade e rigor dos temas, visto que os autores
          expressam orientações e opiniões baseadas em suas experiências
          pessoais, cabendo-lhes, porém, responsabilidade exclusiva e direta
          sobre seus conteúdos, isentando-se a Medita de qualquer relação
          subsidiária ou solidária em caso de conduta imprópria ou infrações por
          terceiros e, ainda, de danos ou prejuízos de qualquer natureza
          advindos destas. A Medita não se responsabiliza, sob qualquer hipótese
          ou situação, pela conduta dos autores parceiros, dados de contato
          divulgados na agenda de autores, bem como mídias sociais, sites ou
          links por estes publicados, tratando-se exclusivamente de relação de
          divulgação de conteúdos e autores por sistema de parceira, sem
          qualquer relação solidária, de cumplicidade, supervisão ou gestão,
          vínculo empregatício ou sociedade comercial. <br />
          <br /> Os conteúdos publicados nas plataformas como site, portal e app
          MeditaBem e Medita são de caráter amplo e informativo e não substituem
          quaisquer orientações médicas ou tratamentos de saúde ao qual os
          usuários estejam submetidos. Cabe a cada usuário a responsabilidade
          única, exclusiva, individual e direta pela forma como utiliza os
          serviços, conteúdos e temas das plataformas da Medita e app MeditaBem,
          cabendo-lhe zelar pela sua saúde e avaliar, segundo suas condições
          físicas, psicológicas, mentais e emocionais, quais conteúdos pode ou
          não aplicar em seu cotidiano, estendendo-se esta responsabilidade a
          tutores, pais e responsáveis por menores de idade ou pessoas que
          necessitem, em qualquer grau, da supervisão e orientação de terceiros
          para leitura, práticas meditativas ou físicas de qualquer natureza. O
          usuário não deve desconsiderar o aconselhamento de profissionais de
          saúde ou deixar de procurar por serviços de saúde baseado em
          informações, dados, conteúdos ou opiniões de causa, origem ou natureza
          publicados no app MeditaBem e plataformas da Medita. O usuário
          renuncia a quaisquer reclamações, ações e indenizações decorrentes do
          uso indevido do app ou site, suas ferramentas, conteúdos e
          informações, bem como qualquer outro que seja de sua responsabilidade
          integrante neste Termo. <br /> <br /> Cabe ao Usuário compreender,
          honrar e cumprir o estipulado neste Termo, sendo de sua única e
          exclusiva responsabilidade quaisquer danos causados a si e a
          terceiros, reivindicações, perdas ou indenizações de qualquer tipo,
          advindos do mau uso dos conteúdos, ferramentas, informações,
          dispositivos, dados do app, site ou autores e parceiros, violação de
          direitos autorais ou propriedade intelectual, sem qualquer vínculo de
          solidariedade por parte do MeditaBem e da Medita ou, ainda, de seus
          autores e parceiros.
          <br />
          <br />
          <Title
            fontWeight='800'
            size='small'
            align='left'
            capitalize='uppercase'
          >
            DO FUNCIONAMENTO E DESEMPENHO DOS SERVIÇOS
          </Title>
          <br />O Usuário declara estar ciente de que, por se tratar de
          plataformas e aplicativo de meio digital sujeitos a falhas de
          abastecimento por questões operacionais ou técnicas de diversas
          origens, os serviços da Medita e MeditaBem estão sujeitos a
          interrupção, falhas técnicas e de armazenamento, exibição,
          configurações, layout, indisponibilidade parcial ou integral,
          momentânea ou permanente de conteúdos e funcionalidades, suspensão por
          tempo indeterminado dos serviços por falhas ou manutenção emergencial,
          corretiva e preventiva, bem como outras ocorrências afins, isentando
          por este Instrumento de forma irrevogável e permanente a Medita da
          necessidade de aviso prévio sobre tais ocorrências e, ainda, de
          qualquer responsabilidade por danos e prejuízos de toda e qualquer
          natureza advindos das falhas de funcionamento do app, portal ou site,
          interrupções, falhas, vírus ou invasões do banco de dados do site,
          terceirizado a empresa do mercado. Considerando-se, ainda, que nenhum
          sistema de segurança é absolutamente seguro, não estão garantidos ao
          usuário qualquer padrão de desempenho ou nível de atendimento que
          possa ser reclamado ou que o acesso e uso ao app e plataformas
          ocorrerá de forma segura, livre de vírus ou similares, ininterrupta ou
          isento de falhas. <br /> <br /> Os autores e parceiros do MeditaBem e
          Medita podem fornecer links para sites e plataformas de terceiros, não
          havendo relação entre os mesmos e a Medita, responsabilidade de
          qualquer natureza, endosso, patrocínio, representação ou afiliação,
          estando isenta a Medita em relação a qualquer reclamação relacionada a
          estes links e plataformas de terceiros, disponibilidade,
          funcionamento, confiabilidade, segurança de dados, publicidades,
          conteúdos de qualquer natureza e a danos ou perdas advindas do seu uso
          e acesso. O usuário poderá ou não acessar tais links a seu critério,
          cabendo-lhe a responsabilidade dos seus atos, conexão, acesso e uso
          das plataformas de terceiros, ainda que indicadas no app MeditaBem e
          plataformas da Medita.
          <br /> <br /> A Medita tem por direito a suspensão temporária ou
          permanente, parcial ou integral, do acesso, conta, conteúdos,
          informações, funcionalidades, recursos, programas ou serviços do app,
          site ou ações por esta promovidas do usuário, um grupo de usuários ou
          sua totalidade, sem necessidade de aviso prévio. A Medita pode, ainda,
          alterar, corrigir, reduzir, atualizar e isentar valores de
          assinaturas, ofertas e promoções a seu critério, sem necessidade de
          aviso prévio ou comunicação formal. Cabe ao usuário verificar as
          informações nas app stores, app ou site, onde estiverem disponíveis. A
          continuidade dos serviços implica no aceite automático de tais
          condições. <br /> <br /> O usuário declara ser maior de 18 anos e ser
          integralmente responsável pela forma como utiliza os conteúdos do
          site, portal e aplicativo e pela preservação de sua saúde física,
          mental e emocional. O uso por menores de idade dos conteúdos
          disponibilizados no aplicativo, incluindo os dirigidos a crianças e
          adolescentes, deve ser autorizado e supervisionado pelos pais ou
          responsáveis legais. Ao baixar o aplicativo, o usuário concorda que
          lhe foi concedida a oportunidade para ler, examinar e entender os
          termos e condições deste instrumento e aceita eleger a Mediação, sem
          São Paulo, Capital, como meio prévio e obrigatório de solução para
          dirimir quaisquer dúvidas oriundas do acordo do presente Termo de Uso
          e Política de Privacidade.
        </SectionMain>
      </SectionMain>

      <SectionMain>
        <FooterSection />
      </SectionMain>
    </SectionMain>
  );
};

export default TermsPageDesktop;
