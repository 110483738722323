import React from "react";
import { makeStyles } from "@material-ui/core";
import Modal from "react-modal";
import ButtonMedita from "../ButtonMedita";
import Title from "../Title";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.3)";

const useStyles = makeStyles({
  register: {
    color: "white",
    cursor: "pointer",
    padding: "13px 26px !important",
    background: "#316570 !important",
    fontWeight: "600 !important",
    letterSpacing: "1px",
    textTransform: "uppercase",
    textDecorationLine: "none",
    margin: "10px 0 0 -27px",
    borderRadius: "30px !important",
    width: "144px !important",
    height: "41px !important",
  },

  text: {
    fontSize: "14px",
    color: "white",
  },
});

const ButtonRegister = ({ openModal }) => {
  const classes = useStyles();

  return (
    <ButtonMedita onClick={openModal} className={classes.register}>
      <Title className={classes.text}>Cadastre-se</Title>
    </ButtonMedita>
  );
};

export default ButtonRegister;
