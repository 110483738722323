import React from 'react';
import {
  TextField,
  FormControl,
  FormHelperText,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import Title from '../Title';
import SectionMain from '../SectionMain';

const useStyles = makeStyles({
  root: {
    borderRadius: '40px',
    border: 'solid 1px #d3d3d3',
    padding: '0 17px',
    borderColor: ({ errorMessage }) => (errorMessage ? 'red' : 'none'),
    height: ({ id }) => id === 'description' && '100%',
    boxShadow: '1px 1px 10px -2px rgba(0,0,0,0.3)',
  },
  errorMessage: {
    height: 18,
  },
  leftIcon: {
    color: '#008393',
    marginRight: 10,
  },
  rightIcon: {
    color: '#008393',
  },
  formHelperText: {
    color: ({ errorColor }) => errorColor,
    paddingLeft: 12,
  },
});

const InputText = ({
  label,
  errorMessage,
  width,
  errorColor = 'red',
  leftIcon,
  rigthElement,
  maxLength,
  style,
  ...props
}) => {
  const classes = useStyles({ errorMessage, id: props.id, errorColor });
  return (
    <FormControl fullWidth error={errorMessage} style={{ width }}>
      <TextField
        className={classes.root}
        maxLength={maxLength}
        error={errorMessage}
        placeholder={label}
        InputProps={{
          startAdornment: (
            <InputAdornment className={classes.leftIcon} position='start'>
              {leftIcon}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment className={classes.rightIcon} position='end'>
              {rigthElement}
            </InputAdornment>
          ),
        }}
        autoFocus={false}
        autoComplete='off'
        {...props}
      />
      <SectionMain className={classes.errorMessage}>
        {errorMessage && (
          <FormHelperText
            id='component-error-text'
            className={classes.formHelperText}
          >
            <Title size='xsmall' color={errorColor}>
              {errorMessage}
            </Title>
          </FormHelperText>
        )}
      </SectionMain>
    </FormControl>
  );
};

export default InputText;
