import React, { useContext } from "react";
import DrawerMenuWrapper from "./DrawerMenuWrapper";
import DrawerMenuUI from "./DrawerMenuUI";
import { AuthContext } from "../../../store/AuthContext";

const DrawerMenu = () => {
  const { setToken, setData } = useContext(AuthContext);

  const logout = async () => {
    setToken(undefined);
    setData(undefined);
    localStorage.clear();

    return (window.location.href = "/");
  };

  return (
    <DrawerMenuWrapper>
      {({ title, link, categories, goToPage }) => {
        return (
          <DrawerMenuUI
            key={`#-title-${title}`}
            categories={categories}
            title={title}
            link={link}
            goToPage={title === "sair" ? logout : goToPage}
          />
        );
      }}
    </DrawerMenuWrapper>
  );
};

export default DrawerMenu;
