import { makeStyles } from "@material-ui/core";
import React from "react";
import Modal from "react-modal";
import ButtonMedita from "../ButtonMedita";
import Title from "../Title";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.3)";

const useStyles = makeStyles({
  register: {
    padding: "1.6rem 3rem !important",
    marginLeft: "5px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    textDecorationLine: "none",
    borderRadius: "23px !important",
    width: "auto !important",
    height: "41px !important",
  },

  text: {
    fontWeight: "bold !important",
    fontSize: "18px",
    color: "white",
  },
});

const ButtonRegister = ({ openModal, title }) => {
  const classes = useStyles();

  return (
    <ButtonMedita onClick={openModal} className={classes.register}>
      <Title className={classes.text}>{title}</Title>
    </ButtonMedita>
  );
};

export default ButtonRegister;
