import { makeStyles } from '@material-ui/core';
import React from 'react';
import SectionMain from '../../SectionMain';

const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    padding: ({ mobile }) => (mobile ? '10px 2vw' : '20px 0'),
  },
  leftSection: {
    justifyContent: 'space-between',
    padding: ({ mobile }) => (mobile ? '0px 2vw' : '0 15px 20px 0px'),
  },
  rightSection: {
    justifyContent: 'space-between',
    padding: ({ mobile }) => (mobile ? '0px 2vw' : '0 0px 20px 15px'),
  },
});

const FormContactWrapper = ({ children, formikProps, mobile }) => {
  const classes = useStyles({ mobile });
  const getInputs = Object.keys(formikProps.values);

  const inputs = {
    first_name: { label: '*Nome completo', type: 'text' },
    email: { label: '*E-mail', type: 'email' },
    phone: { label: '*Telefone', type: 'tel' },
    message: { label: '*Como podemos ajudar?', type: 'text' },
  };

  return (
    <SectionMain noPadding>
      <SectionMain item xs={mobile ? 12 : 6} className={classes.leftSection}>
        {getInputs.slice(0, 3).map((key, i) =>
          children({
            key: `input-${inputs[key].label}`,
            keyObject: key,
            label: inputs[key].label,
            type: inputs[key].type,
            handleFormChange: (value) => formikProps.setFieldValue(key, value),
            value: formikProps.values[key],
            errorMessage: formikProps.touched[key] && formikProps.errors[key],
          })
        )}
      </SectionMain>
      <SectionMain item xs={mobile ? 12 : 6} className={classes.rightSection}>
        {getInputs.slice(-1).map((key, i) =>
          children({
            key: `input-${inputs[key].label}`,
            keyObject: key,
            label: inputs[key].label,
            type: inputs[key].type,
            handleFormChange: (value) => formikProps.setFieldValue(key, value),
            value: formikProps.values[key],
            errorMessage: formikProps.touched[key] && formikProps.errors[key],
          })
        )}
      </SectionMain>
    </SectionMain>
  );
};

export default FormContactWrapper;
