export const STYLE = {
  container: {
    padding: '10px',
    outline: 'none',
    width: '250px',
  },
  card: {
    height: '200px',
    width: '250px',
    backgroundImage: ({ thumbnail }) => `url(${thumbnail})`,
    borderRadius: 30,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  cardBigger: {
    height: '260px',
    width: '300px',
    backgroundImage: ({ thumbnail }) => `url(${thumbnail})`,
    borderRadius: 30,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },

  description: {
    alignContent: 'center',
    padding: '0px 8px',
    height: 75,
    borderRadius: '0 0 30px 30px',
    backgroundColor: '#fff',
    boxShadow: '0 5px 6px -2px rgba(0,0,0,0.2)',
  },
  icon: {
    height: 10,
    justifyContent: 'flex-end',
    alignContent: 'flex-start',
    padding: '10px 0 0',
  },
  avatar: {
    height: 46,
    width: 46,
  },
  title: { overflow: 'hidden', fontWeight: 100 },
  mainTitle: {
    margin: '-6px 0 0',
    overflow: 'initial',
    maxWidth: '91%',
    '-webkit-line-clamp': '2 !important',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  },

  cardContentContainer: {
    display: 'block',
  },

  text: {
    width: 160,
  },
};
