import { makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
  amex,
  amex_single,
  diners,
  diners_single,
  discover,
  discover_single,
  jcb,
  jcb_single,
  maestro,
  maestro_single,
  mastercard,
  mastercard_single,
  unionpay,
  unionpay_single,
  visa,
  visa_single,
} from "../../../../components/Stepper/components/svgs";
import SectionMain from "../../../../components/SectionMain";
import { useSnackbar } from "notistack";
import api from "../../../../core/api";
import { useUpdateUser } from "../../../../hooks/useUpdateUser";
import Axios from "axios";
import IMask from "imask";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import { AuthContext } from "../../../../store/AuthContext";
import Title from "../../../../components/Title";
import CardsPlan from "./components/CardsPlan";

const useStyles = makeStyles({
  containerSeven: {
    backgroundColor: "#F3FAF7",
    borderRadius: "150% 150% 0% 0%/40% 40% 0% 0%",
    paddingBottom: "5%",
  },
  leftSectionSeven: {
    height: "100%",
    maxWidth: "45%",
    margin: "auto",
    marginTop: 50,
  },
  titleSectionSeven: {
    fontFamily: "Century Gothic",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#3bad76",
    marginTop: 10,
  },
  descriptionSeven: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7084",
  },
  sealView: {
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#E1F3EC",
    borderRadius: 40,
    margin: "auto",
  },
  sealText: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#36B37E",
  },
});

const SectionSeven = () => {
  const [plano, setPlano] = React.useState(localStorage.getItem("plano"));

  const { enqueueSnackbar } = useSnackbar();

  const snackbar = (message) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const [name, setName] = React.useState("");
  const [loadingSubscription, setLoadingSubscription] = React.useState(false);
  const [cpf, setCpf] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [addressNumber, setAddressNumber] = React.useState("");
  const [cellphone, setCellphone] = React.useState("");
  const [selectPlan, setSelectPlan] = useState(null);
  const [modalUpdateIsOpen, setUpdateIsOpen] = React.useState(null);
  const [changePlan, setchangePlan] = React.useState(false);
  const [statusPlan, setStatusPlan] = React.useState("NOTPENDING");

  const [subscription, setSubs] = useState(null);
  const [expiration, setExpiration] = useState(null);
  const getSubscrition = async () => {
    const id = localStorage.getItem("id");
    if (id) {
      try {
        await api.get(`/subscriptions/users/${id}`).then(async ({ data }) => {
          setSubs(data);
        });
      } catch (e) {
        // snackbar("Ops.. algo de errado aconteceu. Tente novamente mais tarde!");
      }
    }
  };
  useEffect(() => {
    getSubscrition();
  }, []);
  useEffect(() => {
    if (subscription?.subscriptions?.endDate) {
      const today = subscription?.subscriptions?.endDate;
      const date = `${today.slice(8, 10)}/${today.slice(5, 7)}/${today.slice(
        0,
        4
      )}`;

      setExpiration(date);
    }
  }, [subscription]);

  useEffect(() => {
    const name = document.getElementById("name");
    const cardnumber = document.getElementById("cardnumber");
    const expirationdate = document.getElementById("expirationdate");
    const securitycode = document.getElementById("securitycode");
    const ccicon = document.getElementById("ccicon");
    const ccsingle = document.getElementById("ccsingle");

    //Mask the Credit Card Number Input
    if (cardnumber) {
      var cardnumber_mask = new IMask(cardnumber, {
        mask: [
          {
            mask: "0000 000000 00000",
            regex: "^3[47]\\d{0,13}",
            cardtype: "american express",
          },
          {
            mask: "0000 0000 0000 0000",
            regex: "^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}",
            cardtype: "discover",
          },
          {
            mask: "0000 000000 0000",
            regex: "^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}",
            cardtype: "diners",
          },
          {
            mask: "0000 0000 0000 0000",
            regex: "^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}",
            cardtype: "mastercard",
          },
          // {
          //     mask: '0000-0000-0000-0000',
          //     regex: '^(5019|4175|4571)\\d{0,12}',
          //     cardtype: 'dankort'
          // },
          // {
          //     mask: '0000-0000-0000-0000',
          //     regex: '^63[7-9]\\d{0,13}',
          //     cardtype: 'instapayment'
          // },
          {
            mask: "0000 000000 00000",
            regex: "^(?:2131|1800)\\d{0,11}",
            cardtype: "jcb15",
          },
          {
            mask: "0000 0000 0000 0000",
            regex: "^(?:35\\d{0,2})\\d{0,12}",
            cardtype: "jcb",
          },
          {
            mask: "0000 0000 0000 0000",
            regex: "^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}",
            cardtype: "maestro",
          },
          // {
          //     mask: '0000-0000-0000-0000',
          //     regex: '^220[0-4]\\d{0,12}',
          //     cardtype: 'mir'
          // },
          {
            mask: "0000 0000 0000 0000",
            regex: "^4\\d{0,15}",
            cardtype: "visa",
          },
          {
            mask: "0000 0000 0000 0000",
            regex: "^62\\d{0,14}",
            cardtype: "unionpay",
          },
          {
            mask: "0000 0000 0000 0000",
            cardtype: "Unknown",
          },
        ],
        dispatch: function (appended, dynamicMasked) {
          var number = (dynamicMasked.value + appended).replace(/\D/g, "");

          for (var i = 0; i < dynamicMasked.compiledMasks.length; i++) {
            let re = new RegExp(dynamicMasked.compiledMasks[i].regex);
            if (number.match(re) != null) {
              return dynamicMasked.compiledMasks[i];
            }
          }
        },
      });

      //Mask the Expiration Date
      var expirationdate_mask = new IMask(expirationdate, {
        mask: "00{/}00",
      });

      //Mask the security code
      var securitycode_mask = new IMask(securitycode, {
        mask: "0000",
      });

      IMask(document.getElementById("cpfcnpj"), {
        mask: [
          {
            mask: "000.000.000-00",
            maxLength: 11,
          },
          {
            mask: "00.000.000/0000-00",
          },
        ],
      });

      IMask(document.getElementById("cell"), {
        mask: [
          {
            mask: "(00) 0000-0000",
            maxLength: 11,
          },
          {
            mask: "(00) 0 0000-0000",
          },
        ],
      });

      IMask(document.getElementById("cep"), {
        mask: [
          {
            mask: "00.000-000",
          },
        ],
      });

      //define the color swap function
      const swapColor = function (basecolor) {
        document.querySelectorAll(".lightcolor").forEach(function (input) {
          input.setAttribute("class", "");
          input.setAttribute("class", "lightcolor " + basecolor);
        });
        document.querySelectorAll(".darkcolor").forEach(function (input) {
          input.setAttribute("class", "");
          input.setAttribute("class", "darkcolor " + basecolor + "dark");
        });
      };

      //pop in the appropriate card icon when detected
      cardnumber_mask.on("accept", function () {
        switch (cardnumber_mask.masked.currentMask.cardtype) {
          case "american express":
            ccicon.innerHTML = amex;
            ccsingle.innerHTML = amex_single;
            swapColor("green");
            break;
          case "visa":
            ccicon.innerHTML = visa;
            ccsingle.innerHTML = visa_single;
            swapColor("lime");
            break;
          case "diners":
            ccicon.innerHTML = diners;
            ccsingle.innerHTML = diners_single;
            swapColor("orange");
            break;
          case "discover":
            ccicon.innerHTML = discover;
            ccsingle.innerHTML = discover_single;
            swapColor("purple");
            break;
          case "jcb" || "jcb15":
            ccicon.innerHTML = jcb;
            ccsingle.innerHTML = jcb_single;
            swapColor("red");
            break;
          case "maestro":
            ccicon.innerHTML = maestro;
            ccsingle.innerHTML = maestro_single;
            swapColor("yellow");
            break;
          case "mastercard":
            ccicon.innerHTML = mastercard;
            ccsingle.innerHTML = mastercard_single;
            swapColor("lightblue");

            break;
          case "unionpay":
            ccicon.innerHTML = unionpay;
            ccsingle.innerHTML = unionpay_single;
            swapColor("cyan");
            break;
          default:
            ccicon.innerHTML = "";
            ccsingle.innerHTML = "";
            swapColor("grey");
            break;
        }
      });

      // CREDIT CARD IMAGE JS
      if (document.querySelector(".preload"))
        document.querySelector(".preload").classList.remove("preload");
      document
        .querySelector(".creditcard")
        .addEventListener("click", function () {
          if (this.classList.contains("flipped")) {
            this.classList.remove("flipped");
          } else {
            this.classList.add("flipped");
          }
        });

      //On Input Change Events
      name.addEventListener("input", function () {
        if (name.value.length == 0) {
          document.getElementById("svgname").innerHTML = "John Doe";
          document.getElementById("svgnameback").innerHTML = "John Doe";
        } else {
          document.getElementById("svgname").innerHTML = this.value;
          document.getElementById("svgnameback").innerHTML = this.value;
        }
      });

      cardnumber_mask.on("accept", function () {
        if (cardnumber_mask.value.length == 0) {
          document.getElementById("svgnumber").innerHTML =
            "0123 4567 8910 1112";
        } else {
          document.getElementById("svgnumber").innerHTML =
            cardnumber_mask.value;
        }
      });

      expirationdate_mask.on("accept", function () {
        if (expirationdate_mask.value.length == 0) {
          document.getElementById("svgexpire").innerHTML = "01/23";
        } else {
          document.getElementById("svgexpire").innerHTML =
            expirationdate_mask.value;
        }
      });

      securitycode_mask.on("accept", function () {
        if (securitycode_mask.value.length == 0) {
          document.getElementById("svgsecurity").innerHTML = "985";
        } else {
          document.getElementById("svgsecurity").innerHTML =
            securitycode_mask.value;
        }
      });

      //On Focus Events
      name.addEventListener("focus", function () {
        document.querySelector(".creditcard").classList.remove("flipped");
      });

      cardnumber.addEventListener("focus", function () {
        document.querySelector(".creditcard").classList.remove("flipped");
      });

      expirationdate.addEventListener("focus", function () {
        document.querySelector(".creditcard").classList.remove("flipped");
      });

      securitycode.addEventListener("focus", function () {
        document.querySelector(".creditcard").classList.add("flipped");
      });
    }
  }, [selectPlan]);

  const classes = useStyles();

  const UpdateUser = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const res = await useUpdateUser();
    setPlano(res?.premium);
    if (res?.subscription_assas_id) {
      try {
        const result = JSON.parse(res?.subscription_assas_id);
        const planBody = result.filter((r) => r.status === "PENDING");
        if (
          planBody.length > 0 &&
          planBody[planBody.length - 1].status === "PENDING"
        ) {
          setStatusPlan("PENDING");
        }
      } catch (error) {}
    }
  };
  useEffect(() => {
    UpdateUser();
  }, []);

  const { setToken, setData } = useContext(AuthContext);

  const params = useQueryParams();
  const GetUserByToken = async () => {
    if (params) {
      try {
        await api.get("/auth/redirect").then(async (res) => {
          console.log("res", res.data.data);
          localStorage.setItem("id", res.data.user.id);
          localStorage.setItem("name", res.data.user.name);
          localStorage.setItem("nickName", res.data.user.nickName);
          localStorage.setItem("email", res.data.user.email);
          localStorage.setItem("avatarUrl", res.data.user.avatarUrl);
          localStorage.setItem("plano", res.data.user.premium);

          setToken(res.data.token);
          setData(res.data.user);
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (params?.token) {
      localStorage.setItem("token", params?.token);
      GetUserByToken(params?.token);
    }
  }, [params]);

  return (
    <div style={{ paddingTop: "20px" }}>
      <SectionMain noPadding className={classes.containerSeven}>
        <SectionMain noPadding item className={classes.leftSectionSeven}>
          <div className={classes.sealView}>
            <span className={classes.sealText}>Planos</span>
          </div>
          <div className={classes.titleSectionSeven}>
            Escolha o plano perfeito e comece a transformar seu estado de
            espirito
            <br />
            <span className={classes.descriptionSeven}>
              Descubra qual dos nossos planos mensais ou anuais vai ser o
              primeiro passo para uma vida mais tranquila, focada e equilibrada.
            </span>
          </div>
        </SectionMain>
        <CardsPlan />
      </SectionMain>
    </div>
  );
};

export default SectionSeven;
