import footer_desktop from '../../../assets/images/footer_desktop.jpg';
import footer_mobile from '../../../assets/images/footer_mobile.jpg';

console.log(footer_desktop, footer_mobile);
export const STYLES = {
  banner: {
    backgroundImage: ({ mobile, typeFooter }) =>
      `url(${mobile ? footer_mobile : footer_desktop})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
  },
  overlayContainer: {
    padding: ({ mobile }) => (mobile ? '5vw 4vw' : '3vw 18vw'),
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    height: ({ mobile }) => (mobile ? '360px' : '35vw'),
    maxHeight: 460,
    backgroundColor: ({ typeFooter }) =>
      typeFooter === 'partner' ? '#00000033' : 'none',
  },
  shadow: {
    // textShadow: ({ mobile }) =>
    //   mobile ? 'none' : '4px 4px 10px rgba(0, 0, 0, 0.29)',
    textTransform: 'inherit',
    transition: '0.5s',
    fontSize: '22px',
  },
  subText: {
    width: '70%',
    display: 'block',
    transition: '0.5s',
    fontSize: '18px',
    margin: '0 auto',
  },
  inputs: {
    width: ({ mobile }) => (mobile ? '100%' : 350),
    height: 160,
    padding: '10px 0px',
    justifyContent: 'space-between',
  },
  mobileButton: {
    display: 'block',
    margin: '10px auto',
  },
};
