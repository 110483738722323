export const STYLE = {
  container: { padding: '20px 4vw' },
  center: {
    textAlign: 'center',
  },
  subBanner: {
    height: '34vw',
    justifyContent: 'center',
  },
  subBannerContent: {
    padding: '20px 4vw',
    background: '#ffc864',
    height: '25vw',
    alignItems: 'center',
  },
  subBannerTitle: {
    paddingBottom: 100,
    alignItems: 'flex-end',
  },
  title: {
    flexDirection: 'column',
    letterSpacing: 1,
    justifyContent: 'center',
    padding: '0 0 30px',
    paddingTop: 10,
  },
  image: { height: 280, display: 'block', margin: '0 auto' },
  inputs: {
    height: 160,
    padding: '10px 0px',
    alignItems: 'center',
    display: 'contents',
  },
  section: {
    justifyContent: 'center',
    width: '100%',
    padding: '20px',
  },
  modal: {
    margin: '-16px 0',
    padding: '15px 20px',
    position: 'sticky',
    background: '#F9F9F9',
    height: '600px',
    justifyContent: 'center',
  },

  titleMobile: {
    fontSize: '20px',
    margin: '40px 0 0',
    display: 'block',
  },
  formBlock: {
    width: '100% !important',
    display: 'block',
  },
};
