import { makeStyles } from "@material-ui/core";
import React from "react";
import ButtonMedita from "../../../components/ButtonMedita";
import FooterSection from "../../../components/FooterSection";
import FormPartner from "../../../components/FormPartner";
import SectionMain from "../../../components/SectionMain";
import { STYLE } from "./style";

const useStyles = makeStyles(STYLE);

const RegisterPartnerPageMobile = ({ formikProps }) => {
  const classes = useStyles();

  return (
    <SectionMain noPadding position="center">
      {/* <BePartner></BePartner> */}

      <SectionMain className={classes.container}>
        <FormPartner mobile formikProps={formikProps} />
      </SectionMain>
      <SectionMain className={classes.container}>
        <ButtonMedita
          type="submit"
          onClick={formikProps.handleSubmit}
          size="small"
        >
          PRONTO, ENVIAR
        </ButtonMedita>
      </SectionMain>

      <SectionMain>
        <FooterSection mobile hideNewsletter/>
      </SectionMain>
    </SectionMain>
  );
};

export default RegisterPartnerPageMobile;
