import React from "react";
import FooterSectionLinks from "./FooterSectionLinks";
import { makeStyles } from "@material-ui/core";
import selo from "../../../../../assets/images/home/selo_medita.png";

const useStyles = makeStyles({
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "auto",
    alignSelf: "center",
    padding: 10,
    marginTop: "20px",
  },
  textFooter: {
    fontFamily: "Century Gothic",
    fontFize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#5a7184",
    whiteSpace: "nowrap",
  },
});

const FooterSection = () => {
  const classes = useStyles();

  return (
    <>
      <div style={{ background: "#FFF", width: "100%", marginBottom: "-15px" }}>
        <FooterSectionLinks />
      </div>
      <div className={classes.footer}>
        <img alt="MeditaBem" src={selo} style={{ width: "65px" }} />
        <p className={classes.textFooter}>
          Copyright © 2024. Criado com carinho para você.
        </p>
      </div>
    </>
  );
};

export default FooterSection;
