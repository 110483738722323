import React, { useEffect } from "react";
import SectionMain from "../../components/SectionMain";
import { Hidden } from "@material-ui/core";
import VideoPageDesktop from "./desktop";
import VideoPageMobile from "./mobile";
import { useUpdateUser } from "../../hooks/useUpdateUser";

const VideoPage = () => {
  const UpdateUser = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useUpdateUser();
  };
  useEffect(() => {
    UpdateUser();
  }, []);
  return (
    <SectionMain noPadding>
      <Hidden only={["xs", "sm"]}>
        <VideoPageDesktop />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <VideoPageMobile />
      </Hidden>
    </SectionMain>
  );
};

export default VideoPage;
