import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Title from '../../Title';
import SectionMain from '../../SectionMain';
import { STYLE } from './style';
const useStyles = makeStyles(STYLE);

const IconCategoryButtonUI = ({
  type,
  title,
  goTolink,
  src,
  color,
  regex,
  active,
}) => {
  const classes = useStyles({ active, color });
  // const getColor = { background: active ? color : '#f9f9f9' };

  switch (type) {
    case 'desktop':
      return (
        <Button
          variant='text'
          className={classes.btnDesktop}
          onClick={goTolink}
        >
          <SectionMain
            noPadding
            alignItems='center'
            position='center'
            direction='row'
          >
            <Title size='small' fontWeight='700' className={classes.title}>
              {title === '' ? 'Todos' : title}
            </Title>
          </SectionMain>
        </Button>
      );
    case 'mobile':
      return (
        <Button onClick={goTolink} className={classes.btnMobile} variant='text'>
          <SectionMain
            noPadding
            alignItems='center'
            position='center'
            direction='column'
          >
            <Title
              size='xsmall'
              align='center'
              fontWeight='700'
              className={classes.title}
            >
              {title === '' ? 'Todos' : title}
            </Title>
          </SectionMain>
        </Button>
      );

    default:
      return '';
  }
};

export default IconCategoryButtonUI;
