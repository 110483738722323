import React from 'react'
import SectionMain from '../../SectionMain'
import { makeStyles, Avatar } from '@material-ui/core'
import Title from '../../Title'

const useStyles = makeStyles({
	root: {
		width: ({ mobile }) => (mobile ? '100%' : '25%'),
		marginBottom: '10px',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		maxWidth: 200,
		maxHeight: 200,
		width: ({ mobile }) => (mobile ? '30vw' : 100),
		height: ({ mobile }) => (mobile ? '30vw' : 100)
	}
})

const PartnersListUI = ({ occupation, artisticName, thumbnailURL, mobile }) => {
	const classes = useStyles({ mobile })
	return (
		<SectionMain noPadding className={classes.root} item lg={1} xl={1}>
			<Avatar className={classes.avatar} alt={`parceiro-${artisticName}`} src={thumbnailURL} />
			<SectionMain position="space-between" direction="column">
				<Title size="small" fontWeight="700" align="center">
					{artisticName}
				</Title>
				<Title size="xsmall" align="center" color="#282828">
					{occupation}
				</Title>
			</SectionMain>
		</SectionMain>
	)
}

export default PartnersListUI
