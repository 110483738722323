import React from "react";
import { makeStyles } from "@material-ui/core";
import SectionMain from "../../../components/SectionMain";
import PlansForm from "../../../components/StepperMobile/PlansForm";

const useStyles = makeStyles({
  container: { padding: "20px 4vw", justifyContent: "center" },
});

const PlansUserPageMobile = () => {
  const classes = useStyles();
  return (
    <SectionMain className={classes.container} position="center">
      <PlansForm />
    </SectionMain>
  );
};

export default PlansUserPageMobile;
