import React from "react";
import SectionMain from "../../components/SectionMain";
import { Hidden } from "@material-ui/core";
import TermsPageDesktop from "./desktop";
import TermsPageMobile from "./mobile";

const TermsPage = () => {
  return (
    <SectionMain noPadding>
      <Hidden only={["xs", "sm"]}>
        <TermsPageDesktop />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <TermsPageMobile />
      </Hidden>
    </SectionMain>
  );
};

export default TermsPage;
