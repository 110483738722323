import React from 'react';
import { makeStyles } from '@material-ui/core';
import Modal from 'react-modal';
import ButtonMedita from '../ButtonMedita';
import Title from '../Title';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.3)';

const useStyles = makeStyles({
  cta: {
    width: '280px !important',
    height: '46px !important',
    fontSize: '14px',
    height: '41px !important',
  },
  text: {
    fontSize: '14px',
  },
});

const ButtonWhite = ({ openModal, text }) => {
  const classes = useStyles();

  return (
    <ButtonMedita
      onClick={openModal}
      className={classes.cta}
      variant='outlined'
    >
      <Title className={classes.text}>{text}</Title>
    </ButtonMedita>
  );
};

export default ButtonWhite;
