import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Icon, makeStyles } from "@material-ui/core";
import { AuthContext } from "../../../../store/AuthContext";
import { Person } from "@material-ui/icons";

const useStyles = makeStyles({
  profile: {
    postion: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    borderRadius: "12px",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingRight: "20px",
    paddingLeft: "14px",
    border: "1px solid #ccc",
    fontSize: "14px",
    textAlign: "center",
    cursor: "pointer",
  },

  noMargin: {
    margin: "0",
  },

  link: {
    textDecoration: "none",
    color: "black",
    fontWeight: "bold",
  },

  profile_image: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    border: "1px solid #fff",
    margin: "0 auto",
    display: "block",
    marginRight: "4px",
  },

  dropdown: {
    position: "absolute",
    top: "3em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    //borderRadius: "12px",
    // padding: "4px",
    // border: "1px solid #ccc",
    fontSize: "14px",
    textAlign: "center",
    width: "100%",
    height: "2em",
  },

  box: {
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0.8px 0.8px 7px -2px rgba(5, 6, 27, 0.25)",
    marginLeft: "-1",
    position: "absolute",
    padding: "10px",
    top: "39px",
    zIndex: "3",
    width: "200px",
  },

  dropdown_button: {
    width: "100%",
    height: "2em",
    borderRadius: "12px",
    border: "1px solid #ccc",
    fontSize: "14px",
    textAlign: "center",
    cursor: "pointer",
    color: "#000",
    marginTop: "15px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
});

const ProfileDropdown = () => {
  const [open, setOpen] = useState(false);
  const nome = localStorage.getItem("name");

  const classes = useStyles();
  const history = useHistory();

  const { setToken, setData, data } = useContext(AuthContext);

  const logout = async () => {
    setToken(undefined);
    setData(undefined);
    localStorage.clear();

    return (window.location.href = "/");
  };

  return (
    <div
      className={classes.profile}
      onClick={() => setOpen(!open)}
      style={{ position: "relative" }}
    >
      {localStorage.getItem("avatarUrl") &&
      localStorage.getItem("avatarUrl") !== "undefined" ? (
        <img
          src={localStorage.getItem("avatarUrl")}
          alt="avatar"
          className={classes.profile_image}
        />
      ) : (
        <Person />
      )}

      <p className={classes.noMargin}>{nome ? nome.split(" ")[0] : ""}</p>
      {open ? (
        <div className={classes.box}>
          <Link
            to={
              localStorage.getItem("partner_id")
                ? "painel"
                : "medita-bem-planos"
            }
            className={classes.link}
          >
            {localStorage.getItem("name")}
          </Link>
          <button
            className={classes.dropdown_button}
            onClick={() => {
              return (window.location.href = "/minha-conta");
            }}
          >
            Minha conta
          </button>
          <button
            className={classes.dropdown_button}
            onClick={() => {
              logout();
            }}
          >
            Sair
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProfileDropdown;
