import { makeStyles } from "@material-ui/core";
import React from "react";
import center from "../../../../assets/images/home/center.png";
import SectionMain from "../../../../components/SectionMain";

const useStyles = makeStyles({
  container: {},
  viewImage: {
    width: "70%",
    height: "100%",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
  },
  banner: {
    maxWidth: "95%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "center",
    color: "#36b37e",
  },
  leftSection: {
    height: "100%",
    maxWidth: "80%",
    margin: "auto",
    marginTop: 50,
  },
  titleTwo: {
    fontFamily: "Century Gothic",
    fontSize: "25px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#183b56",
  },
  description: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#5a7184",
  },
  align: { textAlign: "center" },
  margin: { margin: "auto", marginTop: 20 },
  color: { color: "#fff" },
});

const SectionSix = () => {
  const classes = useStyles();

  const token = localStorage.getItem("token");

  return (
    <SectionMain noPadding className={classes.container}>
      <SectionMain noPadding className={classes.banner}>
        <div>
          <div className={classes.title}>
            Playlists personalizadas no MeditaBem
            <br />
            <span className={classes.titleTwo}>
              Crie suas próprias playlists de áudio e vídeo para meditar,
              relaxar e muito mais.
            </span>
            <br />
            <span className={classes.description}>
              <p className={classes.align}>
                Organize músicas, mantras, meditações e práticas do seu jeito
                favorito e conforme seus objetivos.
              </p>
              <p className={classes.align}>
                Personalize suas sequências e aproveite os conteúdos em vários
                momentos do dia.
              </p>
              <p className={classes.align}>
                Com as suas playlists no MeditaBem, você sempre tem uma
                experiência sob medida, só sua.
              </p>
            </span>
          </div>
        </div>
      </SectionMain>

      <img src={center} className={classes.viewImage} />
    </SectionMain>
  );
};

export default SectionSix;
