import { makeStyles } from "@material-ui/core";
import React from "react";
import logo from "../../../../assets/images/logo.png";
import { navButtonsMenuDesktop } from "../../../../core/navButtons";
import useGoToPage from "../../../../hooks/useGoToPage";
import ModalLogin from "../../../ModalLogin";
import ProfileDropdown from "./ProfileDropdown";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  appBar: {
    display: "flex",
    width: "100%",
    maxWidth: "80%",
    padding: "1.3rem",
    alignItems: "center",
    justifyContent: "space-between",
    height: "55px",
    margin: "0 auto",
  },

  menu: {
    display: "flex",
    alignItems: "baseline",
    gap: "1.3rem",
  },

  list: {
    listStyleType: "none",
    display: "flex",
    alignItems: "baseline",
    gap: "1.3rem",
  },

  linkBold: {
    textDecoration: "none",
    color: "#0c4a6e",
    fontSize: ".94rem",
    fontWeight: "bold",
  },

  link: {
    textDecoration: "none",
    color: "#0c4a6e",
    fontSize: ".94rem",
    fontWeight: "normal",
  },
});

const TopMenuWrapper = ({ children }) => {
  const classes = useStyles();
  const goToPage = useGoToPage();

  return (
    <div className={classes.appBar}>
      <img
        alt="MeditaBem"
        src={logo}
        onClick={() => goToPage("/")}
        style={{
          width: "auto",
          height: "55px",
          cursor: "pointer",
        }}
      />

      <nav className={classes.menu}>
        {navButtonsMenuDesktop.map(({ title, link, bold }) => (
          <Link
            to={link}
            key={`link-to-${link}`}
            className={bold === "normal" ? classes.link : classes.linkBold}
          >
            {title}
          </Link>
        ))}
        <span>
          {localStorage.getItem("token") ? <ProfileDropdown /> : <ModalLogin />}
        </span>
      </nav>
    </div>
  );
};

export default TopMenuWrapper;
