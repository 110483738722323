import { Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import fetchPartnersData from "../../core/fetchPartners";
import SectionMain from "../SectionMain";
import PartnersListUI from "./PartnersListUI";

const PartnersList = ({ mobile }) => {
  const [partners, setPartners] = useState([]);

  const filterOrder = [
    "Beatriz Barbara",
    "Camila Montandon",
    "Heloísa Laranja",
    "Julia Vaz",
    "Juliana Borges",
    "Kakao Chao",
    "Nathalie Gretillat",
    "Paula Millen",
    "Yoga por Inteiro",
  ];

  useEffect(() => {
    async function fetchData() {
      await fetchPartnersData().then((partners) => setPartners(partners));
    }
    fetchData();
  }, []);

  const RenderPartners = (mobile) => {
    const partnerList = [];
    filterOrder.forEach((filter) => {
      partners.forEach(({ occupation, artisticName, thumbnailURL }) => {
        if (
          artisticName !== "Paula Millen e Kakao Chao" &&
          (artisticName || "") === filter
        ) {
          partnerList.push(
            <PartnersListUI
              mobile={mobile}
              key={`parceiro-${artisticName}`}
              occupation={occupation}
              artisticName={artisticName}
              thumbnailURL={thumbnailURL}
            />
          );
        }
      });
    });
    return partnerList;
  };

  return (
    <SectionMain noPadding position="center">
      <Hidden only={["xs", "sm"]}>
        <SectionMain position="space-between">{RenderPartners()}</SectionMain>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        {partners && (
          <ScrollMenu
            selected={"0"}
            alignOnResize={false}
            alignCenter={false}
            wheel={false}
            inertiaScrolling
            itemStyle={{ outline: "none", width: "40vw" }}
            wrapperStyle={{ width: "96vw" }}
            data={RenderPartners(true)}
          />
        )}
      </Hidden>
    </SectionMain>
  );
};

export default PartnersList;
