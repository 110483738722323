import React from "react";
import SectionThree from "./sections/SectionThree";
import SectionFour from "./sections/SectionFour";
import SectionSix from "./sections/SectionSix";
import SectionSeven from "./sections/SectionSeven";
import SectionEight from "./sections/SectionEight";
import FooterSection from "./sections/FooterSection";
import SectionOne from "./sections/SectionOne";
import SectionTwo from "./sections/SectionTwo";
import SectionFive from "./sections/SectionFive";

const HomeScreenMobile = () => {
  return (
    <div style={{ overflow: "hidden", marginBottom: "15%" }}>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
      <FooterSection />
    </div>
  );
};

export default HomeScreenMobile;
