import { Avatar, makeStyles } from "@material-ui/core";
import React from "react";
import useVideosArray from "../../hooks/useVideosArray";
import useVideosArrayPremium from "../../hooks/useVideosArrayPremium";
import { colorTitle } from "../../utils/getColorCategory";
import SectionMain from "../SectionMain";
import Title from "../Title";
import Player from "./Player";
const useStyles = makeStyles({
  root: {},
  titleSection: {
    fontWeight: 600,
    justifyContent: "space-between",
    width: "100%",
  },
  avatar: {
    marginRight: 15,
    width: 55,
    height: 55,
  },
  titleWithAvatar: {
    display: "inherit",
  },
});

const VideoPlayer = () => {
  const classes = useStyles();
  const videos = useVideosArray().concat(useVideosArrayPremium());
  const id = window.location.search.split("?id=").pop();
  const video = videos.find((video) => video.id.includes(id));

  const partner = video ? video.partner : {};
  return (
    <SectionMain noPadding>
      <Player
        src={video.videoURL}
        thumbnail={video.thumbnail}
        setor={video.category}
      />
      <SectionMain position="center" id="card-video-title">
        <SectionMain item xs={6} className={classes.titleSection}>
          <div className={classes.titleWithAvatar}>
            <Avatar
              className={classes.avatar}
              src={partner && partner.thumbnailURL}
            />
            <SectionMain noPadding id="video-player-description">
              <Title size="medium" fontWeight="600" align="left">
                {video.name}
                <br />
                <Title size="medium" fontWeight="400" align="left">
                  {partner && partner.artisticName}
                </Title>
              </Title>
            </SectionMain>
          </div>
        </SectionMain>
        <SectionMain noPadding item xs={6}>
          <Title
            color={colorTitle(video.category)}
            fontWeight="600"
            align="right"
          >
            {video.category}
          </Title>
        </SectionMain>
        <SectionMain noPadding style={{ marginTop: "15px" }}>
          <Title size="medium" align="left">
            {video.description}
          </Title>
        </SectionMain>
      </SectionMain>
    </SectionMain>
  );
};

export default VideoPlayer;
