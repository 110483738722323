import banner_footer from "../../../../assets/images/home/foto_news.png";

export const STYLES = {
  container: {
    background:
      "linear-gradient(180deg, rgba(250,251,251,0 ) 0.00%, #ececec 100.00%)",
    borderBottomLeftRadius: "40%",
    borderBottomRightRadius: "40%",
  },
  banner: {
    height: "700px",
    maxWidth: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "auto",
    marginTop: 20,
  },
  title: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#36b37e",
  },
  leftSectionTwo: {
    height: "100%",
    maxWidth: "80%",
    margin: "auto",
    marginTop: 50,
  },
  titleTwo: {
    fontFamily: "Century Gothic",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
  },
  titleSectionTwo: {
    fontFamily: "Century Gothic",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#3bad76",
  },
  description: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
  },
  containerThree: {
    background: "linear-gradient(-180deg, #6eb64b 0.00%, #009daa 100.00%)",
    // clipPath: "ellipse(100% 50%)",
    borderRadius: "0% 0% 150% 150%/0% 0% 40% 40%",
    paddingBottom: "10%",
  },
  leftSectionThree: {
    height: "100%",
    maxWidth: "80%",
    margin: "auto",
    marginTop: 50,
  },
  titleSectionThree: {
    fontFamily: "Century Gothic",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#ffffff",
  },
  descriptionThree: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#ffffff",
  },
  imageThree: {
    margin: "0 auto",
    maxWidth: "100%",
    height: "750px",
  },

  root: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 20,
    marginRight: 10,
    textAlign: "left",
  },
  avatar: {
    width: "64px",
    height: "64px",
    borderRadius: "16px",
    background: "#6eb64b",
    background: "linear-gradient(180deg,  #6eb64b 0.00%, #009daa 100.00%)",
  },
  titleItems: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
    marginTop: 20,
  },
  descItems: {
    width: "85%",
    fontFamily: "Century Gothic",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
  },

  bannerFooter: {
    backgroundImage: `url(${banner_footer})`,
    backgroundSize: "cover",
    backgroundPosition: "center top",
    height: "100%",
  },
};
