import React from 'react';
import SectionMain from '../SectionMain';
import { Hidden } from '@material-ui/core';
import IconCategoryButtonWrapper from './IconCategoryButtonWrapper';
import IconCategoryButtonUI from './IconCategoryButtonUI';

const IconCategoryButton = () => {
  return (
    <SectionMain position='center'>
      <Hidden only={['xs', 'sm']}>
        <IconCategoryButtonWrapper>
          {({ title, src, goTolink, active, color }) => (
            <IconCategoryButtonUI
              type='desktop'
              title={title}
              src={src}
              goTolink={goTolink}
              active={active}
              color={color}
            />
          )}
        </IconCategoryButtonWrapper>
      </Hidden>

      <Hidden only={['md', 'lg', 'xl']}>
        <div id='mobile-buttons-categorias'>
          <IconCategoryButtonWrapper>
            {({ title, src, goTolink, active, color }) => (
              <IconCategoryButtonUI
                type='mobile'
                title={title}
                src={src}
                goTolink={goTolink}
                active={active}
                color={color}
              />
            )}
          </IconCategoryButtonWrapper>
        </div>
      </Hidden>
    </SectionMain>
  );
};

export default IconCategoryButton;
