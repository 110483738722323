import React from 'react';
import VideosCardUI from './VideosCardUI';
import VideosCardWrapper from './VideosCardWrapper';

const VideosCard = ({
  videos = [],
  scrollable,
  showIconAndAvatar,
  position,
  renderMapCards,
  inLine,
}) => {
  return (
    <VideosCardWrapper
      videos={videos}
      position={position}
      renderMapCards={renderMapCards}
      scrollable={scrollable}
      showIconAndAvatar={showIconAndAvatar}
      inLine={inLine}
    >
      {({
        index,
        last,
        title,
        thumbnail,
        partnerName,
        partnerAvatar,
        link,
        icon,
        goToPage,
      }) => (
        <VideosCardUI
          last={last}
          index={index}
          title={title}
          showIconAndAvatar={showIconAndAvatar}
          thumbnail={thumbnail}
          partnerName={partnerName}
          partnerAvatar={partnerAvatar}
          link={link}
          icon={icon}
          goToPage={goToPage}
          renderMapCards={renderMapCards}
        />
      )}
    </VideosCardWrapper>
  );
};

export default VideosCard;
