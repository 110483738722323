import { Hidden, IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import center from "../../../../assets/images/home/center.png";
import SectionMain from "../../../../components/SectionMain";
import ButtonMedita from "../../../../components/ButtonMedita";
import { ArrowForward, ArrowRight } from "@material-ui/icons";

const useStyles = makeStyles({
  container: {
    minHeight: "750px",
    marginTop: "20px",
    position: "relative",
  },
  banner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "auto",
    marginTop: 20,
    marginBottom: 20,
  },
  viewImage: {
    width: "70%",
    pointerEvents: "none",
    marginLeft: "-20%",
  },
  leftSection: {
    width: "35%",
    marginRight: "10%",
    display: "flex",
  },
  title: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#36b37e",
  },
  titleTwo: {
    fontFamily: "Century Gothic",
    fontSize: "35px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
  },
  description: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
  },
  root: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 20,
    marginRight: 10,
    textAlign: "left",
  },
  avatar: {
    width: "64px",
    height: "64px",
    borderRadius: "16px",
    background: "#6eb64b",
    background: "linear-gradient(180deg,  #6eb64b 0.00%, #009daa 100.00%)",
  },
  titleItems: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
    marginTop: 20,
  },
  descItems: {
    width: "85%",
    fontFamily: "Century Gothic",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
  },

  align: {
    textAlign: "left",
    marginBottom: 50,
  },
  viewCenter: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  margin: { marginTop: 50 },
  color: { color: "#fff" },
});

const SectionSix = () => {
  const classes = useStyles();

  const token = localStorage.getItem("token");

  return (
    <SectionMain noPadding className={classes.container}>
      <SectionMain className={classes.banner}>
        <img src={center} className={classes.viewImage} />

        <SectionMain className={classes.leftSection}>
          <div className={classes.title}>
            Playlists personalizadas no MeditaBem
            <br />
            <div style={{ marginTop: 30 }}>
              <span className={classes.titleTwo}>
                Crie suas próprias playlists de áudio e vídeo para meditar,
                relaxar e muito mais.
              </span>
              <br />
              <div style={{ width: "90%" }}>
                <span className={classes.description}>
                  <p className={classes.align}>
                    Organize músicas, mantras, meditações e práticas do seu
                    jeito favorito e conforme seus objetivos.
                  </p>
                  <p className={classes.align}>
                    Personalize suas sequências e aproveite os conteúdos em
                    vários momentos do dia.
                  </p>
                  <p className={classes.align}>
                    Com as suas playlists no MeditaBem, você sempre tem uma
                    experiência sob medida, só sua.
                  </p>
                </span>
              </div>
            </div>
          </div>

          {!token && (
            <ButtonMedita classes={classes.margin}>
              Teste Grátis
              <IconButton size="large" className={classes.color}>
                <ArrowForward />
              </IconButton>
            </ButtonMedita>
          )}
        </SectionMain>
      </SectionMain>
    </SectionMain>
  );
};

export default SectionSix;
