import React from "react";
import { makeStyles } from "@material-ui/core";
import SectionMain from "../SectionMain";
import Modal from "react-modal";
import RegisterUserPage from "../../pages/register_user";

import ButtonRegister from "./ButtonRegister";
import ButtonGradient from "./ButtonGradient";
import ButtonWhite from "./ButtonWhite";
import FooterSection from "../FooterSection";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.3)";

const useStyles = makeStyles({
  modal: {
    width: "380px",
    background: "#fff",
    margin: "40px auto",
    borderRadius: "20px",
    padding: "20px",
  },
});

const ModalRegister = ({ buttonStyle, text }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const classes = useStyles();
  const getButton = (buttonStyle) => {
    switch (buttonStyle) {
      case "gradient":
        return <ButtonGradient openModal={openModal} text={text} />;
      case "white":
        return <ButtonWhite openModal={openModal} text={text} />;
      default:
        return <ButtonRegister openModal={openModal} />;
    }
  };

  return (
    <SectionMain>
      {getButton(buttonStyle)}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Cadastro"
        className={classes.modal}
      >
        <RegisterUserPage />
      </Modal>

      <SectionMain>
        <FooterSection hideNewsletter={true} />
      </SectionMain>
    </SectionMain>
  );
};

export default ModalRegister;
