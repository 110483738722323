import React from 'react'
import SectionMain from '../../components/SectionMain'
import { Hidden } from '@material-ui/core'
import AboutUsPageDesktop from './desktop'
import AboutUsPageMobile from './mobile'

const AboutUsPage = () => {
	return (
		<SectionMain noPadding>
			<Hidden only={[ 'xs', 'sm' ]}>
				<AboutUsPageDesktop />
			</Hidden>
			<Hidden only={[ 'md', 'lg', 'xl' ]}>
				<AboutUsPageMobile />
			</Hidden>
		</SectionMain>
	)
}

export default AboutUsPage
