import React from "react";
import SectionMain from "../../components/SectionMain";
import PlansUserPage from "./desktop";
import { Hidden } from "@material-ui/core";
import PlansUserPageMobile from "./mobile";

const PlanUserPage = () => {
  return (
    <SectionMain
      noPadding
      position="center"
      // style={{
      //   backgroundColor: "#F3FAF7",
      //   borderRadius: "150% 150% 0% 0%/40% 40% 0% 0%",
      //   paddingBottom: "5%",
      // }}
    >
      <Hidden only={["xs", "sm"]}>
        <PlansUserPage />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <PlansUserPageMobile />
      </Hidden>
    </SectionMain>
  );
};

export default PlanUserPage;
