import React, { useState, useEffect } from "react";
import SectionMain from "../../../components/SectionMain";
import InputText from "../../../components/InputText";
import ButtonMedita from "../../../components/ButtonMedita";
import "./style.css";
import api from "../../../core/api";

const PartnersPanelProfileDesktop = () => {
  const [name, setName] = useState("");
  /* eslint-disable no-unused-vars */
  const [category, setCategory] = useState("");
  const [email, setEmail] = useState("");

  const fetchMe = async () => {
    const response = await api.get(`/users/${localStorage.getItem("id")}`);
    setName(response.data.name);
    setCategory(response.data.objectives);
    setEmail(response.data.email);

    console.log(response.data);
  };

  const modifyUser = async () => {
    api.put(`/users/${localStorage.getItem("id")}`, { name, email });
    localStorage.setItem("name", name);
    localStorage.setItem("email", email);
  };

  useEffect(() => {
    fetchMe();
  }, []);

  return (
    <SectionMain noPadding className="">
      <div className="input-painel">
        <InputText
          value={name}
          placeholder="Seu nome"
          onChange={({ target }) => setName(target.value)}
        />
        {/* <InputText value={category} /> */}
        <InputText
          value={email}
          placeholder="Seu email"
          onChange={({ target }) => setName(target.value)}
        />
        <ButtonMedita onClick={() => modifyUser()}>Enviar</ButtonMedita>
      </div>
    </SectionMain>
  );
};

export default PartnersPanelProfileDesktop;
