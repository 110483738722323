export const StatusPayment = {
  PENDING: "PENDENTE",
  RECEIVED: "PAGO",
  CONFIRMED: "PAGO",
  OVERDUE: "VENCIDO",
  REFUNDED: "DEVOLVIDO",
  RECEIVED_IN_CASH: "RECEBIDO EM DINHEIRO",
  REFUND_REQUESTED: "REEMBOLSO SOLICITADO",
  REFUND_IN_PROGRESS: "REEMBOLSO EM ANDAMENTO",
  CHARGEBACK_REQUESTED: "ESTORNO SOLICITADO",
  CHARGEBACK_DISPUTE: "DISPUTA DE ESTORNO",
  AWAITING_CHARGEBACK_REVERSAL: "AGUARDANDO REVERSÃO DE ESTORNO",
  DUNNING_REQUESTED: "COBRANÇA SOLICITADA",
  DUNNING_RECEIVED: "COBRANÇA RECEBIDA",
  AWAITING_RISK_ANALYSIS: "AGUARDANDO ANÁLISE DE RISCO",
};
