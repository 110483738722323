export const STYLE = {
  container: { padding: "20px 4vw", justifyContent: "center" },
  media: {
    padding: "20px 4vw",
    justifyContent: "space-between",
  },
  btnModal: {
    display: "block",
    margin: "20px auto 0",
    width: "290px",
    height: "60px",
  },
  title: {
    width: 210,
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 0 30px",
  },
  inputs: { padding: 15 },
  cursive: { color: "#008393", letterSpacing: 2 },
  register: {
    borderRadius: 20,
    // border: "solid 1px #EEE",
    padding: "15px 20px",
    position: "sticky",
    top: "3vw",
    justifyContent: "center",
    maxWidth: "500px",
    // boxShadow: "1px 1px 10px -2px rgba(0,0,0,0.3)",
  },
  criarContaBtn: {
    background: "#316570 !important",
    width: "240px !important",
  },
  divider: {
    width: "42%",
    height: "0.5px",
    backgroundColor: "#d8d8d8",
    border: "solid 0.5px #d8d8d8",
  },
  facebookBtn: {
    display: "flex",
    boxShadow: "none",
    justifyContent: "flex-start",
    width: "90% !important",
    color: "#282828 !important",
    background: "#EEEEEE !important",
    textAlign: "center",
  },
  facebookText: {
    textTransform: "initial",
    textAlign: "center",
    margin: " 0 auto",
    display: "block",
  },
  verticalCenter: {
    display: "block",
    margin: "auto",
  },
  experimente: {
    fontSize: "19px",
  },

  leftSectionCelular: {
    // background: "#f8f9fb",
  },
  rightSectionCelular: {
    justifyContent: "center",
    background: `#6D8E89`,
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundSize: "cover",
    width: "100%",
    padding: "20px 50px",
  },
  txtMobile: {
    maxWidth: "90%",
    minWidth: "60%",
    margin: "20px auto",
    display: "block",
    textAlign: "center",
    fontSize: "17px",
  },
  link: {
    fontWeight: "700",
    color: "#fff",
  },
  imageLeftScreens: {
    maxWidth: "90%",
    maxHeight: "500px",
    display: "block",
    margin: "0 auto",
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
  },
  btnBeneficios: {
    background: "#fff",
  },
  btn: {
    width: "280px !important",
  },
  badgeAppStores: {
    cursor: "pointer",
    maxWidth: "200px",
  },
};
