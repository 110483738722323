const makeTransparentCode = (colorHex, active) => {
  const mapHexToLighterRGB = {
    '#316570': 'rgba(76, 200, 54, 0.1)',
    '#fac742': 'rgba(250, 199, 66, 0.1)',
    '#ffdc00': 'rgba(255, 220, 0, 0.1)',
    '#4cc836': 'rgba(76, 200, 54, 0.1)',
    '#424242': '#EEE',
  };

  const mapHexToLightRGB = {
    '#2d6671': 'rgba(49, 101, 112, 0.6)',
    '#feaa1a': 'rgba(250, 199, 66, 0.6)',
    '#fed904': 'rgba(255, 220, 0, 0.6)',
    '#4cc836': 'rgba(76, 200, 54, 0.6)',
    '#424242': '#EEE',
    "#fd8694": 'rgba(253, 134, 148, 0.6)',
    '#31b1f6': 'rgba(49, 177, 246, 0.6)'
  };

  // return active ? mapHexToLightRGB[colorHex] : mapHexToLighterRGB[colorHex];
  return mapHexToLightRGB[colorHex];
};

export const STYLE = {
  btnDesktop: {
    backgroundColor: ({ color, active }) => (active ? color : '#f9f9f9'),
    width: '15vw',
    maxWidth: 180,
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 24,
    border: '1px solid #D4D4D4',
    '&:hover': {
      backgroundColor: ({ color, active }) =>
        `${makeTransparentCode(color, active)} !important`,
    },
    marginBottom: '15px'
  },
  btnMobile: {
    maxWidth: 150,
    width: '40vw',
    height: 50,
    backgroundColor: ({ color, active }) => (active ? color : '#fff'),
    borderRadius: 24,
    padding: '10px',
    margin: '0 10px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '#D4D4D4',
    border: '1px solid #D4D4D4',
    '&:hover': {
      backgroundColor: ({ color, active }) =>
        `${makeTransparentCode(color)} !important`,
    },
  },

  image: {
    maxHeight: 71,
    paddingBottom: 5,
    minHeight: 30,
    height: '10vw',
    transition: '0.5s',
  },
  title: {
    padding: '0px 5px',
    textTransform: 'capitalize',
    color: ({ color, active }) => {
      if (color === '#EEE') return '#282828';
      else return !active ? color : '#f9f9f9';
    },
  },
};
