import React from 'react';
import { makeStyles, Avatar } from '@material-ui/core';
import SectionMain from '../../SectionMain';
import Title from '../../Title';
import { STYLE } from './style';
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles(STYLE);

const VideosCardUI = ({
  index = 0,
  last,
  title,
  thumbnail,
  partnerName,
  partnerAvatar,
  link,
  icon,
  renderMapCards,
  goToPage,
  inLine,
  showIconAndAvatar,
}) => {
  const classes = useStyles({
    thumbnail,
    index,
    last,
    renderMapCards,
  });

  return (
    <SectionMain
      className={classes.container}
      id={inLine ? 'card-video-inline' : 'card-video-responsive-container'}
      onClick={() => goToPage(link)}
    >
      <SectionMain
        className={inLine ? classes.cardBigger : classes.card}
        id={'card-video-responsive'}
      >
        <SectionMain
          className={classes.description}
          id={showIconAndAvatar ? '' : 'card-video-description'}
        >
          <SectionMain
            className={classes.icon}
            id={showIconAndAvatar ? '' : 'card-video-icon'}
          >
            {icon}
          </SectionMain>
          <SectionMain
            noPadding
            item
            xs={3}
            md={4}
            lg={3}
            id={showIconAndAvatar ? '' : 'avatar-video'}
          >
            <Avatar className={classes.avatar} src={partnerAvatar} />
          </SectionMain>

          <SectionMain
            item
            xs={showIconAndAvatar ? 8 : 12}
            md={8}
            lg={9}
            noPadding
            position='left'
            className={classes.cardContentContainer}
          >
            <Title
              className={classes.mainTitle}
              id='line-clamp-1'
              fontWeight='600'
              id={showIconAndAvatar ? '' : 'video-card-main-title'}
            >
              {title}
            </Title>
            <Title className={classes.title} id='line-clamp-1'>
              {partnerName}
            </Title>
          </SectionMain>
        </SectionMain>
      </SectionMain>
    </SectionMain>
  );
};

export default VideosCardUI;
