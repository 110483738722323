import React from "react";
import { Button, CircularProgress, withStyles } from "@material-ui/core";

const ButtonMedita = ({
  children,
  width,
  height,
  color = "#fff",
  variant = "contained",
  size = "medium",
  backgroundColor = "linear-gradient(38deg, #008f9d 0%,#68b43c 106%)",
  loading = false,
  ...props
}) => {
  const getSizeButton = {
    small: 182,
    medium: 330,
  };
  const StyledButton = withStyles({
    root: {
      width: !width ? getSizeButton[size] : width,
      height: !height ? 50 : height,
      fontSize: 12,
      borderRadius: "22px",
      background: variant !== "contained" ? "#fff" : backgroundColor,
      color: variant !== "contained" ? "#282828" : color,
    },
  })(Button);

  return (
    <StyledButton
      id="button-submit"
      color="primary"
      {...props}
      fullWidth
      variant={variant}
    >
      {loading ? <CircularProgress color="#fff" /> : children}
    </StyledButton>
  );
};

export default ButtonMedita;
