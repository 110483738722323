import { useAppContext } from '../store/AppContext'

const useDrawerMenu = () => {
	const { drawerMenu, setAppState } = useAppContext()

	const handleStatusChange = (state) => setAppState({ drawerMenu: state })

	return [ drawerMenu, handleStatusChange ]
}

export default useDrawerMenu
