import { useAppContext } from '../store/AppContext'

const usePartnerForm = () => {
	const { setAppState, partnerForm } = useAppContext()

	const handlePartnerForm = (values) => setAppState({ partnerForm: values })

	return [ partnerForm, handlePartnerForm ]
}

export default usePartnerForm
