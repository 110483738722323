import banner from "../../assets/images/banners/partner_desktop.jpg";

export const STYLE = {
  banner: {
    background: "#f8f9fa",
    height: 400,
    width: "100vw",
    justifyContent: "center",
    padding: "20px 2vw",
    backgroundImage: `url(${banner})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  bannerContent: {
    backgroundColor: "#ffffffbf",
    borderRadius: 15,
    padding: "0.6vw 0px",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
  },
  center: {
    textAlign: "center",
  },
  title: {
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 0 30px",
    paddingTop: 10,
  },
  cursive: {
    color: "#008393",
  },
  content: {
    // width: 350,
    height: 160,
    padding: "10px 0px",
    alignItems: "center",
    display: "contents",
  },
  bePartnerBtn: {
    textTransform: "none",
    width: "250px !important",
  },
};
