import React, { useEffect, useState } from "react";

import PersonalDetails from "./PersonalDetails";
import RegisterCard from "./RegisterCard";
import VerifyCode from "./VerifyCode";
import SuccessCard from "./SuccessCard";
import { Stepper } from "react-form-stepper";
import Title from "../Title";
import { Divider } from "@material-ui/core";

const Form = () => {
  const [formStep, setFormStep] = useState(0);
  const [subsResponse, setSubsResponse] = useState(null);
  const [formUser, setFormUser] = useState({
    email: localStorage.getItem("emailActive") || "",
    password: localStorage.getItem("passActive") || "",
    id: localStorage.getItem("idActive") || "",
  });

  const id = localStorage.getItem("id");
  const [state, setState] = useState({
    step: id ? 3 : formUser.id ? 2 : 1,
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    courses: [],
    level: "",
    isErrorFirstName: true,
    isErrorLastName: true,
    errorMessageFirstName: "",
    errorMessageLastName: "",
  });

  const nextStep = () => {
    setState({ ...state, step: state.step + 1 });
  };

  const prevStep = () => {
    setState({ ...state, step: state.step - 1 });
  };

  const renderContent = () => {
    switch (state.step) {
      case 1:
        return (
          <PersonalDetails
            nextStep={nextStep}
            step={state.step}
            setFormUser={setFormUser}
          />
        );
      case 2:
        return (
          <VerifyCode
            nextStep={nextStep}
            prevStep={prevStep}
            step={state.step}
            formUser={formUser}
          />
        );
      case 3:
        return (
          <RegisterCard
            nextStep={nextStep}
            prevStep={prevStep}
            step={state.step}
            setFormStep={setFormStep}
            register={true}
            setSubsResponse={setSubsResponse}
          />
        );
      case 4:
        return (
          <SuccessCard
            nextStep={nextStep}
            prevStep={prevStep}
            step={state.step}
            stepFree={formStep}
            subsResponse={subsResponse}
          />
        );

      //

      default:
        return null;
    }
  };

  useEffect(() => {
    if (formStep === 1) {
      setState({ ...state, step: 4 });
    }
  }, [formStep]);

  return (
    <div
      style={{
        backgroundColor: "transparent",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ backgroundColor: "#F8F9FA", width: "100%", padding: 20 }}>
        <Title size="xbig" fontWeight="700" color="#424242" align="center">
          CRIAR CONTA
        </Title>
      </div>

      <Stepper
        steps={[
          { label: "Senha" },
          { label: "Verificação" },
          { label: "Plano" },
          { label: "Conclusão" },
        ]}
        activeStep={state.step - 1}
        styleConfig={{
          activeBgColor: "#8BCD98",
          activeTextColor: "#fff",
          inactiveBgColor: "#D9D9D9",
          inactiveTextColor: "#fff",
          completedBgColor: "#8BCD98",
          completedTextColor: "#fff",
          size: "3em",
          border: "none",
        }}
        className={"stepper form"}
      />
      {renderContent()}
    </div>
  );
};

export default Form;
