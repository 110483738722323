import React from 'react';
import { makeStyles } from '@material-ui/core';
import SectionMain from '../../../components/SectionMain';
import Title from '../../../components/Title';
import ButtonMedita from '../../../components/ButtonMedita';
import useGoToPage from '../../../hooks/useGoToPage';
import FooterSection from '../../../components/FooterSection';
import { STYLE } from './style';
import { STYLE as DESKTOP_STYLE } from '../desktop/style';
import FacebookIconSrc from '../../../assets/images/icons/facebook.png';

import FormContactMinimalMinimal from '../../../components/FormContactMinimal';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import gplayBadge from '../../../assets/images/google-play-badge.png';
const useStyles = makeStyles({ ...DESKTOP_STYLE, ...STYLE });

const AboutUsPageMobile = () => {
  const classes = useStyles();
  const goToPage = useGoToPage();
  return (
    <SectionMain noPadding position='center' className={classes.container}>
      <SectionMain className={classes.text}>
        <Title align='left' fontWeight='800' letterSpacing={2}>
          <h2>A MEDITA</h2>
        </Title>

        <br />
        <Title align='left' className={classes.mainText}>
          Somos uma startup brasileira, animada, que inventa e se reinventa o
          tempo todo para oferecer bem-estar as pessoas. Para isso, usamos como
          ferramenta a tecnologia, mas defendemos a ideia de que celulares e
          tablets são apenas instrumentos e devem ser usados com moderação.
          Contradição? Nós achamos que não. Aqui, trabalhamos para que nossa
          comunidade possa usar o aparelhos para aprender coisas, mas buscamos
          conteúdos que estimulem a prática fora da tela, no dia-a-dia,
          experimentando, se movimentando, testando e vivenciando!
          <br />
          <br />
          Nós buscamos pessoas que, como nós, desejam, precisam e acreditam ser
          possível ter mais qualidade de vida, não importa onde estejam. Nós
          somos gratos pela oportunidade de aprender algo novo o tempo todo com
          os professores, autores e especialistas que colaboram com o nosso
          aplicativo. Somos gratos pela generosidade de cada pessoa que
          participa dos nossos projetos e sonhos.
          <br />
          <br />
          Nosso compromisso? Continuar aprendendo sempre, entender o que você
          busca para se sentir bem no dia-a-dia e fazer o nosso melhor para
          levar isso até você. Ah, no meio dessa jornada tem muita evolução,
          erros e acertos, risadas, dias corridos e mestres incríveis que
          trabalham com bem-estar há muito tempo e acolhem com carinho e
          compreensão esse nosso início.
          <br />
          <br />
          E agora tem você, que está lendo tudo isso.
          <br />
          Muito obrigado :)
        </Title>
      </SectionMain>

      <SectionMain>
        <SectionMain item xs={12} className={classes.rightSectionCelular}>
          <Title color='#fff' align='center' className={classes.txtMobile}>
            <Title
              color='#fff'
              align='center'
              fontWeight='700'
              className={classes.titleMobile}
            >
              Playlists criadas para e por você
            </Title>
            <br />
            Crie, ordene e personalize playlists com conteúdos exclusivos de
            acordo com o seu humor. Tudo isso em um único lugar. Experimente!
            <br />
            <br />
            <a
              href='https://play.google.com/store/apps/details?id=com.newmeditabemapp'
              target='_blank'
              className={classes.link}
            >
              LER MAIS SOBRE O APLICATIVO
            </a>
          </Title>
        </SectionMain>

        <SectionMain item xs={12}>
          <SectionMain className={classes.modal}>
            <SectionMain position='flex-start' noPadding direction='column'>
              <SectionMain align='center'>
                <Title fontWeight='800' align='center'>
                  <h2>Conteúdos sem anúncios e interrupções</h2>
                </Title>
                <Title align='center' size='small'>
                  Salve os seus favoritos, pratique conteúdos
                  <br />
                  ilimitados e conecte-se com nossos professores.
                  <br />É fácil. É simples. É grátis.
                </Title>
              </SectionMain>
              <FormContactMinimalMinimal mobile={true} />

              {/* <div
                className='flex-row center-b'
                style={{ justifyContent: 'space-between', padding: '15px 0' }}
              >
                <div className='flex-row' className={classes.divider} />
                <Title color='#282828'>ou</Title>
                <div className='flex-row' className={classes.divider} />
              </div>
              <div
                className='flex-row center-b'
                style={{ justifyContent: 'center', paddingTop: '15px' }}
              >
                <FacebookLogin
                  appId='915077175552670'
                  callback={(res) => alert(JSON.stringify(res))}
                  render={(renderProps) => (
                    <ButtonMedita
                      className={classes.facebookBtn}
                      onClick={renderProps.onClick}
                    >
                      <img src={FacebookIconSrc} />
                      <SectionMain
                        position='center'
                        noPadding
                        className={classes.facebookText}
                      >
                        Acesse com seu facebook
                      </SectionMain>
                    </ButtonMedita>
                  )}
                />
              </div> */}
            </SectionMain>
          </SectionMain>
        </SectionMain>
      </SectionMain>

      <SectionMain noPadding className={classes.subText}>
        <SectionMain className={classes.titleAllMoments}>
          <Title size='medium' fontWeight='700' align='center'>
            Use o MeditaBem em todos os momentos
          </Title>
        </SectionMain>

        <Title align='center' className={classes.getAppText}>
          Nossos conteúdos de vídeos, áudios e <br /> músicas podem mudar seu
          estado de espírito. Experimente de graça em seu celular, tablet ou TV.
        </Title>
        <img src={gplayBadge} className={classes.badgeAppStores} />
      </SectionMain>
      <SectionMain>
        <FooterSection mobile />
      </SectionMain>
    </SectionMain>
  );
};

export default AboutUsPageMobile;
