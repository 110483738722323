import banner_celular from '../../../assets/images/banners/Banner_home_celular.png';
export const STYLE = {
  container: { padding: '0px', justifyContent: 'center' },
  banner: {
    padding: '15px 2vw',
    minWidth: 320,
    height: 380,
    background: '#f8f9fa',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  titleCategories: {
    textTransform: 'uppercase',
    letterSpacing: 1,
    justifyContent: 'center',
    padding: '0 4vw 30px',
  },
  text: { flexDirection: 'column', alignItems: 'center', padding: '0 30px' },
  title: {
    width: 210,
    flexDirection: 'column',
    letterSpacing: 1,
    justifyContent: 'center',
    padding: '15px 0px',
    paddingBottom: 0,
  },
  cursive: {
    color: '#008393',
    letterSpacing: 2,
    fontFamily: 'adobe-caslon-pro, serif',
    fontStyle: 'italic',
    fontSize: 24,
  },
  subText: {
    alignItems: 'center',
    padding: '0',
    justifyContent: 'center',
  },
  getAppText: {
    width: '90%',
    display: 'block',
    margin: '0 auto',
    fontSize: '16px',
  },

  mainText: {
    fontSize: '15px',
  },

  txtMobile: {
    maxWidth: '90%',
    minWidth: '60%',
    margin: '0 auto',
    display: 'block',
    textAlign: 'center',
    fontSize: '17px',
  },
  rightSectionCelular: {
    justifyContent: 'center',
    backgroundImage: `url(${banner_celular})`,
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '549px',
    backgroundPosition: 'center',
    padding: '20px',
  },
  leftSectionCelular: {
    background: '#f8f9fb',
  },
  criarContaBtn: {
    background: '#316570 !important',
    maxWidth: '200px',
  },
  divider: {
    width: '42%',
    height: '0.5px',
    backgroundColor: '#d8d8d8',
  },
  facebookBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#282828 !important',
    background: '#EEEEEE !important',
    textAlign: 'center',
    boxShadow: 'none !important',
    width: '100% !important',
  },
  facebookText: {
    textTransform: 'initial',
    textAlign: 'center',
    margin: ' 0 auto',
    display: 'block',
  },
  modal: {
    maxHeight: '580px',
    padding: '15px 20px',
    position: 'sticky',
    top: '3vw',
    // margin: '0 20px',
    justifyContent: 'center',
  },
  badgeAppStores: {
    width: '170px',
    height: '68px',
    margin: '20px',
  },
  link: {
    fontWeight: '700',
    color: '#fff',
  },

  titleAllMoments: {
    maxWidth: '70%',
    margin: '20px 0',
  },
};
