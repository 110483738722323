import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import BePartner from "../../../components/BePartner";
import FooterSection from "../../../components/FooterSection";
import PartnersList from "../../../components/PartnersList";
import SectionMain from "../../../components/SectionMain";
import Title from "../../../components/Title";
import { STYLE } from "./style";

const useStyles = makeStyles(STYLE);

const PartnersPageDesktop = ({ handleSubmitForm }) => {
  const classes = useStyles();
  const [state, setState] = useState({ email: "" });

  return (
    <SectionMain noPadding>
      <BePartner navigate={handleSubmitForm}></BePartner>
      <br />
      <SectionMain className={classes.container}>
        <SectionMain className={classes.title}>
          <Title
            size="medium"
            align="center"
            capitalize="uppercase"
            fontWeight="800"
            letterSpacing={2}
          >
            {"PARCEIROS EM DESTAQUE"}
          </Title>
        </SectionMain>

        <PartnersList />
      </SectionMain>

      <SectionMain className={classes.title}>
        <Title
          align="center"
          size="medium"
          fontWeight="800"
          letterSpacing="2"
          className={classes.center}
        >
          MeditaBem: um círculo virtuoso
        </Title>
      </SectionMain>

      <SectionMain className={classes.container}>
        <Title size="small" align="left">
          Assim como nós, você deseja oferecer conteúdos de bem-estar de forma
          acolhedora e simples, ajudando pessoas de todas as idades e estilos a
          adotar hábitos mais saudáveis em seu dia-a-dia? Então, junte-se ao
          time de autores e professores do MeditaBem e conte com um canal
          especializado para divulgar seu trabalho de uma forma leve e prática.
          <br />
          <br />
          Além de acompanhar os conteúdos, a comunidade do MeditaBem também tem
          acesso à agenda de parceiros no próprio aplicativo, com nome,
          fotografia, apresentação e os dados de contato que cada autor e
          professor deseja publicar, incluindo sua mídia social para que as
          pessoas possam seguir e prestigiar.
          <br />
          <br />
          Gostou do MeditaBem e deseja saber mais sobre como participar? Deixe
          seus dados e em breve entraremos em contato. Obrigado :)
        </Title>
      </SectionMain>

      {/* <SectionMain item xs={6} className={classes.section}>
        <SectionMain className={classes.modal}>
          <Title color="#282828" align="center" className={classes.txtMobile}>
            <Title
              color="#282828"
              align="center"
              fontWeight="800"
              className={classes.titleMobile}
            >
              Explore e descubra seu perfil
            </Title>
            <br />
            Um espaço exclusivo somente para os seus conteúdos, informações e
            contato. <br /> Acompanhe os resultados dos seus conteúdos em nossa
            plataforma exclusiva
            <br />
            <br />
          </Title>
          <img src={banner} className={classes.image} />
        </SectionMain>
      </SectionMain> */}

      {/* <SectionMain item xs={6} className={classes.section}>
        <SectionMain className={classes.modal}>
          <Title color="#282828" align="center" className={classes.txtMobile}>
            <Title
              color="#282828"
              align="center"
              fontWeight="800"
              className={classes.titleMobile}
            >
              Faça parte do time MeditaParceiros
            </Title>
            <br />
            Leve sua mensagem muito mais longe e acompanhe os resultados dos
            seus conteúdos em nossa <br /> plataforma exclusiva. Entre em
            contato e experimente!
            <br />
            <br />
          </Title>
          <SectionMain className={classes.formBlock}>
            <FormParceiro handleSubmitForm={handleSubmitForm} />
          </SectionMain>
        </SectionMain>
      </SectionMain> */}

      <SectionMain>
        <FooterSection hideNewsletter={true} />
      </SectionMain>
    </SectionMain>
  );
};

export default PartnersPageDesktop;
