import React, { useEffect } from "react";
import SectionMain from "../../components/SectionMain";
import { Hidden } from "@material-ui/core";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import AccountPageDesktop from "./desktop";
import AccountPageMobile from "./mobile";

const AccountPage = () => {
  const UpdateUser = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useUpdateUser();
  };
  useEffect(() => {
    UpdateUser();
  }, []);

  return (
    <SectionMain noPadding position="center">
      <Hidden only={["xs", "sm"]}>
        <AccountPageDesktop />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <AccountPageMobile />
      </Hidden>
    </SectionMain>
  );
};

export default AccountPage;
