import React from "react";
import Modal from "react-modal";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  modal: {
    width: "300px",
    background: "#fff",
    margin: "200px auto",
    borderRadius: "13px",
    padding: "20px",
    background: "#eee",
    textAlign: "center",
    zIndex: 9999,
  },
  divider: {
    height: "1px",
    background: "#CDCED2",
    margin: "10px",
  },
  iosLikeButton: {
    background: "none",
    border: "none",
    display: "block",
    margin: "0 auto",
    color: "#0076FF",
    fontSize: "17px",
    fontWeight: "600",
  },
  modalTitle: {
    fontWeight: "600",
    margin: "10px",
  },
});

const ModalFormComplete = ({ isOpen, closeModal, title, text, goToPage }) => {
  const classes = useStyles();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={goToPage || closeModal}
      contentLabel="Cadastro"
      className={classes.modal}
      id="modal-result"
    >
      <h3 className={classes.modalTitle}>{title}</h3>
      {text}
      <div className={classes.divider}></div>
      <button
        className={classes.iosLikeButton}
        onClick={goToPage || closeModal}
      >
        OK
      </button>
    </Modal>
  );
};

export default ModalFormComplete;
