export const STYLES = {
  banner: {
    display: 'block !important',
    width: 'auto',
    margin: 0,
    padding: 0,
  },
  overlayContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },
  shadow: {
    textTransform: 'inherit',
    transition: '0.5s',
    fontSize: '30px',
  },
  subText: {
    width: '70%',
    display: 'block',
    transition: '0.5s',
    fontSize: '18px',
    margin: '0 auto',
  },
  inputs: {
    width: ({ mobile }) => (mobile ? '100%' : '70%'),
    height: 160,
    padding: '10px 0px',
    justifyContent: 'space-between',
  },
  mobileButton: {
    display: 'block',
    margin: '10px auto',
    background: '#316570 !important',
    minWidth: '200px',
    border: 'none',
  },
};
