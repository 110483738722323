import { PARTNERS } from "./partners";

const { default: api } = require("./api");

const fetchPartnersData = async () => {
  const partnersLocal = localStorage.getItem("partners");
  let partners = null;
  if (!partnersLocal) {
    try {
      // partners = (await api.get(`/partners/site`)).data
      partners = PARTNERS;
      // localStorage.setItem("partners", JSON.stringify(partners));
    } catch (e) {
      console.log({ e });
    }
  } else {
    partners = await JSON.parse(partnersLocal);
  }

  const partnersString = partners
    ? partners.map((partner) => ({ ...partner, ...JSON.parse(partner.info) }))
    : [];
  // console.log({ partnersString });
  return partnersString;
};

export default fetchPartnersData;
