import React from 'react'
import FormPartnerWrapper from './FormPartnerWrapper'
import FormPartnerUI from './FormPartnerUI'

const FormPartner = ({ formikProps, mobile }) => {
	return (
		<FormPartnerWrapper formikProps={formikProps} mobile={mobile}>
			{({ keyObject, label, icon, type, handleFormChange, value, errorMessage }, i) => (
				<FormPartnerUI
					mobile={mobile}
					key={i}
					keyObject={keyObject}
					label={label}
					icon={icon}
					type={type}
					handleFormChange={handleFormChange}
					value={value}
					errorMessage={errorMessage}
				/>
			)}
		</FormPartnerWrapper>
	)
}

export default FormPartner
