import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import SectionMain from "../../../components/SectionMain";
import Title from "../../../components/Title";
import useGoToPage from "../../../hooks/useGoToPage";
import logo from "../../../assets/images/medita_logo_horizontal.png";

const useStyles = makeStyles({
  root: {
    padding: (theme) =>
      theme.noPadding ? 0 : theme.miniPadding ? "10px 0" : "15px 0",
    justifyContent: (theme) => theme.position,
  },
  container: {
    padding: "20px 4vw",
    justifyContent: "center",

    width: "80%",
    alignSelf: "center",
  },
  banner: {
    background: "#f8f9fa",
    height: 400,
    justifyContent: "space-between",
    padding: "10px 6vw",
  },
  leftSection: { padding: "25px 0", maxHeight: 400 },
  rightSection: { justifyContent: "center" },
  image: { width: "45vw", maxWidth: 400 },
  title: {
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 0 30px",
    fontSize: 25,
  },
  cursive: {
    color: "#008393",
    letterSpacing: 2,
    fontFamily: "adobe-caslon-pro, serif",
    fontStyle: "italic",
    fontSize: 38,
  },
  text: {
    flexDirection: "column",
    alignItems: "center",
    fontSize: 20,
    textAlign: "justify",
  },
  subText: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: 195,
    padding: "20px 4vw",
  },
  divImage: {
    background: "#EFEFEF",
    width: "100%",
    height: 250,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    padding: "30px",
  },
  img: { cursor: "pointer" },
  footer: {
    background: "#EFEFEF",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    alignSelf: "center",
    padding: "30px",
  },
  contato: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  info: {
    width: "100%",
    display: "flex",
    justifyContent: "right",
    fontSize: 14,
  },
});

const PrivacyPageDesktop = () => {
  const classes = useStyles();
  const goToPage = useGoToPage();

  return (
    <SectionMain
      noPadding
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className={classes.divImage}>
        <img
          alt="MeditaBem"
          src={logo}
          height="auto"
          width="400px"
          className={classes.img}
          onClick={() => goToPage("/")}
        />
        <div>&nbsp;</div>
      </div>
      <SectionMain className={classes.container}>
        <SectionMain className={classes.title}>
          <Title
            fontWeight="800"
            size="big"
            align="center"
            capitalize="uppercase"
          >
            {"POLÍTICA DE PRIVACIDADE"}
          </Title>
        </SectionMain>
        <SectionMain className={classes.text}>
          <Title fontWeight="800" size="large" align="left">
            Seja bem-vindo à política de privacidade do MeditaBem
          </Title>
          <br />
          <Title size="large" align="justify">
            O MeditaBem preocupa-se com sua privacidade e acreditamos que você
            deve estar no controle sobre seus dados pessoais.
            <br />
            Por isso, preparamos essa Política de Privacidade para ajudar você a
            entender melhor como usamos seus dados pessoais em nossas páginas,
            na utilização de nossos serviços, sites, aplicativos, softwares,
            formulários físicos e demais plataformas que possam conter seus
            dados pessoais.
            <br />
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            1. Para que serve esse documento
          </Title>
          <br />
          <Title size="large" align="justify">
            Criamos essa Política de Privacidade para ajudar você a entender
            quais são as práticas adotadas pelo MeditaBem ao realizar o
            tratamento de seus dados pessoais e o motivo pelo qual seus dados
            são solicitados ou coletados, demonstrando o nosso compromisso com a
            boa-fé, segurança, privacidade e transparência. <br />
            <br /> Esta política é aplicável à todas as pessoas que acessarem o
            Aplicativo MeditaBem e poderá ser aplicável a outras formas de
            coleta de dados pelo MeditaBem, que permitam qualquer prestação de
            serviços ou fornecimento de produtos ou conteúdo. <br />
            <br /> Ao utilizar as Plataformas Digitais nós disponibilizamos o
            acesso a esta política onde solicitamos a informação você que leu,
            compreendeu e aceita este documento, além de concordar em ficar
            vinculado por ele. Caso tenha dúvidas sobre o entendimento a
            respeito de qualquer disposição desta Política de Privacidade, entre
            em contato com o DPO (encarregado dos dados) por meio do canal
            oficial disponível somente para atender os titulares dos dados
            pessoais, aqui mencionado no final desta Política. Se você não
            concordar (ou não puder cumprir) com este documento, você não poderá
            usar o Aplicativo MeditaBem ou demais serviços que façam uso de seus
            dados pessoais conforme as diretrizes desta política.
            <br />
            <br /> Alguns serviços oferecidos pelo MeditaBem podem estar
            sujeitos a termos e condições adicionais especificados pelo Medita
            Bem ou terceiros periodicamente, os quais serão incorporados nesta
            desta Política de Privacidade para os quais você também poderá ter
            de dar seu consentimento.
            <br />
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            2. Sobre seus dados pessoais
          </Title>
          <Title size="large" align="justify">
            <br />
            Neste item vamos falar um pouco sobre seus dados pessoais no Medita
            Bem, considerando todo o ciclo de tratamento desde a coleta até a
            eliminação dos dados.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            2.1. Como coletamos seus dados pessoais
          </Title>
          <Title size="large" align="justify">
            <br /> Os seus dados pessoais poderão ser coletados ao realizar o
            seu cadastro no nosso Aplicativo mobile e nosso site, acessa
            quaisquer de nossos produtos ou serviços ou participa de eventos,
            campanhas e promoções conforme abaixo: Durante a navegação de nossos
            sites através de Cookies:
            <br />
            <ul>
              <li>Durante a navegação de nossos sites através de Cookies;</li>
              <li>
                Compartilhados através de terceiros, quando você autoriza em
                plataformas de terceiros o compartilhamento de seus dados
                pessoais;
              </li>
              <li>
                Ao efetuar a instalação de algum software ou aplicação
                desenvolvida pelo próprio MeditaBem ou de empresas parceiras,
                neste momento você será consultado sobre a permissão de acesso
                conforme o dispositivo utilizado;
              </li>
              <li>Eventos organizados ou com a participação do MeditaBem;</li>
              <li>
                Com a aquisição de algum produto ou serviço oferecido pelo
                MeditaBem ou empresas do Grupo;
              </li>
            </ul>
            <br />
            <b>Importante:</b> No momento da coleta o MeditaBem disponibilizará
            o acesso a esta política para leitura e respectivo aceite (exceto
            quando a coleta ocorrer em plataforma de terceiros).
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            2.2. Quais dados pessoais coletamos de você
          </Title>
          <Title size="large" align="justify">
            <br />
            Dados pessoais são todos aqueles que possam identificá-lo, com isso
            podem ser dados cadastrais (como nome, e-mail, endereço), assim como
            dados de registro de navegação (como IP de dispositivos, histórico
            de navegação e cookies), o mínimo necessário para oferecer a melhor
            experiência.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            2.3. Vamos detalhar um pouco os tipos dos dados?
          </Title>
          <br />
          <Title size="large" align="justify">
            <b>Dados cadastrais.</b> Poderão ser utilizados pelo MeditaBem
            diversos dados pessoais, os quais poderão incluir, mas não se
            limitam a:
            <ul>
              <li>
                Dados cadastrais, tais como: nome, documentos de identificação,
                nacionalidade, endereço, data de nascimento, filiação, gênero,
                entre outros.
              </li>
              <li> Dados de contato tais como: telefone, e-mail e endereço.</li>
              <li>Dados sobre sua atividade, trabalho, profissão.</li>
              <li>
                Dados coletados de terceiros, incluindo os já acima mencionados,
                suas fotografias e demais documentos de identificação.
              </li>
            </ul>
            <br />
            <b>Dados de navegação. </b> Poderão ser utilizados pelo MeditaBem os
            seguintes dados de navegação, os quais poderão incluir, mas não se
            limitam a:
            <ul>
              <li> Endereço de IP do dispositivo utilizado por você.</li>
              <li>Interações realizadas e uso de perfil.</li>
              <li>
                Dados técnicos, como informações de URL, conexão de rede, do
                provedor, do dispositivo.
              </li>
              <li>Histórico de navegação e interesses.</li>
              <li>
                Atributos de dispositivo, como ID, sistema operacional,
                navegador e modelo.
              </li>
              <li>
                Acesso a eventuais listas de contatos caso exista a ativação
                desta funcionalidade.
              </li>
              <li>
                Dados como cookies, pixels tags, beacons e local shared objects.
              </li>
              <li>
                Dados sobre as características dos dispositivos de acesso.
              </li>
              <li>
                Dados do navegador, incluindo IP (com data e hora) e origem do
                IP.
              </li>
              <li>
                Dados e informações sobre cliques, páginas acessadas, páginas
                seguintes acessadas após a saída das páginas anteriores, termos
                de buscas digitados em sites e nas Plataformas Digitais.
              </li>
            </ul>
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            2.3. Vamos detalhar um pouco os tipos dos dados?
          </Title>
          <Title size="large" align="justify">
            <br />
            Usamos seus dados pessoais para fornecer os nossos serviços, dentro
            e fora das Plataformas Digitais, da melhor forma possível,
            respeitando sempre suas preferências de privacidade e a coleta
            mínima necessária. Podemos fazer uso para fins publicitários, de
            segurança e de personalização dos serviços.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            2.4.1. Finalidades que estão relacionadas diretamente ao seu
            interesse Fins de nossos serviços.
          </Title>
          <Title size="large" align="justify">
            <br />O MeditaBem utiliza os seus dados para o estabelecimento de
            vínculo contratual ou ainda para gestão, administração, prestação,
            ampliação e melhoramento dos produtos, serviços, bem como do nosso
            Aplicativo adequando-as às suas preferências.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Fins publicitários do MeditaBem.
          </Title>
          <Title size="large" align="justify">
            <br />
            Os dados pessoais e demais informações coletadas também poderão ser
            utilizadas para fins publicitários do MeditaBem, como envio de
            informações de marcas, produtos, promoções e descontos, para a
            divulgação de eventos ou para a realização de pesquisas, sempre
            respeitando a transparência com o titular dos dados.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Fins de segurança.
          </Title>
          <Title size="large" align="justify">
            <br />
            Para operar nossos serviços, usamos alguns de seus dados, em
            especial dados de registro e de navegação, para assegurar seu
            desempenho seguro, confiável e robusto, bem como para garantir a
            segurança das contas e a devida autenticação.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            2.4.2. Finalidades que podemos solicitar o consentimento mesmo que
            atendam o seu interesse
          </Title>
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Fins publicitários de terceiros.
          </Title>
          <Title size="large" align="justify">
            <br />
            Os dados pessoais e demais informações coletadas também poderão ser
            utilizadas para fins publicitários de terceiros ou empresas
            parceiras, como envio de informações de marcas, produtos, promoções
            e descontos, para a divulgação de eventos ou para a realização de
            pesquisas, sempre respeitando a transparência com o titular dos
            dados.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Fins de personalização.
          </Title>
          <Title size="large" align="justify">
            <br />
            Para personalizar nossos serviços, poderemos associar você a
            informações fornecidas por você ou por terceiros, por meio de
            inferências sobre temas sobre os quais você pode estar interessado e
            da customização do conteúdo que mostramos em nosso Aplicativo,
            inclusive em anúncios.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Demais finalidades.
          </Title>
          <Title size="large" align="justify">
            <br />
            Os dados pessoais obtidos pelo MeditaBem poderão também incluir as
            seguintes finalidades:
            <ul>
              <li>
                Atendimento de suas solicitações e/ou esclarecimento de dúvidas.
              </li>
              <li>
                Contato por telefone, e-mail, SMS, aplicativo de mensagens,
                inclusive por meio de notificações ou push.
              </li>
              <li>
                Envio de comunicações em geral, incluindo atualizações desta
                Política de Privacidade bem como de demais termos aplicáveis.
              </li>
              <li>
                Exibição de publicidade no Aplicativo, redes sociais, inclusive
                em sites e plataformas de parceiros, assim como envio de
                ofertas, informações, novidades, atualizações de produtos,
                serviços, conteúdos, funcionalidades, notícias, eventos
                relevantes que possam ser de seu interesse e para manutenção de
                relacionamento com você.
              </li>
              <li>
                Prevenção e resolução de problemas técnicos e de segurança.
              </li>
              <li>Prevenção e combate a fraudes e demais atos ilícitos.</li>
              <li>
                Colaboração ou cumprimento de ordem judicial de autoridade
                competente ou órgão fiscalizador, assim como cumprimento de
                obrigação legal ou regulatória.
              </li>
              <li>Melhoria dos sistemas, produtos e serviços.</li>
              <li>
                Recomendações e operacionalização de novos serviços, produtos ou
                funcionalidades de aplicativos, softwares, plataformas, sites,
                inclusive de serviços oferecidos por parceiros.
              </li>
              <li>
                Geração de estatísticas, estudos, pesquisas e levantamentos
                pertinentes às atividades e comportamento no uso das Plataformas
                Digitais, produtos ou serviços.
              </li>
              <li>Dados de geolocalização, quando possível.</li>
            </ul>
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            2.5. Compartilhamento dos seus dados pessoais
          </Title>
          <Title size="large" align="justify">
            <br />
            Seus dados pessoais e informações também poderão ser compartilhados
            com prestadores de serviços, sempre sujeito às obrigações
            consistentes com esta Política de Privacidade, tais como empresas
            provedoras de infraestrutura tecnológica e operacional, empresas de
            atendimento ao consumidor, empresas de comunicação de serviços,
            empresas intermediadoras de pagamento e empresas e provedores de
            serviço de armazenamento de informações e demais parceiros
            necessários para a completa atividade do MeditaBem. Esse
            compartilhamento é essencial para que você possa melhor utilizar
            nossos serviços.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Anúncios
          </Title>
          <Title size="large" align="justify">
            <br />
            Ao aceitar os termos disponibilizados em nosso Aplicativo, você
            concorda que o MeditaBem e seus parceiros podem utilizar parte de
            seus dados pessoais para disponibilizar anúncios que consideramos
            relevantes para você e seus interesses nas Plataformas Digitas ou em
            conexão com a exibição de conteúdo. Para o fornecimento de anúncios
            mais customizados e eficazes, nossos parceiros podem compartilhar
            conosco algumas de suas informações pessoais, em especial dados de
            navegação, localização e interesse, permitindo que os combinemos com
            dados pessoais já coletados. A receita oriunda de publicidade
            permite ao MeditaBem continuadamente melhore seus serviços.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Outros compartilhamentos
          </Title>
          <Title size="large" align="justify">
            <br />
            Poderemos usar e compartilhar dados pessoais ou outros dados de
            segurança se acreditarmos que são razoavelmente necessários para:
            <ul>
              <li>
                Cumprimento de obrigação legal ou regulatória, incluindo ordem
                judicial ou requerimento de autoridades administrativas que
                detenham competência legal para tal requisição.
              </li>
              <li>
                Exercício regular do direito do MeditaBem, incluindo proteção
                dos interesses do MeditaBem em qualquer tipo de conflito,
                incluindo ações judiciais.
              </li>
              <li>Proteger a segurança de qualquer pessoa.</li>
              <li>Proteger a segurança ou integridade de nossa plataforma.</li>
              <li>Proteger você ou terceiros de fraude.</li>
              <li>
                Proteger direitos ou nossa propriedade ou de terceiros que usam
                nossos serviços.
              </li>
              <li>
                Casos de transações e alterações societárias envolvendo o Medita
                Bem, hipótese em que a transferência das informações será
                necessária para a continuidade dos serviços e utilização das
                Plataformas Digitais.
              </li>
              <li>
                Envio de notificações não obrigatórias por e-mail, aplicativos
                de mensagens, SMS e notificação push.
              </li>
              <li>
                Cumprir com o legítimo interesse do MeditaBem e das empresas que
                integrem o grupo empresarial do MeditaBem para os mesmos fins
                indicados na presente Política de Privacidade.
              </li>
              <li>
                Em caso de exceções não previstas nos itens acima, o MeditaBem
                aplicará o termo de consentimento.
              </li>
              <li>
                Consultar a veracidade dos dados através de um serviço de birô.
              </li>
            </ul>
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            2.6. Coleta e uso de dados de menores de idade
          </Title>
          <Title size="large" align="justify">
            <br />
            Para realizar o cadastro em nossas Plataformas Digitais, o titular
            do dado precisa ter no mínimo 18 (dezoito) anos de idade. Caso o
            MeditaBem oferecer algum produto ou serviço destinado aos menores de
            idade, será obrigatório o consentimento formal de um dos pais ou
            responsável legal.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Idades mínimas
          </Title>
          <Title size="large" align="justify">
            <br />
            Para utilizar nossos serviços, você deve ter, no mínimo, 18
            (dezoito) anos de idade, assim como, para se cadastrar no
            aplicativo, você precisa ter, no mínimo, 18 (dezoito) anos de idade.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Dados coletados
          </Title>
          <Title size="large" align="justify">
            <br />
            Para garantir a máxima proteção à criança ou ao adolescente, somente
            serão solicitados os dados indispensáveis para a realização da
            inscrição específica, tais como nome do menor, idade, foto, entre
            outros e consentimento formal de um dos pais ou responsável legal.
            Ao realizar o cadastro de criança ou adolescente, você assume a
            veracidade dos dados fornecidos, bem como a condição de responsável
            legal do menor.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            2.7. Retenção e exclusão de seus dados pessoais
          </Title>
          <Title size="large" align="justify">
            <br />
            Excluiremos seus dados pessoais quando não mais forem necessários
            para a finalidade a eles estipuladas, quando a política de retenção
            assim o determinar ou quando você ou uma autoridade requerer.
            Lembre-se que, ainda assim, poderemos manter parte de seus dados,
            caso seja legalmente requerido ou permitido.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Exclusão dos dados.
          </Title>
          <Title size="large" align="justify">
            <br />
            Enquanto você mantiver relacionamento com o MeditaBem, bem como
            durante o uso do Aplicativo e de nossos de serviços, o MeditaBem
            armazenará seus dados em ambientes seguros e controlados, mas
            poderão ser excluídos:
            <ul>
              <li>
                Quando os dados deixarem de ser necessários ou pertinentes à
                finalidade específica pela qual foram disponibilizados.
              </li>
              <li>Quando terminar o período do tratamento.</li>
              <li>
                Quando você solicitar que o MeditaBem bloqueie ou elimine esses
                dados.
              </li>
            </ul>
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Retenção dos dados.
          </Title>
          <Title size="large" align="justify">
            <br />
            Mesmo em caso de requisição de exclusão de dados ou após o término
            do relacionamento com você, será respeitado o prazo de armazenamento
            mínimo de informações de usuários de aplicações de Internet,
            determinado pela legislação brasileira, resguardados os requisitos
            de tratamento previstos quando anonimizados os dados. O MeditaBem
            poderá armazenar seus dados por um período adicional de tempo para
            fins de auditoria, para cumprimento de obrigações legais ou
            regulatórias, para preservar os direitos do MeditaBem em eventuais
            discussões judiciais e para observar as bases legais que justifique
            a retenção dos dados, como, por exemplo, para cumprir eventual
            solicitação da Autoridade Nacional de Proteção de Dados (ANPD).
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Exclusão definitiva de conta.
          </Title>
          <Title size="large" align="justify">
            <br />É necessário que você esteja ciente de que a exclusão das
            informações essenciais para gestão de sua conta de acesso ao
            Aplicativo implicará o término de seu cadastro, com consequente
            cancelamento de seu acesso e potencial perda permanente dos dados
            até então coletados.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            3. Vamos falar um pouco sobre alguns produtos e serviços
          </Title>
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            3.1. Site do MeditaBem
          </Title>
          <Title size="large" align="justify">
            <br />
            Durante a utilização de site MeditaBem o Usuário estará sujeito a
            fornecer dados pessoais para customização de conteúdo, publicidade
            personalizada, cadastro para ficar por dentro de campanhas e
            novidades envolvendo o aplicativo e campanhas envolvendo empresas
            parceiras.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            3.2. Tratamento de dados no MeditaBem
          </Title>
          <Title size="large" align="justify">
            <br />
            O Usuário poderá criar diversos perfis e no momento de criação, será
            solicitado nome e data de nascimento.
            <br />
            Por meio do aceite desta Política o Usuário confirma e autoriza o
            envio de notificações automáticas sobre conteúdos considerados de
            interesse pelo MeditaBem (“push notification”) bem como de eventos a
            serem realizados. O aceite poderá ser solicitado novamente durante o
            uso do MeditaBem no dispositivo móvel, conforme regem as políticas
            de privacidade do ANDROID e IOS.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            4. Como mantemos as suas informações e seus dados pessoais seguros
          </Title>
          <Title size="large" align="justify">
            <br />
            Estamos comprometidos com a adoção de políticas e normas de boas
            práticas relativas à proteção dos dados pessoais. Implementamos as
            medidas técnicas e organizacionais pertinentes, considerando a
            tecnologia existente, para a segurança de dados, ainda que não
            possamos garantir que estejamos imunes a atos ilícitos de terceiros.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Segurança de sua informação.
          </Title>
          <Title size="large" align="justify">
            <br />
            Os sistemas e procedimentos do MeditaBem são estruturados de forma a
            atender aos requisitos de segurança, aos padrões de boas práticas e
            aos princípios gerais previstos na legislação aplicável e demais
            normas regulamentares. O MeditaBem armazenará suas informações e
            dados pessoais coletados no aplicativo em servidores próprios ou por
            nós contratados, utilizando os meios razoáveis de mercado e
            legalmente requeridos para preservar a privacidade sua privacidade,
            tais como:
            <br />
            <br />
            <ul>
              <li>
                Métodos padrões e de mercado para criptografar e anonimizar os
                dados.
              </li>
              <li>Proteção contra acesso não autorizado a seus sistemas.</li>
              <li>
                Acesso restrito às pessoas previamente autorizadas ao local onde
                são armazenados os dados e as informações coletadas.
              </li>
              <li>
                Comprometimento e sigilo absoluto pelas pessoas que tiverem
                acesso aos dados e informações.
              </li>
              <li>
                Manutenção de inventário indicando momento, duração, identidade
                do funcionário, ou do responsável pelo acesso com base nos
                registros de conexão e de acesso a aplicações.
              </li>
            </ul>
            <b>Importante.</b> É importante que você saiba que nenhum site é
            totalmente seguro. O MeditaBem adota as melhores práticas de
            segurança da informação, porém incentivamos você a tomar as medidas
            apropriadas para reforçar esta proteção, como, por exemplo, mantendo
            confidenciais todos os nomes de usuário e senhas bem como não
            transferindo os seus dados de acesso a quaisquer terceiros.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            5. Seus direitos como titular dos dados pessoais
          </Title>
          <Title size="large" align="justify">
            <br />
            Você poderá exercer seus direitos de titular de dado pessoal, tais
            como requerer acesso aos dados pessoais que coletamos, assim como
            requerendo retificação, atualização, portabilidade, revogação de
            consentimento e eliminação de seus dados pessoais.
            <br />
            <br />
            <Title size="large" align="justify">
              <b>Direitos de titular. </b> Enquanto titular de dados pessoais,
              você poderá exercer uma série de direitos. Disponibilizaremos
              mecanismos para que você possa entender de forma clara e
              transparente como poderá exercer seus direitos. O MeditaBem
              empreenderá todos os esforços para atender tais pedidos no menor
              espaço de tempo possível.
            </Title>
            <br />
            <Title size="large" align="justify">
              <b>Acesso a dados.</b>
              Você poderá solicitar através do canal exclusivo para esta
              finalidade, a qualquer momento, que o MeditaBem lhe informe e
              confirme a existência de tratamento de dados em nossas Plataformas
              Digitais ou demais bases sob a responsabilidade da emissora, bem
              como forneça os dados que possuímos de você.
            </Title>
            <br />
            <Title size="large" align="justify">
              <b>Retificação e atualização de dados.</b> Se você identificar que
              os seus dados pessoais necessitam de correções ou se encontrarem
              incompletos, inexatos ou desatualizados, você poderá, caso não
              haja possibilidade de fazê-lo diretamente em nosso aplicativo,
              encaminhar solicitação para o canal dedicado ao titular dos dados.
              Lembre-se que você pode ter de comprovar, inclusive por meio de
              documento, a veracidade desses dados.
            </Title>
            <br />
            <Title size="large" align="justify">
              <b>Demais direitos.</b> O MeditaBem respeita e garante a você a
              possibilidade de apresentação de solicitações baseadas nos
              seguintes direitos:
            </Title>
            <ul>
              <li>
                Anonimização, bloqueio ou eliminação de dados desnecessários,
                excessivos ou tratados em desconformidade.
              </li>
              <li>
                Portabilidade de seus dados a outro fornecedor de serviço ou
                produto.
              </li>
              <li>Eliminação dos dados tratados com o seu consentimento.</li>
              <li>
                Informação sobre a possibilidade de não fornecer o seu
                consentimento, bem como de ser informado sobre as consequências
                em caso de negativa.
              </li>
            </ul>
          </Title>
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            6. Coleta e revogação do consentimento
          </Title>
          <Title size="large" align="justify">
            <br />
            Poderemos pedir seu consentimento para alguns dos usos desses dados
            pessoais. Você tem o direito de não dar seu consentimento, assim
            como de revogá-lo, mas o MeditaBem poderá ainda fazer uso de parte
            desses dados pessoais, nas hipóteses previstas em lei.
            <br /> <br />
            <b>Obtenção de consentimento. </b> Caso seu consentimento seja
            necessário para uso dos seus dados pessoais, de acordo com o
            disposto na lei aplicável, ele será coletado eletronicamente,
            momento em que serão informados os procedimentos e finalidades. Você
            possui o direito de não oferecer o seu consentimento ou de solicitar
            maiores esclarecimentos acerca desse consentimento.
            <br /> <br />
            <b>Revogação do consentimento. </b> Você poderá solicitar a
            revogação do consentimento que concedeu. Esta revogação poderá
            ocasionar a impossibilidade de uso ou acesso de funcionalidades dos
            sistemas e serviços do MeditaBem. É importante que você esteja
            ciente de que a revogação do seu consentimento não significa que
            seus dados pessoais serão eliminados, tendo em vista a possiblidade
            de conservação autorizada dos dados para as finalidades dispostas na
            lei aplicável.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            7. Informações sobre legislação.
          </Title>
          <Title size="large" align="justify">
            <br />
            A legislação brasileira é aplicável a esta Política de Privacidade,
            em especial a LGPD, e o foro é o da comarca da cidade de São
            Paulo/SP.
            <br />
            <br />
            Nulidade ou incompletude de cláusulas. Qualquer cláusula ou condição
            destes Termos que venha a ser reputada nula ou ineficaz por qualquer
            juízo ou tribunal, não afetará a validade das demais disposições, as
            quais permanecerão plenamente válidas e vinculantes. Nossa falha em
            exigir quaisquer direitos ou disposições dos presentes Termos não
            constituirá renúncia, podendo exercer regularmente o seu direito,
            dentro dos prazos legais.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Legislação aplicável e foro.
          </Title>
          <Title size="large" align="justify">
            <br />
            Esta Política de Privacidade é regida, interpretada e executada de
            acordo com as Leis da República Federativa do Brasil, especialmente
            a Lei nº 13.709/2018, sendo competente o foro de Osasco para dirimir
            qualquer controvérsia decorrente desta Política de Privacidade.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            7.1. Pelo que o MeditaBem não se responsabiliza.
          </Title>
          <Title size="large" align="justify">
            <br />O MeditaBem será responsável apenas pelos danos diretos
            oriundos de nossas obrigações. Você continua responsável pelos dados
            que forneceu ao MeditaBem.
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            Responsabilidade do MeditaBem.
          </Title>
          <Title size="large" align="justify">
            <br />
            O MeditaBem será responsável apenas pelos danos diretos que
            comprovadamente vier a ocasionar, não assumindo responsabilidade:
            <br />
            <ul>
              <li>
                Por erros ou inconsistências na transmissão de dados que você
                apresentou ou que automaticamente forem coletados, incluindo
                dados imprecisos, inverídicos, incompletos.
              </li>
              <li>
                Por utilização do Aplicativo em desacordo com o disposto nesta
                Política de Privacidade.
              </li>
              <li>
                Por qualquer dano, ou prejuízo decorrente de utilização não
                autorizada ou indevida de seus dados.
              </li>
              <li>
                Pela violação de direitos de terceiros ou ainda pela utilização
                não autorizada de dados de terceiros.
              </li>
            </ul>
          </Title>
          <br />
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            8. Entre em contato conosco
          </Title>{" "}
          <br />
          <Title
            fontWeight="800"
            size="large"
            align="left"
            capitalize="uppercase"
          >
            8.1. Canal de Privacidade
          </Title>
          <Title size="large" align="justify">
            <br />
            Se você precisar sanar dúvidas sobre como seus dados pessoais estão
            sendo tratados no Aplicativo MeditaBem ou queira formalizar alguma
            solicitação prevista pela Lei Geral de Proteção de Dados, você
            deverá utilizar o nosso Canal de Privacidade.
            <br />O encarregado pelo tratamento de dados pessoais no MeditaBem
            acompanhará todas as solicitações direcionadas ao canal de
            privacidade, assim mantendo a interação entre o titular dos dados
            pessoais e a emissora.
            <br />
            <b>Encarregado (DPO):</b> Isabelle Gretillat <br />
            <b>E-mail:</b>
            <a href="mailto:belle@medita.com.br">belle@medita.com.br</a>
            <br /> <br />
            <Title
              fontWeight="800"
              size="large"
              align="left"
              capitalize="uppercase"
            >
              8.2 SAC
            </Title>
            Para tirar demais dúvidas sobre produtos ou serviços do MeditaBem,
            você deverá entrar em contato conosco por meio do e-mail:
            <a href="mailto:belle@medita.com.br">{"  "} belle@medita.com.br</a>
            <br />
            <br /> <br />
          </Title>
          <div className={classes.info}>
            <em>Última atualização em 01 de novembro de 2023</em>
          </div>
        </SectionMain>
      </SectionMain>

      <br />
      <br />
      <div className={classes.footer}>
        <div className={classes.contato}>
          <p>
            <b>Encarregado (DPO):</b> Isabelle Gretillat
          </p>
          <p>
            <b>E-mail:</b>{" "}
            <a href="mailto:belle@medita.com.br">belle@medita.com.br</a>
          </p>
        </div>
        <img
          alt="MeditaBem"
          src={logo}
          height="auto"
          width="200px"
          className={classes.img}
          onClick={() => goToPage("/")}
        />
        <div>&nbsp;</div>
      </div>
    </SectionMain>
  );
};

export default PrivacyPageDesktop;
