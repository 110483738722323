import { makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../../store/AppContext";
import { getSizeFont } from "../../../../utils/getSizeFont";
import SectionMain from "../../../SectionMain";

const useStyles = makeStyles({
  link: {
    cursor: "pointer",
    color: "#282828",
    fontSize: getSizeFont("xsmall"),
    textTransform: "capitalize",
    textDecorationLine: "none",
    padding: "10px",
    fontSize: "1.1em",
    display: "inline",
    fontWeight: 600,
  },
  subLink: {
    cursor: "pointer",
    color: "#a8a7a5",
    fontSize: "1.1em",
    textTransform: "capitalize",
    textDecorationLine: "none",
    padding: "10px 10px 10px 30px",
  },
});

const DrawerMenuUI = ({ link, title, categories = [], goToPage }) => {
  const classes = useStyles();
  const { setAppState } = useAppContext();
  const setRoute = (link) => {
    setTimeout(() => {
      setAppState({ route: link, drawerMenu: false });
    });
  };

  switch (title) {
    case "categorias":
      return (
        <SectionMain noPadding>
          <Link
            to={link}
            className={classes.link}
            onClick={() => {
              setRoute(link);
              goToPage(link);
            }}
          >
            {title}
          </Link>
          {categories.map(({ link, title }) => (
            <SectionMain noPadding key={`#-title-${title}`}>
              <Link
                to={link}
                className={classes.subLink}
                onClick={() => {
                  setRoute(link);
                  goToPage(link);
                }}
              >
                {title}
              </Link>
            </SectionMain>
          ))}
        </SectionMain>
      );

    default:
      return (
        <SectionMain noPadding>
          <Link
            to={link}
            className={classes.link}
            onClick={() => {
              setRoute(link);
              goToPage(link);
            }}
          >
            {title}
          </Link>
        </SectionMain>
      );
  }
};

export default DrawerMenuUI;
