import { Avatar, makeStyles } from "@material-ui/core";
import React from "react";
import meditation from "../../../../assets/images/home/meditation.png";
import fone from "../../../../assets/images/home/fone.png";
import flat from "../../../../assets/images/home/flat.png";
import { STYLES } from "./styles";
import SectionMain from "../../../../components/SectionMain";
import Title from "../../../../components/Title";

const useStyles = makeStyles({
  container: {
    paddingBottom: "10%",
  },
  leftSectionTwo: {
    height: "100%",
    maxWidth: "80%",
    margin: "auto",
    marginTop: 100,
  },
  titleSectionTwo: {
    fontFamily: "Century Gothic",
    fontSize: "30px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#3bad76",
  },
  descriptionTwo: {
    fontFamily: "Century Gothic",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5A7184",
  },
  viewItems: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,
  },
  root: {
    width: "31%",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
  },
  avatar: {
    width: "60px",
    height: "60px",
    borderRadius: "16px",
    background: "#6eb64b",
    background: "linear-gradient(180deg,  #6eb64b 0.00%, #009daa 100.00%)",
  },
  titleItems: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#3bad76",
    marginTop: "5px",
    marginBottom: "5px",
  },
  descItems: {
    width: "69%",
    margin: "auto",
    fontFamily: "Century Gothic",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#5a7184",
  },
  viewCenter: { width: "88%", margin: "auto" },
});

const SectionTwo = () => {
  const classes = useStyles();

  return (
    <SectionMain noPadding className={classes.container}>
      <SectionMain noPadding item className={classes.leftSectionTwo}>
        <div className={classes.viewCenter}>
          <div className={classes.titleSectionTwo}>
            Desperte sua melhor versão
            <br />
            <div style={{ width: "78%", margin: "auto" }}>
              <span className={classes.descriptionTwo}>
                Com o MeditaBem, o bem-estar físico, mental e espiritual está ao
                seu alcance todos os dias. Experimente uma transformação
                completa em sua vida com conteúdos exclusivos que equilibram sua
                mente e corpo de maneira integrada e profunda.
              </span>
            </div>
          </div>
        </div>
        <div className={classes.viewItems}>
          <SectionMain className={classes.root} item>
            <Avatar
              className={classes.avatar}
              alt={`icon-meditation`}
              src={meditation}
            />
            <SectionMain
              position="space-between"
              direction="column"
              width="50%"
            >
              <Title className={classes.titleItems}>Bem-estar integral</Title>
              <p className={classes.descItems}>
                Conteúdos desenvolvidos por especialistas em meditação, yoga,
                respiração e terapias holísticas para promover o equilíbrio e a
                transformação na sua jornada de autocuidado.
              </p>
            </SectionMain>
          </SectionMain>
          <SectionMain className={classes.root} item>
            <Avatar className={classes.avatar} alt={`icon-flat`} src={flat} />

            <SectionMain
              position="space-between"
              direction="column"
              width="50%"
            >
              <Title className={classes.titleItems}>Sem interrupções</Title>
              <span className={classes.descItems}>
                Desfrute de uma experiência fluida e focada, sem anúncios ou
                qualquer interrupção que interfira no seus momentos de prática e
                relaxamento.
              </span>
            </SectionMain>
          </SectionMain>
          <SectionMain className={classes.root} item>
            <Avatar className={classes.avatar} alt={`icon-fone`} src={fone} />
            <SectionMain
              position="space-between"
              direction="column"
              width="50%"
            >
              <Title className={classes.titleItems}>
                Playlist do seu jeito
              </Title>
              <span className={classes.descItems}>
                Crie playlists personalizadas com seus conteúdos favoritos para
                meditar, relaxar, praticar yoga ou dormir melhor, sempre de
                acordo com suas necessidades.
              </span>
            </SectionMain>
          </SectionMain>
        </div>
      </SectionMain>
    </SectionMain>
  );
};

export default SectionTwo;
