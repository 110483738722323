import { makeStyles } from "@material-ui/core";
import React from "react";
import ButtonMedita from "../ButtonMedita";
import SectionMain from "../SectionMain";
import Title from "../Title";
import { STYLE } from "./style";

const useStyles = makeStyles(STYLE);

const BePartner = ({ navigate }) => {
  const classes = useStyles();

  return (
    <SectionMain className={classes.banner}>
      <SectionMain className={classes.bannerContent}>
        <SectionMain direction="column" className={classes.title}>
          <Title size="xbig" fontWeight="700" align="center" letterSpacing={3}>
            Conectando a comunidade
          </Title>
          <Title align="center" id="cursive" fontWeight="500">
            <span style={{ fontSize: 34 }}>
              MeditaBem a <span className={classes.cursive}>você</span>!
            </span>
          </Title>
        </SectionMain>
        <SectionMain className={classes.content}>
          <Title align="center">
            Conteúdos em áudio e vídeo, um novo olhar.
            <br />
            Experiências transformando hábitos e vidas.
            <br />
            Professores e autores de diversas especialidades. Mantenha seu
            estilo.
            <br />
            Junte-se ao time. Conheça o MeditaBem.
          </Title>
          <br />

          {navigate ? (
            <div>
              {/* <ButtonMedita
                backgroundColor="#316570"
                type="submit"
                className={classes.bePartnerBtn}
                onClick={() => navigate()}
              >
                {"Fazer login"}
              </ButtonMedita> */}
              <ButtonMedita
                backgroundColor="#316570"
                type="submit"
                className={classes.bePartnerBtn}
                onClick={() => navigate()}
              >
                {"Quero saber mais"}
              </ButtonMedita>
            </div>
          ) : (
            ""
          )}
        </SectionMain>
      </SectionMain>
    </SectionMain>
  );
};

export default BePartner;
