import { IconButton, OutlinedInput, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import ButtonMedita from "../../../components/ButtonMedita";
import SectionMain from "../../../components/SectionMain";
import api from "../../../core/api";
import {
  getToday,
  lastSevenDays,
  lastThirtyDays,
  lastNinetyDays,
  lastYearDays,
} from "../../../utils/dates";
import "./style.css";

const PartnersPanelDataDesktop = () => {
  const [page, setPage] = useState(0);
  const [rank, setRank] = useState(0);
  const [videos, setVideos] = useState([]);
  const [period, setPeriod] = useState("hoje");
  const [data, setData] = useState(undefined);

  const getData = async () => {
    const { data } = await api.get(`/users/${localStorage.getItem("id")}`);

    setData(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const fetchContentMetrics = async () => {
    let data;
    if (data === undefined) {
      const response = await api.get(`/users/${localStorage.getItem("id")}`);

      data = response.data;
    }

    if (period === "hoje") {
      const startDate = getToday();
      const endDate = getToday();
      const response = await api.get(
        `/partners/content-metrics/${data.partner_id}?startDate=${startDate}&endDate=${endDate}`
      );

      orderRanking(response.data.videos);
    } else if (period === "sete_dias") {
      const startDate = getToday();
      const endDate = lastSevenDays();
      const response = await api.get(
        `/partners/content-metrics/${data.partner_id}?startDate=${startDate}&endDate=${endDate}`
      );

      orderRanking(response.data.videos);
    } else if (period === "trinta_dias") {
      const startDate = getToday();
      const endDate = lastThirtyDays();
      const response = await api.get(
        `/partners/content-metrics/${data.partner_id}?startDate=${startDate}&endDate=${endDate}`
      );

      orderRanking(response.data.videos);
    } else if (period === "tres_meses") {
      const startDate = getToday();
      const endDate = lastNinetyDays();
      const response = await api.get(
        `/partners/content-metrics/${data.partner_id}?startDate=${startDate}&endDate=${endDate}`
      );

      orderRanking(response.data.videos);
    } else if (period === "ano") {
      const startDate = getToday();
      const endDate = lastYearDays();
      const response = await api.get(
        `/partners/content-metrics/${data.partner_id}?startDate=${startDate}&endDate=${endDate}`
      );
      orderRanking(response.data.videos);
    }
  };

  useEffect(() => {
    fetchContentMetrics();
  }, [period]);

  const rowsPerPage = 8;

  const BotoesPaginacao = () => {
    return (
      <div className="table_pagination">
        <ButtonMedita
          className="btn-pagination-next"
          variant="outlined"
          disabled={page * rowsPerPage + rowsPerPage >= videos.length}
          onClick={() => setPage(page + 1)}
        >
          Próximo
        </ButtonMedita>
        <div className="pagination_actions">
          <IconButton disabled={page === 0} onClick={() => setPage(page - 1)}>
            <ChevronLeft />
          </IconButton>
          <OutlinedInput
            readOnly
            value={page + 1}
            className="table-pagination-current"
          />
          <IconButton
            disabled={page * rowsPerPage + rowsPerPage >= videos.length}
            onClick={() => setPage(page + 1)}
          >
            <ChevronRight />
          </IconButton>
          <Typography component="span" className="table-pagination-page">
            de {Math.ceil(videos.length / rowsPerPage)}
          </Typography>
        </div>
      </div>
    );
  };

  const changePeriod = (periodo) => {
    // periodos = hoje, sete_dias, trinta_dias, tres_meses, ano

    setPeriod(periodo);
  };

  useEffect(() => {}, [period]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const compare = (a, b) => {
    if (a.totalViews > b.totalViews) return -1;
    if (a.totalViews < b.totalViews) return 1;
    return 0;
  };

  const orderRanking = (videos) => {
    let index = 0;
    let i;
    const rankVideos = videos
      .sort((a, b) => compare(a, b))
      .map((v, indice) => {
        if (indice === 0) {
          index++;
          return { ...v, rank: 1 };
        } else if (
          videos[indice - 1].percentViews.toFixed(2) ===
          videos[indice].percentViews.toFixed(2)
        ) {
          return { ...v, rank: index };
        }
        index++;
        return { ...v, rank: index };
      });
    setVideos(rankVideos);
  };

  return (
    <SectionMain noPadding>
      <div className="container-datas">
        <button
          className={[period === "hoje" ? "active" : "", "toggle-data"].join(
            " "
          )}
          onClick={() => changePeriod("hoje")}
        >
          Hoje
        </button>
        <button
          className={[
            period === "sete_dias" ? "active" : "",
            "toggle-data",
          ].join(" ")}
          onClick={() => changePeriod("sete_dias")}
        >
          Nos últimos 7 dias
        </button>
        <button
          className={[
            period === "trinta_dias" ? "active" : "",
            "toggle-data",
          ].join(" ")}
          onClick={() => changePeriod("trinta_dias")}
        >
          Nos últimos 30 dias
        </button>
        <button
          className={[
            period === "tres_meses" ? "active" : "",
            "toggle-data",
          ].join(" ")}
          onClick={() => changePeriod("tres_meses")}
        >
          Nos últimos 3 meses
        </button>
        <button
          className={[period === "ano" ? "active" : "", "toggle-data"].join(
            " "
          )}
          onClick={() => changePeriod("ano")}
        >
          Em um ano
        </button>
      </div>

      <TableContainer>
        <Table aria-label="custom pagination table" className="tabela">
          <TableHead>
            <TableRow>
              <TableCell align="left">Ranking</TableCell>
              <TableCell align="center">Meu Conteúdo</TableCell>
              <TableCell align="left">Categoria</TableCell>
              <TableCell align="right">% de views</TableCell>
              <TableCell align="right">Visualizações</TableCell>
              <TableCell align="right">% de salvos</TableCell>
              <TableCell align="right">Salvos</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {videos
              .sort((a, b) => compare(a, b))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell align="left" size="small">
                      {row.rank}
                    </TableCell>
                    <TableCell align="center" size="small">
                      {row.title}
                    </TableCell>
                    <TableCell align="left" size="small">
                      {row.category_name}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {parseFloat(row.percentViews.toFixed(2))}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {row.totalViews}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {parseFloat(row.percentFavorites.toFixed(2))}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {row.totalFavorites}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <BotoesPaginacao />
    </SectionMain>
  );
};

export default PartnersPanelDataDesktop;
