import api from "../core/api";

export const useUpdateUser = async () => {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");
  if (token) {
    try {
      const data = await api.get(`auth/me`);

      if (data.status === 200) {
        localStorage.setItem("id", data.data.id);
        localStorage.setItem("name", data.data.name);
        localStorage.setItem("plano", data.data.premium);
        localStorage.setItem("nickName", data.data.nickName);
        localStorage.setItem("email", data.data.email);
        localStorage.setItem("avatarUrl", data.data.avatarUrl);
      }
      return data.data;
    } catch (e) {
      console.error({ e });

      return false;
    }
  }
};
