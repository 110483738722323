import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import banner_categorias from "../../../assets/images/banners/Banner_categorias.jpg";
import ButtonMedita from "../../../components/ButtonMedita";
import FooterSection from "../../../components/FooterSection";
import IconCategoryButton from "../../../components/IconCategoryButton";
import SectionMain from "../../../components/SectionMain";
import Title from "../../../components/Title";
import VideosCard from "../../../components/VideosCard";
import useGoToPage from "../../../hooks/useGoToPage";
import useVideosArray from "../../../hooks/useVideosArray";
import { useAppContext } from "../../../store/AppContext";
import PremiumSection from "../premiumSection/PremiumSection";

const useStyles = makeStyles({
  container: {
    padding: "20px 6px",
    justifyContent: "center",
    maxWidth: "90vw",
    margin: "0 auto",
  },
  containerFilter: {
    padding: "20px 6px",
    justifyContent: "center",
    maxWidth: "90vw",
    margin: "70px auto",
  },
  centerTextBlock: {
    textAlign: "center",
    display: "block",
    margin: "-50px 10px 0",
    padding: "0 40px",
  },
  titleCenter: {
    textAlign: "center",
    display: "block",
  },
  center: {
    display: "block",
    margin: "0 auto",
  },
  "justify > div": {
    justifyContent: "space-between",
  },
  banner: {
    background: "#f8f9fa",
    height: 400,
    justifyContent: "space-between",
    padding: "10px 4vw",
    backgroundImage: `url(${banner_categorias})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  leftSection: { padding: "25px 0", maxHeight: 400 },
  image: { width: "45vw", maxWidth: 400 },
  title: {
    width: 260,
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 0 30px",
    paddingTop: 10,
  },
  cursive: {
    color: "#008393",
  },
  listVideos: {
    width: "90vw",
  },
  btnModal: {
    display: "block",
    margin: "0 auto",
    width: "290px",
    height: "60px",
  },
});

const CategoriesPageDesktop = () => {
  const classes = useStyles();
  const goToPage = useGoToPage();
  const videos = useVideosArray();
  const {
    activeCategory: titleCategory,
    filter,
    route,
    setAppState,
  } = useAppContext();

  useEffect(() => {
    function getCategory() {
      switch (window.location.search) {
        case "?meditacoes":
          return setAppState({
            activeCategory: "Meditações",
            filter: /Aprender a respirar|Livre de ansiedade/,
          });
        case "?minimedita":
          return setAppState({
            activeCategory: "MiniMedita",
            filter: "",
          });
        case "?yoga":
          return setAppState({
            activeCategory: "Yoga",
            filter: /Chandra Pranayama|Natarajásana/,
          });
        case "?mantras":
          return setAppState({
            activeCategory: "Mantras",
            filter:
              /Afirmações para paz e harmonia|Alinhamento dos sete chacras/,
          });
        case "?musicas":
          return setAppState({
            activeCategory: "Músicas",
            filter: "",
          });
        case "?exercicios":
          return setAppState({
            activeCategory: "Exercícios",
            filter: /Alongamento e preparação|Alongamento matinal/,
          });
        case "":
          return setAppState({ activeCategory: "", filter: "" });
        default:
          return setAppState({ activeCategory: "", filter: "" });
      }
    }
    return getCategory();
  }, [route]);

  return (
    <SectionMain noPadding>
      <SectionMain noPadding className={classes.banner}>
        <SectionMain item xs={5} xl={5}></SectionMain>
        <SectionMain item xs={5} xl={5} className={classes.leftSection}>
          <SectionMain direction="column">
            <Title
              size="xbig"
              fontWeight="700"
              color="#424242"
              className={classes.titleCenter}
            >
              Centenas de conteúdos
              <br />
              sem anúncios e interrupções
            </Title>
          </SectionMain>
          <Title color="#424242" className={classes.centerTextBlock}>
            Assista, escute e pratique com nossos vídeos, áudios e músicas.
            <br />
            Descubra os seus conteúdos favoritos para salvar e criar playlists.
            <br />
            Cuide da mente, o corpo e a alma. Conheça o MeditaBem.
          </Title>

          <SectionMain noPadding className={classes.btnModal}>
            <ButtonMedita
              onClick={() => goToPage("/medita-bem-planos")}
              className={classes.btnModal}
            >
              {"QUERO EXPERIMENTAR"}
            </ButtonMedita>
          </SectionMain>
        </SectionMain>
      </SectionMain>

      <SectionMain className={classes.containerFilter}>
        {/* <CategoriesIconText /> */}
        <IconCategoryButton />
      </SectionMain>

      <SectionMain noPadding>
        <SectionMain className={classes.container}>
          <Title
            fontWeight="800"
            size="medium"
            align="center"
            capitalize="uppercase"
            letterSpacing={2}
          >
            {titleCategory === ""
              ? "FEITOS PARA VOCÊ"
              : titleCategory.toUpperCase()}
          </Title>
        </SectionMain>
        <SectionMain
          className={classes.container}
          id="videos-categories-container"
          justifyContent="space-between"
        >
          <VideosCard
            videos={videos.filter(({ category }) =>
              category.includes(titleCategory)
            )}
          />
        </SectionMain>
      </SectionMain>
      <div>
        <SectionMain className={classes.container}>
          <SectionMain className={classes.title}>
            <Title
              fontWeight="800"
              size="medium"
              align="center"
              capitalize="uppercase"
              letterSpacing={2}
            >
              FEITOS PARA VOCÊ
            </Title>
          </SectionMain>
          <VideosCard
            videos={videos.filter(
              ({ category }) => !category.includes(titleCategory)
            )}
            position="center"
            scrollable="true"
          />
        </SectionMain>
        <PremiumSection mobile={false} />
      </div>

      <SectionMain>
        <FooterSection hideNewsletter={true} />
      </SectionMain>
    </SectionMain>
  );
};

export default CategoriesPageDesktop;
