import React from "react";
import { useHistory } from "react-router";
import exerciciosActive from "../../../assets/images/icons/exercicios-active.png";
import exercicios from "../../../assets/images/icons/exercicios.png";
import mantrasActive from "../../../assets/images/icons/mantras-active.png";
import mantras from "../../../assets/images/icons/mantras.png";
import meditacoesActive from "../../../assets/images/icons/meditacoes-active.png";
import meditacoes from "../../../assets/images/icons/meditacoes.png";
import yogaActive from "../../../assets/images/icons/yoga-active.png";
import yoga from "../../../assets/images/icons/yoga.png";
import useCategoryButton from "../../../hooks/useCategoryButton";
import { useAppContext } from "../../../store/AppContext";
import SectionMain from "../../SectionMain";

const IconCategoryButtonWrapper = ({ children }) => {
  const setSelectedCategory = useCategoryButton();
  const { activeCategory } = useAppContext();

  //não usar goToPage, assim o menu não terá o scroll para o topo.
  const history = useHistory();

  const handleCategoryRoute = (link, title, regex) => {
    history.push(link);
    return setSelectedCategory(title, regex);
  };

  const categoryButtons = [
    {
      title: "",
      goTolink: () => handleCategoryRoute("/experimente", ""),
      color: "#EEE",
    },
    {
      title: "Meditações",
      goTolink: () =>
        handleCategoryRoute(
          "/experimente?meditacoes",
          "Meditações",
          /Aprender a respirar|Livre de ansiedade/
        ),
      src: activeCategory === "Meditações" ? meditacoesActive : meditacoes,
      color: "#2d6671",
    },
    {
      title: "MiniMedita",
      goTolink: () =>
        handleCategoryRoute("/experimente?minimedita", "MiniMedita"),
      src: activeCategory === "MiniMedita" ? "" : " ",
      color: "#fd8694",
    },
    {
      title: "Yoga",
      goTolink: () =>
        handleCategoryRoute(
          "/experimente?yoga",
          "Yoga",
          /Chandra Pranayama|Natarajásana/
        ),
      src: activeCategory === "Yoga" ? yogaActive : yoga,
      color: "#feaa1a",
    },
    {
      title: "Mantras",
      goTolink: () =>
        handleCategoryRoute(
          "/experimente?mantras",
          "Mantras",
          /Afirmações para paz e harmonia|Alinhamento dos sete chacras/
        ),
      src: activeCategory === "Mantras" ? mantrasActive : mantras,
      color: "#fed904",
    },
    {
      title: "Músicas",
      goTolink: () => handleCategoryRoute("/experimente?musicas", "Músicas"),
      src: activeCategory === "Músicas" ? "" : " ",
      color: "#31b1f6",
    },
    {
      title: "Exercícios",
      goTolink: () =>
        handleCategoryRoute(
          "/experimente?exercicios",
          "Exercícios",
          /Alongamento e preparação|Alongamento matinal/
        ),
      src: activeCategory === "Exercícios" ? exerciciosActive : exercicios,
      color: "#4cc836",
    },
  ];

  return (
    <SectionMain position="space-around" noPadding>
      {categoryButtons.map(
        ({ title, goTolink, setSelectedCategory, src, color }) => {
          if (title === activeCategory) {
            return (
              <span key={`categoria--${title}`}>
                {children({
                  title,
                  goTolink,
                  src,
                  setSelectedCategory,
                  color,
                  active: true,
                })}
              </span>
            );
          } else {
            return (
              <span key={`categoria--${title}`}>
                {children({
                  title,
                  goTolink,
                  src,
                  setSelectedCategory,
                  color,
                  active: false,
                })}
              </span>
            );
          }
        }
      )}
    </SectionMain>
  );
};

export default IconCategoryButtonWrapper;
