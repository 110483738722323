import React from 'react';
import { Hidden } from '@material-ui/core';
import SectionMain from '../../components/SectionMain';
import PartnersPageDesktop from './desktop';
import PartnersPageMobile from './mobile';
import usePartnerForm from '../../hooks/usePartnerForm';
import useGoToPage from '../../hooks/useGoToPage';

const PartnersPage = () => {
  const goToPage = useGoToPage();
  const handlePartnerForm = usePartnerForm()[1];

  const onSubmitForm = (values) => {
    handlePartnerForm(values);
    goToPage('/ser-parceiro');
  };

  return (
    <SectionMain noPadding>
      <Hidden only={['xs', 'sm']}>
        <PartnersPageDesktop handleSubmitForm={onSubmitForm} />
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <PartnersPageMobile handleSubmitForm={onSubmitForm} />
      </Hidden>
    </SectionMain>
  );
};

export default PartnersPage;
