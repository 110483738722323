import React from "react";
import FooterSectionLinks from "./FooterSectionLinks";

const FooterSection = () => {
  return (
    <div style={{ background: "#FFF", width: "100%", marginBottom: "-15px" }}>
      <FooterSectionLinks />
    </div>
  );
};

export default FooterSection;
