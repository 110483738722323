import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import banner_categorias from "../../../assets/images/banners/Banner_categorias.jpg";
import ButtonMedita from "../../../components/ButtonMedita";
import FooterSection from "../../../components/FooterSection";
import IconCategoryButton from "../../../components/IconCategoryButton";
import SectionMain from "../../../components/SectionMain";
import Title from "../../../components/Title";
import VideosCard from "../../../components/VideosCard";
import useGoToPage from "../../../hooks/useGoToPage";
import useVideosArray from "../../../hooks/useVideosArray";
import { useAppContext } from "../../../store/AppContext";
import PremiumSection from "../premiumSection/PremiumSection";

const useStyles = makeStyles({
  container: {
    padding: "10px 2vw",
    justifyContent: "center",
    marginBottom: "80px",
  },
  banner: {
    background: "#f8f9fa",
    height: 350,
    justifyContent: "space-between",
    padding: "0px 4vw",
    backgroundImage: `url(${banner_categorias})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  title: {
    width: 210,
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 0 30px",
    paddingTop: 10,
  },
  bannerTitle: {
    maxWidth: 400,
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 0 10px",
    paddingTop: 10,
    fontSize: "2em",
  },
  titleConteudo: {
    fontSize: "1.4em",
    textTransform: "none",
  },
  btnFreeDays: {
    textTransform: "none",
    maxWidth: "240px",
    padding: "30px 0",
  },
  cursive: {
    color: "#008393",
  },
});

const CategoriesPageMobile = () => {
  const classes = useStyles();
  const goToPage = useGoToPage();
  const videos = useVideosArray();
  const {
    activeCategory: titleCategory,
    filter,
    route,
    setAppState,
  } = useAppContext();

  useEffect(
    () => {
      function getCategory() {
        switch (window.location.search) {
          case "?meditacoes":
            return setAppState({
              activeCategory: "Meditações",
              filter: /Aprender a respirar|Livre de ansiedade/,
            });
          case "?minimedita":
            return setAppState({
              activeCategory: "MiniMedita",
              filter: "",
            });
          case "?yoga":
            return setAppState({
              activeCategory: "Yoga",
              filter: /Chandra Pranayama|Natarajásana/,
            });
          case "?mantras":
            return setAppState({
              activeCategory: "Mantras",
              filter:
                /Afirmações para paz e harmonia|Alinhamento dos sete chacras/,
            });
          case "?musicas":
            return setAppState({
              activeCategory: "Músicas",
              filter: "",
            });
          case "?exercicios":
            return setAppState({
              activeCategory: "Exercícios",
              filter: /Alongamento e preparação|Alongamento matinal/,
            });
          case "":
            return setAppState({ activeCategory: "", filter: "" });
          default:
            return setAppState({ activeCategory: "", filter: "" });
        }
      }
      return getCategory();
    },
    // eslint-disable-next-line
    [route]
  );

  return (
    <SectionMain>
      <SectionMain className={classes.banner} />
      <SectionMain className={classes.container}>
        <SectionMain className={classes.bannerTitle}>
          <Title
            fontWeight="800"
            size="medium"
            align="center"
            capitalize="uppercase"
            letterSpacing={2}
            className={classes.titleConteudo}
          >
            {"Centenas de conteúdos"}
            <br />
            {"sem anúncios e interrupções"}
          </Title>
        </SectionMain>
        <SectionMain position="center">
          <Title align="center">
            Assista, escute e pratique com nossos vídeos, áudios e músicas.
            <br />
            Descubra os seus conteúdos favoritos para salvar e criar playlists.
            <br />
            Cuide da mente, o corpo e a alma. Conheça o MeditaBem.
          </Title>
        </SectionMain>
        <ButtonMedita
          onClick={() =>
            goToPage(
              "/medita-bem-planos"
            )
          }
          className={classes.btnFreeDays}
        >
          {"QUERO EXPERIMENTAR"}
        </ButtonMedita>
      </SectionMain>

      <SectionMain className={classes.container}>
        {/* <CategoriesIconText /> */}
        <IconCategoryButton />
      </SectionMain>

      <SectionMain noPadding>
        <SectionMain className={classes.container}>
          <Title
            fontWeight="800"
            size="medium"
            align="center"
            capitalize="uppercase"
            letterSpacing={2}
          >
            {titleCategory === ""
              ? "EXPERIMENTE GRÁTIS"
              : titleCategory.toUpperCase()}
          </Title>
        </SectionMain>
        <SectionMain className={classes.container}>
          <VideosCard
            videos={videos.filter(({ category }) =>
              category.includes(titleCategory)
            )}
          />
        </SectionMain>
      </SectionMain>

      <div>
        <SectionMain className={classes.container}>
          <SectionMain className={classes.title}>
            <Title
              fontWeight="800"
              size="medium"
              align="center"
              capitalize="uppercase"
              letterSpacing={2}
            >
              {"EXPERIMENTE GRÁTIS"}
            </Title>
          </SectionMain>
          <VideosCard
            videos={videos.filter(
              ({ category }) => !category.includes(titleCategory)
            )}
            position="center"
            scrollable={true}
            showIconAndAvatar={true}
          />
        </SectionMain>
        <PremiumSection />
      </div>
      <SectionMain>
        <FooterSection mobile hideNewsletter={true} />
      </SectionMain>
    </SectionMain>
  );
};

export default CategoriesPageMobile;
