import { Hidden } from "@material-ui/core";
import { Formik } from "formik";
import React, { useState } from "react";
import { object, string } from "yup"; // for only what you need
import ModalFormResult from "../../components/ModalFormResult";
import SectionMain from "../../components/SectionMain";
import api from "../../core/api";
import ContactPageDesktop from "./desktop";
import ContactPageMobile from "./mobile";

const ContactPage = () => {
  const [response, setResponse] = useState();
  const [loading, changeLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("");
  const [modalBody, setModalBody] = React.useState("");

  const openModal = (header, body) => {
    setIsOpen(true);
    setModalHeader(header);
    setModalBody(body);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const defaultValues = {
    first_name: "",
    email: "",
    phone: "",
    message: "",
  };

  const toggleLoading = () => {
    console.log("before", loading);

    changeLoading(!loading);

    console.log("after", loading);
  };

  const validationSchema = () =>
    object().shape({
      first_name: string().required("Preencha o campo Nome"),
      email: string()
        .email("Digite um e-mail válido")
        .required("Preencha o campo, E-mail"),
    });

  const onSubmitForm = async (values = { document_number: "" }) => {
    toggleLoading();

    const form = {
      ...values,
      email: values.email.toLowerCase(),
    };
    console.log("form msg", form);

    try {
      await api.post(`/contato`, form).then((res) => {
        toggleLoading();

        if (res.status === 200) {
          openModal(
            "Obrigado pelo contato",
            "Recebemos sua mensagem. Logo entraremos em contato."
          );
          setResponse(res);
        }
      });
    } catch (e) {
      toggleLoading();

      console.error({ ...e });

      openModal(
        `Erro ${e.response.status}`,
        e.response.data.message || "Algo deu errado na sua solicitação"
      );
    }
  };

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        initialValues={defaultValues}
        onSubmit={(values) => {
          onSubmitForm(values);
        }}
      >
        {(formikProps) => (
          <SectionMain noPadding>
            <Hidden only={["xs", "sm"]}>
              <ContactPageDesktop
                handleForm={formikProps.setFieldValue}
                values={formikProps.values}
                formikProps={formikProps}
              />
            </Hidden>
            <Hidden only={["md", "lg", "xl"]}>
              <ContactPageMobile
                handleForm={formikProps.setFieldValue}
                values={formikProps.values}
                formikProps={formikProps}
              />
            </Hidden>
          </SectionMain>
        )}
      </Formik>
      <ModalFormResult
        isOpen={modalIsOpen}
        closeModal={closeModal}
        title={modalHeader}
        text={modalBody}
      ></ModalFormResult>
    </div>
  );
};

export default ContactPage;
