import { Hidden, IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import useGoToPage from "../../../hooks/useGoToPage";
import SectionMain from "../../SectionMain";

const useStyles = makeStyles({
  justify: {
    justifyContent: "left",
    display: "block",
    margin: "0 auto",
    width: "90vw",
    "&>div": {
      justifyContent: "left",
    },
  },
});

const VideosCardWrapper = ({
  children,
  videos = [],
  position,
  renderMapCards,
  scrollable,
  showIconAndAvatar,
  inLine,
}) => {
  const classes = useStyles();

  const goToPage = useGoToPage();

  const RenderChildrenMap = () =>
    videos.map(({ name, thumbnail, partner, icon, id }, index) => (
      <span key={`video-card-${name}`} id="card-video-span">
        {children({
          last: videos.length - 1,
          index,
          title: name,
          thumbnail,
          partnerName: partner && partner.artisticName,
          partnerAvatar: partner && partner.thumbnailURL,
          link: `/video?id=${id}`,
          icon,
          goToPage,
        })}
      </span>
    ));

  if (renderMapCards) {
    return <RenderChildrenMap />;
  }

  const normalCards = (
    <SectionMain
      noPadding
      position={position}
      className={classes.justify}
      id="video-wrapper"
    >
      <SectionMain noPadding style={{ justifyContent: "center" }}>
        {videos.map(({ name, thumbnail, partner, icon, id }, index) => (
          <span className="video-card-container" key={`video-card-${name}`}>
            {children({
              last: videos.length - 1,
              index,
              title: name,
              thumbnail,
              showIconAndAvatar,
              partnerName: partner && partner.artisticName,
              partnerAvatar: partner && partner.thumbnailURL,
              link: `/video?id=${id}`,
              icon,
              goToPage,
            })}
          </span>
        ))}
      </SectionMain>
    </SectionMain>
  );

  const inlineCards = (
    <SectionMain noPadding position={position} className={classes.justify}>
      <SectionMain noPadding>
        {videos.map(({ name, thumbnail, partner, icon, id }, index) => (
          <span class="video-card-container-inline" key={`video-card-${name}`}>
            {children({
              last: videos.length - 1,
              index,
              title: name,
              thumbnail,
              showIconAndAvatar,
              partnerName: partner && partner.artisticName,
              partnerAvatar: partner && partner.thumbnailURL,
              link: `/video?id=${id}`,
              icon,
              inLine: false,
              goToPage,
            })}
          </span>
        ))}
      </SectionMain>
    </SectionMain>
  );

  const scrollableCards = (
    <SectionMain noPadding position={position} className={classes.wrapper}>
      <Hidden only={["xs", "sm"]}>
        <ScrollMenu
          wheel={false}
          arrowRight={
            <IconButton
              className="scroll-menu-arrow--disabled"
              size="small"
              style={{
                background: "#f9f9f9",
                border: "solid 1px #d8d8d8",
                position: "relative",
                width: "46px",
                height: "46px",
              }}
            >
              <MdKeyboardArrowRight
                style={{
                  fontWeight: "200",
                  borderRadius: "100%",
                  fontSize: "2vw",
                  color: "#282828",
                }}
              />
            </IconButton>
          }
          arrowLeft={
            <IconButton
              className="scroll-menu-arrow--disabled"
              size="small"
              style={{
                background: "#f9f9f9",
                border: "solid 1px #d8d8d8",
                position: "relative",
                zIndex: "999999",
                width: "46px",
                height: "46px",
              }}
            >
              <MdKeyboardArrowLeft
                style={{
                  fontWeight: "200",
                  borderRadius: "100%",
                  fontSize: "2vw",
                  color: "#282828",
                }}
              />
            </IconButton>
          }
          hideArrows={true}
          dragging={false}
          clickWhenDrag={false}
          alignOnResize={false}
          alignCenter={false}
          wrapperStyle={{ width: "90vw" }}
          itemStyle={{ outline: "none" }}
          data={videos.map(({ name, thumbnail, partner, icon, id }, index) => (
            <span key={`video-card-${name}`}>
              {children({
                last: videos.length - 1,
                index,
                title: name,
                thumbnail,
                showIconAndAvatar,
                partnerName: partner && partner.artisticName,
                partnerAvatar: partner && partner.thumbnailURL,
                link: `/video?id=${id}`,
                icon,
                goToPage,
              })}
            </span>
          ))}
        />
      </Hidden>

      <Hidden only={["md", "lg", "xl"]}>
        <SectionMain position="center" noPadding>
          <ScrollMenu
            alignOnResize={false}
            alignCenter={false}
            inertiaScrolling
            hideArrows={true}
            wheel={false}
            itemStyle={{ outline: "none" }}
            scrollToSelected={true}
            wrapperStyle={{ width: "95vw" }}
            data={videos.map(
              ({ name, thumbnail, partner, icon, id }, index) => (
                <span
                  class={showIconAndAvatar ? "1" : "2"}
                  key={`video-card-${name}`}
                >
                  {children({
                    last: videos.length - 1,
                    index,
                    title: name,
                    thumbnail,
                    partnerName: partner && partner.artisticName,
                    partnerAvatar: partner && partner.thumbnailURL,
                    link: `/video?id=${id}`,
                    icon,
                    goToPage,
                  })}
                </span>
              )
            )}
          />
        </SectionMain>
      </Hidden>
    </SectionMain>
  );

  if (inLine) {
    return inlineCards;
  }

  if (scrollable) {
    return scrollableCards;
  }

  return normalCards;
};

export default VideosCardWrapper;
