import React from 'react';
import { makeStyles } from '@material-ui/core';
import SectionMain from '../../SectionMain';
import Title from '../../Title';
import InputText from '../../InputText';
import ButtonMedita from '../../ButtonMedita';
import { STYLES } from './styles';
import { TEXTS } from './texts';

const useStyles = makeStyles(STYLES);

const FormContactMinimalUI = ({
  mobile,
  formValues,
  typeFooter,
  success = false,
  handleChangeInput,
  goToPage,
  handleSubmit,
  formikProps,
  buttonText,
}) => {
  const classes = useStyles({ mobile, typeFooter });
  const texts = TEXTS;

  return (
    <SectionMain noPadding>
      <form
        action=''
        onSubmit={handleSubmit ? handleSubmit : formikProps.handleSubmit}
        style={{ width: 'inherit' }}
      >
        <SectionMain className={classes.banner}>
          <SectionMain noPadding className={classes.overlayContainer}>
            <SectionMain
              noPadding
              position='center'
              direction='column'
              className={classes.inputs}
            >
              <InputText
                errorColor='#fff'
                value={formValues.first_name}
                errorMessage={
                  formikProps.touched.first_name &&
                  formikProps.errors.first_name
                }
                onChange={(e) =>
                  handleChangeInput('first_name', e.target.value)
                }
                label='Nome'
              />
              <InputText
                errorColor='#fff'
                value={formValues.email}
                onChange={(e) => handleChangeInput('email', e.target.value)}
                errorMessage={
                  formikProps.touched.email && formikProps.errors.email
                }
                type='email'
                label='E-mail'
              />
            </SectionMain>

            <ButtonMedita
              type='submit'
              size='small'
              className={classes.mobileButton}
            >
              {buttonText ? buttonText : 'CRIAR UMA CONTA'}
            </ButtonMedita>

            <br />
          </SectionMain>
        </SectionMain>
      </form>
    </SectionMain>
  );
};

export default FormContactMinimalUI;
