import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import { cn } from "../../utils/classNames";
// eslint-disable-next-line
// import TimeJump from '../TimeJump/TimeJump'
import "./Player.css";
import Overlay from "../Overlay/Overlay";
import ToggleButton from "../ToggleButton/ToggleButton";
import Progress from "../Progress/Progress";
import Volume from "../Volume/Volume";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Player = ({ className, src, thumbnail, setor }) => {
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    // console.log("video", video.duration);
    const updateDuration = () => {
      if (video.id === "Premium") {
        setDuration(30);
      } else {
        setDuration(video.duration);
      }
    };
    video.addEventListener("loadedmetadata", updateDuration);

    video.addEventListener("timeupdate", function () {
      if (!this._startTime) this._startTime = this.currentTime;

      // if (this.currentTime >= 15) {
      //   this.pause();
      // }
    });

    return () => video.removeEventListener("loadedmetadata", updateDuration);
  }, [setDuration]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    const updateTime = () => {
      setCurrentTime(video.currentTime);
    };
    video.addEventListener("timeupdate", updateTime);
    return () => video.removeEventListener("timeupdate", updateTime);
  }, [currentTime, setCurrentTime]);

  // useEffect(() => {
  //   setControls(true);
  //   var video = document.querySelector("video");

  //   video.addEventListener("timeupdate", function () {
  //     // don't have set the startTime yet? set it to our currentTime
  //     if (!this._startTime) this._startTime = this.currentTime;

  //     var playedTime = this.currentTime - this._startTime;

  //     if (this.currentTime >= 15) {
  //       this.pause();
  //     }
  //   });

  //   video.addEventListener("seeking", function () {
  //     // reset the timeStart
  //     this._startTime = undefined;
  //   });
  // }, [src]);

  return (
    <div className={cn("Rac", className)} ref={playerRef}>
      <video
        // className='Rac__video'
        id={setor}
        ref={videoRef}
        src={src}
        poster={thumbnail}
        preload="metadata"
        autoPlay
      />

      <Overlay className="Rac__overlay">
        {/* <div className="Rac__playback">
          <ToggleButton
            style={{ outline: "none" }}
            className="Rac__playback-button"
            value={() => videoRef.current && !videoRef.current.paused}
            onChange={(active) => {
              if (!active) {
                return videoRef.current.pause();
              }

              return videoRef.current.play();
            }}
          >
            {(active) => {
              if (!active) {
                return <FontAwesomeIcon icon="play" size="60px" />;
              }

              return <FontAwesomeIcon icon="pause" />;
            }}
          </ToggleButton>
        </div> */}
        <div className="Rac__controls">
          <ToggleButton
            style={{ outline: "none" }}
            className="Rac__playback-button"
            value={() => videoRef.current && !videoRef.current.paused}
            onChange={(active) => {
              if (!active) {
                return videoRef.current.pause();
              }

              return videoRef.current.play();
            }}
          >
            {(active) => {
              if (!active) {
                return <FontAwesomeIcon icon="play" />;
              }

              return <FontAwesomeIcon icon="pause" />;
            }}
          </ToggleButton>
          <Progress
            className="Rac__progress-bar"
            initial={0}
            time={currentTime}
            duration={duration}
            onSkip={(time) => (videoRef.current.currentTime = time)}
          />
          <Volume
            className="Rac__volume"
            onChange={(level) => {
              const volume = level / 100;
              videoRef.current.volume = volume;
              videoRef.current.muted = volume === 0;
            }}
          />
          <ToggleButton
            className="Rac__screen-toggle"
            initial={document.fullscreenElement}
            onChange={(active) => {
              if (!active) {
                return document.exitFullscreen();
              }

              return playerRef.current.requestFullscreen({
                navigationUI: "hide",
              });
            }}
          >
            {(active) => {
              if (!active) {
                return <FontAwesomeIcon icon="expand" />;
              }

              return <FontAwesomeIcon icon="compress" />;
            }}
          </ToggleButton>
        </div>
      </Overlay>
    </div>
  );
};

Player.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
};

export default Player;
