import React from "react";
import InputSelect from "../../InputSelect";
import InputText from "../../InputText";

const FormPartnerUI = ({
  keyObject,
  label,
  type,
  icon,
  value,
  handleFormChange,
  errorMessage,
  mobile,
}) => {
  switch (true) {
    case /state/.test(keyObject):
      return (
        <InputSelect
          width="19%"
          value={value}
          onChange={(e) => handleFormChange(e.target.value)}
          type={type}
          label={label}
          errorMessage={errorMessage}
          leftIcon={icon}
        />
      );
    case /email|phone|document_number|occupation/.test(keyObject):
      return (
        <InputText
          width="48%"
          value={value}
          onChange={(e) => handleFormChange(e.target.value)}
          type={type}
          label={label}
          errorMessage={errorMessage}
          leftIcon={icon}
        />
      );
    case /street/.test(keyObject):
      return (
        <InputText
          width="69%"
          value={value}
          onChange={(e) => handleFormChange(e.target.value)}
          type={type}
          label={label}
          errorMessage={errorMessage}
          leftIcon={icon}
        />
      );
    case /number/.test(keyObject):
      return (
        <InputText
          width="29%"
          value={value}
          onChange={(e) => handleFormChange(e.target.value)}
          type={type}
          label={label}
          errorMessage={errorMessage}
          leftIcon={icon}
        />
      );
    case /postal_code|city|country|facebook|instagram|youtube|others/.test(
      keyObject
    ):
      return (
        <InputText
          width={mobile ? "48%" : "22%"}
          value={value}
          onChange={(e) => handleFormChange(e.target.value)}
          type={type}
          label={label}
          errorMessage={errorMessage}
          leftIcon={icon}
        />
      );
    case /message/.test(keyObject):
      return (
        <InputText
          id="description"
          multiline
          rows="5"
          errorColor="red"
          value={value}
          onChange={(e) => handleFormChange(e.target.value)}
          type={type}
          label={label}
          errorMessage={errorMessage}
          leftIcon={icon}
        />
      );

    default:
      return (
        <InputText
          value={value}
          onChange={(e) => handleFormChange(e.target.value)}
          type={type}
          label={label}
          errorMessage={errorMessage}
          leftIcon={icon}
        />
      );
  }
};

export default FormPartnerUI;
