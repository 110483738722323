import { makeStyles } from "@material-ui/core";
import React from "react";
import facebook from "../../../assets/images/icons/facebook-light.png";
import instagram from "../../../assets/images/icons/instagram.png";
import outros from "../../../assets/images/icons/outros.png";
import youtube from "../../../assets/images/icons/youtube.png";
import SectionMain from "../../SectionMain";
import Title from "../../Title";

const useStyles = makeStyles({
  container: {
    justifyContent: "space-between",
    padding: ({ mobile }) => (mobile ? "10px 2vw" : "20px 0"),
  },
  leftSection: {
    justifyContent: "space-between",
    padding: ({ mobile }) => (mobile ? "0px 2vw" : "0 15px 20px 0px"),
  },
  rightSection: {
    justifyContent: "space-between",
    padding: ({ mobile }) => (mobile ? "0px 2vw" : "0 0px 20px 15px"),
  },
});

const FormPartnerWrapper = ({ children, formikProps, mobile }) => {
  const classes = useStyles({ mobile });
  const getInputs = Object.keys(formikProps.values);

  const inputs = {
    name: { label: "Nome completo", type: "text" },
    email: { label: "E-mail", type: "email" },
    phone_first: { label: "Telefone", type: "text" },
    document_number: { label: "CPF ou CNPJ", type: "text" },
    occupation: { label: "Especialidade", type: "text" },
    street: { label: "Endereço", type: "text" },
    number: { label: "Número/Apartamento/Unidade", type: "text" },
    postal_code: { label: "CEP", type: "text" },
    city: { label: "Cidade", type: "text" },
    country: { label: "País", type: "text" },
    state: { label: "Estado", type: "text" },
    facebook: {
      label: "Facebook",
      icon: <img src={facebook} />,
      type: "text",
    },
    instagram: {
      label: "Instagram",
      icon: <img src={instagram} />,
      type: "text",
    },
    youtube: {
      label: "Youtube",
      icon: <img src={youtube} />,
      type: "text",
    },
    others: {
      label: "Outros",
      icon: <img src={outros} />,
      type: "text",
    },
    message: { label: "Conte um pouco a seu respeito", icon: "", type: "text" },
  };

  return (
    <SectionMain noPadding>
      <SectionMain item xs={12} className={classes.leftSection}>
        <SectionMain position="center">
          <Title
            fontWeight="800"
            size="medium"
            align="center"
            capitalize="uppercase"
            letterSpacing={2}
          >
            {"INFORMAÇÕES PESSOAIS"}
          </Title>
        </SectionMain>
        {getInputs.slice(0, 5).map((key, i) =>
          children({
            key: `input-${inputs[key].label}`,
            keyObject: key,
            label: inputs[key].label,
            icon: inputs[key].icon,
            type: inputs[key].type,
            handleFormChange: (value) => formikProps.setFieldValue(key, value),
            value: formikProps.values[key],
            errorMessage: formikProps.touched[key] && formikProps.errors[key],
          })
        )}
      </SectionMain>
      <SectionMain xs={12}></SectionMain>
      <SectionMain item xs={12} className={classes.rightSection}>
        <SectionMain position="center">
          <Title
            fontWeight="800"
            size="medium"
            align="center"
            capitalize="uppercase"
            letterSpacing={2}
          >
            {"ENDEREÇO"}
          </Title>
        </SectionMain>
        {getInputs.slice(5, 11).map((key, i) =>
          children({
            key: `input-${inputs[key].label}`,
            keyObject: key,
            label: inputs[key].label,
            icon: inputs[key].icon,
            type: inputs[key].type,
            handleFormChange: (value) => formikProps.setFieldValue(key, value),
            value: formikProps.values[key],
            errorMessage: formikProps.touched[key] && formikProps.errors[key],
          })
        )}
      </SectionMain>
      <SectionMain position="space-between" className={classes.container}>
        <SectionMain position="center">
          <Title
            fontWeight="800"
            size="medium"
            align="center"
            capitalize="uppercase"
            letterSpacing={2}
          >
            {"REDES SOCIAIS"}
          </Title>
        </SectionMain>
        {getInputs.slice(11, 15).map((key, i) =>
          children({
            keyObject: key,
            label: inputs[key].label,
            icon: inputs[key].icon,
            type: inputs[key].type,
            handleFormChange: (value) => formikProps.setFieldValue(key, value),
            value: formikProps.values[key],
            errorMessage: formikProps.touched[key] && formikProps.errors[key],
          })
        )}
      </SectionMain>
      <SectionMain position="space-between" className={classes.container}>
        {getInputs.slice(-1).map((key, i) =>
          children({
            keyObject: key,
            label: inputs[key].label,
            icon: inputs[key].icon,
            type: inputs[key].type,
            handleFormChange: (value) => formikProps.setFieldValue(key, value),
            value: formikProps.values[key],
            errorMessage: formikProps.touched[key] && formikProps.errors[key],
          })
        )}
      </SectionMain>
    </SectionMain>
  );
};

export default FormPartnerWrapper;
