import { IconButton, makeStyles } from "@material-ui/core";
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import ModalFormResult from "../ModalFormResult";
import ModalForgotPassword from "../ModalForgotPassword";
import ModalRecoveryPass from "../ModalRecoveryPass";
import ButtonMedita from "../ButtonMedita";
import InputText from "../InputText";
import SectionMain from "../SectionMain";
import Title from "../Title";
import ButtonRegister from "./ButtonRegister";
import api from "../../core/api";
import { AuthContext } from "../../store/AuthContext";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  CheckCircle,
  CheckCircleOutline,
  Visibility,
} from "@material-ui/icons";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.3)";

const useStyles = makeStyles({
  modal: {
    width: "80%",
    maxWidth: "600px",
    background: "#fff",
    margin: "40px auto",
    borderRadius: "20px",
    padding: "20px",
  },
  title: { width: "80%", margin: "20px 10px 20px" },
  modalText: {
    padding: "20px",
    width: "80%",
  },
  borderLeft: {
    borderLeft: "1px solid #ddd",
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "center",
  },
  form: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
  },
  alignEnterButton: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontWeight: "bold",
    textAlign: "left",
    width: "100%",
    marginTop: "15px",
    marginLeft: "20px",
  },
  linksenha: {
    color: "#000",
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
    marginTop: "5px",
  },
});

const ModalLogin = ({ open, emailEx }) => {
  const [modalIsOpen, setIsOpen] = useState(open);
  const [email, setEmail] = useState(emailEx);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [emailModal, setEmailModal] = useState("");

  const [modalErroIsOpen, setErroIsOpen] = useState(false);
  const [modalHeader, setModalHeader] = React.useState("");
  const [modalBody, setModalBody] = React.useState("");

  const [modalForgotPassword, setModalFPIsOpen] = useState(false);
  const [viewSenha, setViewSenha] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { setToken, setData } = useContext(AuthContext);

  const snackbar = (message) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const openModalErro = (header, body) => {
    setErroIsOpen(true);
    setModalHeader(header);
    setModalBody(body);
  };

  const closeModalErro = () => {
    setErroIsOpen(false);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModalFP() {
    setModalFPIsOpen(true);
  }

  function closeModalFP() {
    setModalFPIsOpen(false);
  }

  const recoverPasswordUser = async () => {
    if (!email) {
      snackbar("Insira um e-mail");
      return;
    }
    setEmailModal(email);
    openModalFP();
  };

  async function loginUserApp() {
    const user = {
      email,
      password,
    };

    setLoading(true);
    try {
      if (user) {
        await api
          .post("/auth/login", user)
          .then(async (res) => {
            if (res.data.token) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("id", res.data.user.id);
              localStorage.setItem("name", res.data.user.name);
              localStorage.setItem("nickName", res.data.user.nickName);
              localStorage.setItem("email", res.data.user.email);
              localStorage.setItem("avatarUrl", res.data.user.avatarUrl);
              localStorage.setItem("plano", res.data.user.premium);

              setToken(res.data.token);
              setData(res.data.user);
              if (res.data.user.partner_id) {
                localStorage.setItem("partner_id", res.data.user.partner_id);
                await api
                  .get(`/partners/${res.data.user.partner_id}`, user)
                  .then((resp) => {
                    if (resp.status === 200) {
                      localStorage.setItem("avatarUrl", resp.data.thumbnail);
                    }
                  });
                setIsOpen(false);
                return (window.location.href = "/painel");
              } else {
                setIsOpen(false);
                // if (
                //   res.data.user.premium === "F" ||
                //   res.data.user.premium === "N"
                // )
                //   return (window.location.href = "/medita-bem-planos");
                return (window.location.href = "/minha-conta");
              }
            } else {
              localStorage.setItem("idActive", res.data.id);
              localStorage.setItem("emailActive", email);
              localStorage.setItem("passActive", password);
              return (window.location.href = "/cadastre-se");
            }
          })
          .catch((e) => {
            openModalErro(
              `LOGIN`,
              `Ops.. Seu e-mail ou senha incorreto(s).
              Por favor, tente novamente.`
            );
          });
      }
    } catch (e) {
      openModalErro(
        `LOGIN`,
        `Ops.. Algo deu errado.
        Por favor, tente novamente.`
      );
    } finally {
      setLoading(false);
    }
  }

  const classes = useStyles();

  return (
    <SectionMain>
      <ButtonRegister openModal={openModal} title="Login" />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Login"
        className={classes.modal}
      >
        <SectionMain className={classes.wrapper} noPadding>
          <SectionMain className={classes.modalText} position="center">
            <SectionMain className={classes.title}>
              <Title size="large" fontWeight="700" align="left">
                ENTRAR{" "}
              </Title>
            </SectionMain>
            <SectionMain className={classes.form} item xs={12} md={12}>
              <InputText
                onChange={({ target }) => {
                  setEmail(target.value);
                }}
                type="text"
                label="Login"
              />
              <InputText
                onChange={({ target }) => {
                  setPassword(target.value);
                }}
                type={viewSenha ? "text" : "password"}
                label="Senha"
                rigthElement={
                  <IconButton
                    size="large"
                    onClick={() => setViewSenha(!viewSenha)}
                  >
                    <Visibility
                      style={{
                        size: "100px",
                        color: "#bfbfbf",
                      }}
                    />
                  </IconButton>
                }
              />
              <Link
                to="/"
                onClick={recoverPasswordUser}
                className={classes.linksenha}
              >
                Esqueci minha senha
              </Link>
            </SectionMain>

            <SectionMain className={classes.container}>
              <ButtonMedita
                type="button"
                onClick={() => loginUserApp()}
                size="medium"
                className={classes.alignEnterButton}
                loading={loading}
              >
                Entrar
              </ButtonMedita>

              <Link
                to="cadastre-se"
                onClick={closeModal}
                className={classes.link}
              >
                Não tem conta? <u>clique aqui</u>
              </Link>
            </SectionMain>
          </SectionMain>
        </SectionMain>
      </Modal>

      <ModalFormResult
        isOpen={modalErroIsOpen}
        closeModal={closeModalErro}
        title={modalHeader}
        text={modalBody}
      ></ModalFormResult>
      <ModalForgotPassword
        isOpen={modalForgotPassword}
        onClose={closeModalFP}
        email={emailModal}
      ></ModalForgotPassword>
    </SectionMain>
  );
};

export default ModalLogin;
