import { makeStyles } from "@material-ui/core";
import React from "react";
import banner_meditacao from "../../../assets/images/banners/Banner_home.png";
import gplayBadge from "../../../assets/images/google-play-badge.png";
import ButtonMedita from "../../../components/ButtonMedita";
import FooterSection from "../../../components/FooterSection";
import SectionMain from "../../../components/SectionMain";
import Title from "../../../components/Title";
import VideoPlayer from "../../../components/VideoPlayer";
import VideosCard from "../../../components/VideosCard";
import useGoToPage from "../../../hooks/useGoToPage";
import useVideosArray from "../../../hooks/useVideosArray";
import useVideosArrayPremium from "../../../hooks/useVideosArrayPremium";
import { STYLE } from "./style";

const useStyles = makeStyles(STYLE);

const VideoPageDesktop = () => {
  const videos = useVideosArray().concat(useVideosArrayPremium());
  const goToPage = useGoToPage();

  const classes = useStyles();
  const id = window.location.search.split("?id=").pop();
  const video = videos.find((video) => video.id.includes(id));

  if (!video.partner) {
    video.partner = {};
  }

  const videosSameCategory = videos.filter(
    ({ category, id }) => category == video.category && id !== video.id
  );

  return (
    <SectionMain>
      <SectionMain className={classes.media}>
        <SectionMain item xs={6}>
          <VideoPlayer />
        </SectionMain>

        <SectionMain item xs={4}>
          <SectionMain className={classes.register}>
            <SectionMain position="flex-start" direction="column">
              <div className={classes.verticalCenter}>
                <SectionMain noPadding position="center">
                  <Title
                    align="center"
                    size="small"
                    fontWeight="800"
                    className={classes.experimente}
                  >
                    CONHEÇA O MEDITABEM
                  </Title>
                </SectionMain>

                <SectionMain>
                  <Title align="center" size="small">
                    Encontre centenas de áudios e vídeos: meditação, aulas de
                    yoga, mantras, exercícios, músicas, conteúdos para crianças
                    e muito mais. <br />
                    <br />
                    <br />
                    Baixe o app, teste grátis e comece a aproveitar agora.
                  </Title>
                </SectionMain>

                <ButtonMedita
                  onClick={() => goToPage("/medita-bem-planos")}
                  className={classes.btnModal}
                >
                  {"QUERO EXPERIMENTAR"}
                </ButtonMedita>
              </div>
            </SectionMain>
          </SectionMain>
        </SectionMain>
      </SectionMain>

      <SectionMain className={classes.container}>
        <SectionMain className={classes.title}>
          <Title
            fontWeight="800"
            size="medium"
            align="center"
            letterSpacing={2}
          >
            {"RELACIONADOS"}
          </Title>
        </SectionMain>
        <VideosCard position={"center"} videos={videosSameCategory} />
      </SectionMain>

      <SectionMain style={{ width: "95%" }}>
        <SectionMain item xs={6} className={classes.leftSectionCelular}>
          <SectionMain style={{ display: "block" }}>
            <img
              src={banner_meditacao}
              className={classes.imageLeftScreens}
              alt="Medita app frame mobile"
            />
          </SectionMain>
        </SectionMain>
        <SectionMain item xs={6} className={classes.rightSectionCelular}>
          <Title color="#fff" align="center" className={classes.txtMobile}>
            <Title
              color="#fff"
              align="center"
              fontWeight="700"
              className={classes.titleMobile}
            >
              <h2>Escolher o que faz bem.</h2>
            </Title>
            Tempo para você, para acalmar a mente e focar no que te deixa leve.
            <br />
            <br />
            Baixe o MeditaBem e venha fazer parte dessa deliciosa jornada rumo a
            uma vida mais leve, conectada e feliz.
            <br />
            <br />
            Estilos variados. Temas, professores e conteúdos exclusivos.
            <br />
            <br />
            Pronto para aproveitar um novo universo de meditação e bem-estar?
            <br />
            <br />
            <br />
            <div
              className="flex-row center-b"
              style={{ justifyContent: "center", padding: "15px 0" }}
            >
              <img
                src={gplayBadge}
                className={classes.badgeAppStores}
                onClick={() =>
                  goToPage(
                    "https://play.google.com/store/apps/details?id=com.newmeditabemapp"
                  )
                }
              />
            </div>
          </Title>
        </SectionMain>
      </SectionMain>

      <SectionMain>
        <FooterSection hideNewsletter={true} />
      </SectionMain>
    </SectionMain>
  );
};

export default VideoPageDesktop;
