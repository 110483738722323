import React, { useState } from "react";
import ButtonMedita from "../../../components/ButtonMedita";
import InputText from "../../../components/InputText";
import SectionMain from "../../../components/SectionMain";
import "./style.css";
import api from '../../../core/api';

const PartnersPanelChangePasswordDesktop = () => {
  const [state, setState] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleChangeInput = (field, value) => {
    state[field] = value;
    setState({ ...state });
  };

  const handleSubmit = () => {
		if(state.new_password != state.confirm_password) {
			alert('senhas não coincidem');
			return
		}

    api.put(`/users/${localStorage.getItem("id")}`, { password: state.new_password });
  };

  return (
    <SectionMain noPadding>
      <div className="input-painel">
        {/* 
        <InputText
          value={state.old_password}
          label="Senha atual"
          onChange={(e) => handleChangeInput("old_password", e.target.value)}
        />
				*/}
        <InputText
          value={state.new_password}
          label="Nova senha"
          onChange={(e) => handleChangeInput("new_password", e.target.value)}
        />
        <InputText
          value={state.confirm_password}
          label="Confirmar nova senha"
          onChange={(e) =>
            handleChangeInput("confirm_password", e.target.value)
          }
        />

        <div className="new-password-container">
          <ButtonMedita
            type="button"
            size="small"
            variant="text"
            className="button-cancel-new-password"
          >
            Cancelar
          </ButtonMedita>
          <ButtonMedita
            type="submit"
            size="small"
            variant="outlined"
            className="button-submit-new-password"
            onClick={() => handleSubmit()}
          >
            Salvar
          </ButtonMedita>
        </div>
      </div>
    </SectionMain>
  );
};

export default PartnersPanelChangePasswordDesktop;
