import React from 'react'
import TopMenuWrapper from './TopMenuWrapper'

const TopMenu = () => {
	return (
		<TopMenuWrapper/>
	)
}

export default TopMenu
