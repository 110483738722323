import { useAppContext } from '../store/AppContext'

const useCategoryButton = () => {
	const { setAppState } = useAppContext()

	const handleCategoryButton = (category, filterRegex) =>
		setAppState({ activeCategory: category, filter: filterRegex })

	return handleCategoryButton
}

export default useCategoryButton
