import React from "react";
import InputText from "../../InputText";
import { makeStyles } from "@material-ui/core";
import SectionMain from "../../SectionMain";
import Dropzone from "react-dropzone";
import Title from "../../Title";

const useStyles = makeStyles({
  messageInput: {
    borderRadius: "30px",
    border: "solid 1px #d3d3d3",
    height: 251,
  },
  inputMessage: {
    borderRadius: "30px 30px",
    borderTop: "solid 1px transparent",
  },
  dropzone: {
    background: "#f0f0f0",
    borderTop: "solid 1px #d3d3d3",
    borderRadius: "0 0 30px 30px",
    padding: "10px 20px",
    outline: "none",
    cursor: "pointer",
  },
});

const FormContactUI = ({
  keyObject,
  label,
  type,
  value,
  handleFormChange,
  errorMessage,
  mobile,
}) => {
  const classes = useStyles();

  switch (true) {
    case /message/.test(keyObject):
      return (
        <SectionMain noPadding className={classes.messageInput}>
          <InputText
            className={classes.inputMessage}
            id="description"
            multiline
            rows="5"
            errorColor="red"
            value={value}
            onChange={(e) => handleFormChange(e.target.value)}
            type={type}
            label={label}
            errorMessage={errorMessage}
          />
          {/* <Dropzone
						onDrop={(acceptedFiles) => {
							console.log(acceptedFiles[0])
						}}
					>
						{({ getRootProps, getInputProps }) => {
							return (
								<SectionMain {...getRootProps()} noPadding className={classes.dropzone}>
									<Title variant="secondary" align="left">
										Clique aqui para adicionar arquivos em JPG, PNG e PDF.
									</Title>

									<input {...getInputProps()} />
								</SectionMain>
							)
						}}
					</Dropzone> */}
        </SectionMain>
      );

    default:
      return (
        <InputText
          value={value}
          onChange={(e) => handleFormChange(e.target.value)}
          type={type}
          label={label}
          errorMessage={errorMessage}
        />
      );
  }
};

export default FormContactUI;
