export const STYLE = {
  container: { padding: '10px 2vw', justifyContent: 'center' },
  banner: {
    background: '#f8f9fa',
    height: 300,
    justifyContent: 'center',
    padding: '0 2vw',
    backgroundImage: 'url(https://site.medita.com.br/imgs/partner_desktop.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  bannerContent: {
    backgroundColor: '#ffffffbf',
    borderRadius: 15,
    margin: '3vw 20px',
    padding: '3vw 0px',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    width: 300,
    flexDirection: 'column',
    letterSpacing: 1,
    justifyContent: 'center',
  },
  cursive: {
    color: '#008393',
  },
  line: {
    width: '42%',
    height: '0.5px',
    backgroundColor: '#d8d8d8',
    border: 'solid 0.5px #d8d8d8',
  },
  bold: {
    fontWeight: '600',
  },
};
