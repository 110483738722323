import React, { useState } from "react";
import Modal from "react-modal";
import { IconButton, makeStyles } from "@material-ui/core";
import SectionMain from "../SectionMain";
import { Title, Visibility } from "@material-ui/icons";
import InputText from "../InputText";
import { Link } from "react-router-dom";
import ButtonMedita from "../ButtonMedita";

const useStyles = makeStyles({
  modal: {
    width: "400px",
    background: "#fff",
    margin: "40px auto",
    borderRadius: "20px",
    padding: "25px",
    textAlign: "center",
  },
  divider: {
    height: "1px",
    background: "#CDCED2",
    margin: "10px",
  },
  iosLikeButton: {
    background: "none",
    border: "none",
    display: "block",
    margin: "0 auto",
    color: "#0076FF",
    fontSize: "17px",
    fontWeight: "600",
  },
  modalTitle: {
    fontWeight: "600",
    margin: "10px",
  },
  p: {
    padding: 0,
    margin: 5,
    fontWeight: "bold",
    color: "#454545",
  },
});

const ModalRecoveryPass = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const [viewSenha, setViewSenha] = useState(false);
  const [viewConfirmSenha, setViewConfirmSenha] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Cadastro"
      className={classes.modal}
      shouldCloseOnOverlayClick={false}
      id="modal-pass"
    ></Modal>
  );
};

export default ModalRecoveryPass;
