import React from 'react';
import FormContactMinimalWrapper from './FormContactMinimalWrapper';
import FormContactMinimalUI from './FormContactMinimalUI';

const FormContactMinimal = ({
  mobile,
  typeFooter = 'client',
  buttonText,
  handleSubmitForm,
}) => {
  return (
    <FormContactMinimalWrapper typeFooter={typeFooter}>
      {({ goToPage, formValues, handleChangeInput, formikProps }) => (
        <React.Fragment>
          <FormContactMinimalUI
            typeFooter={typeFooter}
            goToPage={goToPage}
            handleChangeInput={handleChangeInput}
            handleSubmit={handleSubmitForm}
            formValues={formValues}
            mobile={mobile}
            buttonText={buttonText}
            formikProps={formikProps}
          />
        </React.Fragment>
      )}
    </FormContactMinimalWrapper>
  );
};

export default FormContactMinimal;
