import React from "react";
import { makeStyles } from "@material-ui/core";
import SectionMain from "../../../components/SectionMain";
import Form from "../../../components/StepperMobile/Form";

const useStyles = makeStyles({
  container: { padding: "20px 4vw", justifyContent: "center" },
});

const RegisterUserPageMobile = ({ formikProps }) => {
  const classes = useStyles();
  return (
    <SectionMain className={classes.container} position="center">
      <Form formikProps={formikProps} />
    </SectionMain>
  );
};

export default RegisterUserPageMobile;
