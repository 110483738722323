import { makeStyles } from "@material-ui/core";
import React from "react";
import ButtonMedita from "../../../components/ButtonMedita";
import ModalRegister from "../../../components/ModalRegister";
import SectionMain from "../../../components/SectionMain";
import Title from "../../../components/Title";
import VideosCard from "../../../components/VideosCard";
import useGoToPage from "../../../hooks/useGoToPage";
import useVideosArrayPremium from "../../../hooks/useVideosArrayPremium";

const useStyles = makeStyles({
  premiumSection: {
    background: "#5F7A77",
    padding: "20px 1vw",
    justifyContent: "center",
  },
  premiumText: {
    width: "60%",
    color: "#fff",
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 0 30px",
    paddingTop: 10,
  },
  text: {
    textRransform: "inherit",
  },
  modalTrigger: {
    display: "block",
    margin: "0 auto",
    width: "280px",
  },
  cta: {
    width: "280px !important",
    height: "46px !important",
    fontSize: "14px",
    margin: "20px auto",
    height: "41px !important",
  },
  link: {
    color: "#fff",
    margin: "20px auto",
    fontSize: "14px",
    fontWeight: 600,
  },
});

const PremiumSection = (mobile) => {
  const classes = useStyles();
  const goToPage = useGoToPage();
  const premiumVideos = useVideosArrayPremium();

  return (
    <SectionMain className={classes.premiumSection}>
      <SectionMain className={classes.premiumText} id="premium-section">
        <Title
          fontWeight="800"
          size="medium"
          align="center"
          letterSpacing={2}
          className={classes.text}
          color="#fff"
        >
          <h2>MeditaBem Premium</h2>
        </Title>
        <Title
          fontWeight="500"
          size="medium"
          align="center"
          className={classes.text}
          color="#fff"
        >
          Assinatura mensal e anual. Promoção de lançamento por tempo limitado.
          <br />
          A experiência de ter acesso a todos os conteúdos e novidades do
          MeditaBem, sempre.
          <br />
          Meditações, mantras, músicas, yoga, exercícios e terapias para cuidar
          da mente e do corpo, relaxar, melhorar o humor, reduzir a ansiedade e
          dormir bem.
        </Title>
        {/* <a
          href='https://play.google.com/store/apps/details?id=com.newmeditabemapp'
          className={classes.link}
        >
          {'VER MAIS SOBRE O PLANO >'}
        </a> */}
      </SectionMain>
      <VideosCard
        videos={premiumVideos}
        position="center"
        inLine={true}
        showIconAndAvatar={true}
      />

      <SectionMain className={classes.modalTrigger}>
        <ButtonMedita
          variant="outlined"
          onClick={() => goToPage("/medita-bem-planos")}
          className={classes.btnModal}
        >
          {"QUERO EXPERIMENTAR GRÁTIS"}
        </ButtonMedita>
      </SectionMain>
    </SectionMain>
  );
};

export default PremiumSection;
