import banner_celular from '../../../assets/images/banners/Banner_home_celular.png';

export const STYLE = {
  container: { padding: '20px 4vw', justifyContent: 'center' },
  center: {
    textAlign: 'center',
  },
  banner: {
    background: '#f8f9fa',
    height: 400,
    justifyContent: 'space-between',
    padding: '10px 6vw',
  },
  leftSection: { padding: '25px 0', maxHeight: 400 },
  rightSection: { justifyContent: 'center' },
  image: { width: '45vw', maxWidth: 400 },
  title: {
    width: 210,
    flexDirection: 'column',
    letterSpacing: 1,
    justifyContent: 'center',
    padding: '0 0 30px',
  },
  cursive: {
    color: '#008393',
    letterSpacing: 2,
    fontFamily: 'adobe-caslon-pro, serif',
    fontStyle: 'italic',
    fontSize: 38,
  },
  text: { flexDirection: 'column', alignItems: 'center', padding: '0 80px' },
  subText: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 195,
    padding: '20px 4vw',
  },

  criarContaBtn: {
    background: '#316570 !important',
    width: '240px !important',
    fontWeight: '400 !important',
  },
  divider: {
    width: '42%',
    height: '0.1px',
    backgroundColor: '#D4D4D4',
  },
  facebookBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '90% !important',
    color: '#282828 !important',
    background: '#EEEEEE !important',
    textAlign: 'center',
  },
  facebookText: {
    textTransform: 'initial',
    textAlign: 'center',
    margin: ' 0 auto',
    display: 'block',
  },
  rightSectionCelular: {
    justifyContent: 'center',
    backgroundImage: `url(${banner_celular})`,
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    padding: '20px 50px',
  },
  leftSectionCelular: {
    background: '#f8f9fb',
  },
  modal: {
    margin: '-16px 0',
    padding: '15px 20px',
    position: 'sticky',
    background: '#F9F9F9',
    height: '600px',
    justifyContent: 'center',
  },
  getAppText: {
    width: '40%',
    display: 'block',
    margin: '0 auto',
  },
  badgeAppStores: {
    maxWidth: '120px',
    maxHeight: '46px',
  },
  titleMobile: {
    fontSize: '20px',
  },
  link: {
    fontWeight: '700',
    color: '#fff',
  },
  cta: {
    width: '320px !important',
  },
  textForm: {
    fontSize: '16px',
  },
  displayCenter: {
    display: 'block',
    margin: '20px auto',
  },
  titleForm: {
    fontWeight: 800,
  },
};
