import React from 'react'

const AppContext = React.createContext({
	user: null,
	loading: false,
	video: { partner: '' },
	partnerForm: { name: '', email: '' },
	userForm: { name: '', email: '' },
	route: '/',
	filter: '',
	activeCategory: '',
	orientation: 'left',
	search: '',
	path: '',
	onReloadData: null,
	partners: false,
	drawerMenu: false,
	colorSchema: { primary: '#282828', secondary: '#666' },
	setAppState() {}
})

export const useAppContext = () => React.useContext(AppContext)

export default AppContext
