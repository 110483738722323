import { useHistory } from "react-router";

const useGoToPage = () => {
  const history = useHistory();

  const goPage = (link) => {
    if (link.includes("http")) {
      window.location = link;
    } else {
      if (link === "/") {
        window.location = link;
      } else {
        history.push(link);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  };

  return goPage;
};

export default useGoToPage;
