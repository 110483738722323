import React, { useState } from 'react'
import SectionMain from '../SectionMain'
import { FormControl, RadioGroup, FormControlLabel, Radio, makeStyles, withStyles } from '@material-ui/core'
import Title from '../Title'

const useStyles = makeStyles({
	root: {
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: 288
	}
})

const StyledLabel = withStyles({
	label: {
		fontSize: 14
	}
})(FormControlLabel)

const RateExperience = ({ alignItems = 'center' }) => {
	const classes = useStyles()
	const [ value, setValue ] = useState('')

	const handleChange = (event) => {
		setValue(event.target.value)
	}

	const align = {
		left: 'left',
		center: 'center'
	}

	return (
		<SectionMain noPadding position={align[alignItems]}>
			<SectionMain position="center" direction="column" className={classes.root}>
				<Title fontWeight="600" size="medium" align={align[alignItems]}>
					Ajude a melhorar sua experiência
				</Title>
				<Title align={align[alignItems]}>Você encontrou o que estava procurando?</Title>
				<FormControl component="fieldset" color="primary">
					<RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
						<StyledLabel id="form-label" value="yes" control={<Radio />} label="Sim" />
						<StyledLabel id="form-label" value="no" control={<Radio />} label="Não" />
						{/* <FormControlLabel value="contact" control={<Radio />} label="Quero entrar em contato" /> */}
					</RadioGroup>
				</FormControl>
			</SectionMain>
		</SectionMain>
	)
}

export default RateExperience
