import React, { useEffect, useState } from "react";
import "./style.css";
import ButtonMedita from "../ButtonMedita";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  TextField,
  makeStyles,
} from "@material-ui/core";
import api from "../../core/api";
import { useSnackbar } from "notistack";
import InputText from "../InputText";
import { Visibility } from "@material-ui/icons";
import useGoToPage from "../../hooks/useGoToPage";

const useStyles = makeStyles({
  p: {
    fontWeight: "600",
    marginTop: "15px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
  },
  pTitleSpan: {
    fontWeight: "600",
    marginBottom: 0,
    textAlign: "left",
    fontSize: 20,
  },
  pSpan: {
    fontWeight: "400",
    marginBottom: 0,
    textAlign: "left",
    color: (theme) => (theme.checked ? "#17E5DA" : ""),
  },
  Color: {
    color: "red",
  },
});

const PersonalDetails = ({ nextStep, setFormUser }) => {
  const goToPage = useGoToPage();
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const [name, setName] = useState("");
  const [nickName, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("**");
  const [confirmPassword, setConfirmPassword] = useState("**");
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [receiveNewsletter, setNewsletter] = useState(false);
  const [accept_terms, setTerms] = useState(false);

  const [validPass, setValidPass] = useState(false);
  const [loading, setLoading] = useState(false);

  const Continue = async () => {
    setLoading(true);

    if (!name || !email || !password) {
      snackbar("Adicione todas as informações");
      setLoading(false);
      return;
    }
    if (!accept_terms) {
      snackbar("É necessário aceitar os termos e condições de uso");
      setLoading(false);
      return;
    }
    if (validPass) {
      const form = {
        name,
        nickName,
        email: email.toLowerCase().trim(),
        password,
        receiveNewsletter,
        accept_terms,
      };
      try {
        const res = await api.post("/auth/registerWeb", form);
        if (res.status === 200) {
          setFormUser({
            email: res.data.user.email,
            password,
            id: res.data.user.id,
          });
          nextStep();
        }
      } catch (e) {
        snackbar("Ocorreu um erro ao cadastrar usuário");
        return false;
      } finally {
        setLoading(false);
      }
    } else {
      snackbar("Verifique todas as definições de senha");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      !!password &&
      password.length > 8 &&
      /\d/.test(password) &&
      /[$#@!]/.test(password) &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      password === confirmPassword
    ) {
      setValidPass(true);
    } else setValidPass(false);
  }, [password, confirmPassword]);

  useEffect(() => {
    setEmail(localStorage.getItem("emailTemp"));
    localStorage.removeItem("emailTemp");
  }, []);

  const classes = useStyles();
  return (
    <div
      style={{
        width: "590px",
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
    >
      <div className={classes.p}>
        <p className={classes.pTitleSpan}>
          Crie uma senha conforme as definições:
        </p>

        <p
          className={
            useStyles({ checked: !!password && password.length > 8 }).pSpan
          }
        >
          Possuir no mínimo 8 caracteres;{" "}
        </p>

        <p className={useStyles({ checked: /\d/.test(password) }).pSpan}>
          {" "}
          Possuir pelo menos um número;
        </p>

        <p className={useStyles({ checked: /[$#@!]/.test(password) }).pSpan}>
          Possuir pelo menos um caracter especial ($,#,@,!,etc);
        </p>

        <p className={useStyles({ checked: /[a-z]/.test(password) }).pSpan}>
          Possuir ao menos uma letra minúscula;
        </p>

        <p className={useStyles({ checked: /[A-Z]/.test(password) }).pSpan}>
          Possuir ao menos uma letra maiúscula.
        </p>

        <p
          className={useStyles({ checked: password === confirmPassword }).pSpan}
        >
          Confirmar senha.
        </p>
      </div>
      <InputText
        onChange={({ target }) => {
          setName(target.value);
        }}
        type="text"
        label="Nome"
        value={name}
      />

      <InputText
        onChange={({ target }) => {
          setNickName(target.value);
        }}
        type="text"
        label="Apelido"
        value={nickName}
      />

      <InputText
        onChange={({ target }) => {
          setEmail(target.value);
        }}
        type="text"
        label="Email"
        value={email}
      />

      <InputText
        onChange={({ target }) => {
          setPassword(target.value);
        }}
        type={viewPassword ? "text" : "password"}
        label="Senha"
        rigthElement={
          <IconButton
            size="large"
            onClick={() => setViewPassword(!viewPassword)}
          >
            <Visibility
              style={{
                size: "100px",
                color: "#bfbfbf",
              }}
            />
          </IconButton>
        }
      />
      <InputText
        onChange={({ target }) => {
          setConfirmPassword(target.value);
        }}
        type={viewConfirmPassword ? "text" : "password"}
        label="Confirmar Senha"
        rigthElement={
          <IconButton
            size="large"
            onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
          >
            <Visibility
              style={{
                size: "100px",
                color: "#bfbfbf",
              }}
            />
          </IconButton>
        }
      />
      <FormControlLabel
        label={
          <>
            Quero receber promoções personalizadas de parceiros comerciais da
            MeditaBem. Mais detalhes você encontra na{" "}
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                goToPage("/politica-de-privacidade");
              }}
            >
              Política de Privacidade
            </Link>
          </>
        }
        control={
          <Checkbox
            checked={receiveNewsletter}
            onChange={(event) => setNewsletter(event.target.checked)}
            inputProps={{ "aria-label": "Checkbox demo" }}
          />
        }
      />
      <FormControlLabel
        label={
          <>
            Li e aceito os{" "}
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                goToPage("/politica-de-privacidade");
              }}
            >
              termos e condições de uso
            </Link>
          </>
        }
        control={
          <Checkbox
            checked={accept_terms}
            onChange={(event) => setTerms(event.target.checked)}
            inputProps={{ "aria-label": "Checkbox demo" }}
          />
        }
      />
      <div style={{ textAlign: "center", width: "100%", marginTop: 10 }}>
        <ButtonMedita
          loading={loading}
          disabled={loading}
          type="button"
          onClick={Continue}
          size="large"
          // className={classes.submit}
        >
          Próximo
        </ButtonMedita>
      </div>
    </div>
  );
};

export default PersonalDetails;
