/* eslint-disable */
import { Link, makeStyles } from "@material-ui/core";
import React from "react";
import useGoToPage from "../../../../../../hooks/useGoToPage";
import SectionMain from "../../../../../../components/SectionMain";
import ButtonMedita from "../../../../../../components/ButtonMedita";
import logo from "../../../../../../assets/images/logo.png";
import selo from "../../../../../../assets/images/home/selo_medita.png";

const useStyles = makeStyles({
  root: { padding: "0 2vw 100px", width: "100%", marginTop: "50px" },
  link: {
    display: "flex",
    color: "#282828",
    textDecorationLine: "none",
    width: "100%",
    lineHeight: 2,
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Century Gothic",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
  },
  linksContainerTop: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  linksContainer: {
    flexDirection: "column",
    padding: 10,
  },
  center: {
    margin: "0 auto",
    display: "block",
  },
  subLink: {
    padding: "0 10px",
    color: "#282828",
    fontSize: 14,
    textDecorationLine: "none",
    lineHeight: 2,
    alignItems: "center",
  },
  fixedbtn: {
    position: "fixed",
    bottom: "0",
    display: "block",
    margin: "20px auto",
    textTransform: "inherit",
    width: "247px !important",
    zIndex: 9999,
    left: "calc(50% - 123.5px)",
  },
  title: {
    fontFamily: "OpenSans-Bold",
    fontSize: "18px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
  },
  linksWrapper: {
    justifyContent: "center",
  },
  description: {
    fontFamily: "Century Gothic",
    fontFize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
  },
  descriptionTop: {
    fontFamily: "Century Gothic",
    fontFize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#5a7184",
    width: "80%",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: "auto",
    alignSelf: "center",
    padding: 10,
    marginTop: "20px",
  },
  textFooter: {
    fontFamily: "Century Gothic",
    fontFize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#5a7184",
  },
});

const FooterSectionLinks = () => {
  const classes = useStyles();
  const goToPage = useGoToPage();

  const links = [
    {
      title: "Experimente",
      routes: [
        {
          label: "Meditações",
          link: "/experimente?meditacoes",
        },
        {
          label: "Yoga",
          link: "/experimente?yoga",
        },
        {
          label: "Exercícios",
          link: "/experimente?exercicios",
        },
        {
          label: "Mantras",
          link: "/experimente?mantras",
        },
      ],
    },

    {
      title: "Parceiros",
      routes: [
        {
          label: "Seja um Parceiro",
          link: "/parceiros",
        },
      ],
    },

    {
      title: "Contato",
      routes: [
        {
          label: "Entre em contato",
          link: "/contato",
        },
        {
          label: "Parceria",
          link: "/parceiros",
        },
      ],
    },
    {
      title: "Comunidade",
      routes: [
        // {
        //   label: "Sobre nós",
        //   link: "/sobre-nos",
        // },
        {
          label: "Termos de uso e privacidade",
          link: "/politica-de-privacidade",
        },
      ],
    },

    // {
    //   title: 'Conheça o app',
    //   routes: [
    //     {
    //       label: 'Download',
    //       link:
    //         'https://play.google.com/store/apps/details?id=com.newmeditabemapp',
    //     },
    //     {
    //       label: 'Assinaturas',
    //       link:
    //         'https://play.google.com/store/apps/details?id=com.newmeditabemapp',
    //     },
    //     {
    //       label: 'Benefícios',
    //       link:
    //         'https://play.google.com/store/apps/details?id=com.newmeditabemapp',
    //     },
    //   ],
    // },
  ];

  return (
    <>
      <SectionMain className={classes.root}>
        <SectionMain noPadding className={classes.linksWrapper}>
          <SectionMain className={classes.linksContainerTop}>
            <img
              alt="MeditaBem"
              src={logo}
              style={{
                width: "200px",
                height: "auto",
              }}
            />
            <p className={classes.descriptionTop}>
              Cuide do seu bem-estar com meditação, yoga, músicas, mantras,
              exercícios e muito mais.
            </p>
          </SectionMain>
          {links.map(({ title, routes }, index) => {
            return (
              <SectionMain
                key={`title-link-${title}`}
                xs={index === links.length ? 12 : 6}
                md={2}
                className={classes.linksContainer}
              >
                <p className={classes.title}>{title}</p>
                <ButtonMedita
                  variant="outlined"
                  className={classes.fixedbtn}
                  onClick={() => goToPage("/medita-bem-planos")}
                >
                  Baixe o MeditaBem
                </ButtonMedita>
                {routes.map(({ label, link, newPage }) =>
                  newPage ? (
                    <a href={link} target="_blank" className={classes.link}>
                      {label}
                    </a>
                  ) : (
                    <Link
                      key={`title-link-${link}`}
                      className={classes.link}
                      onClick={() => {
                        goToPage(link);
                      }}
                    >
                      {label}
                    </Link>
                  )
                )}
              </SectionMain>
            );
          })}
        </SectionMain>
      </SectionMain>
      <SectionMain className={classes.footer}>
        <img alt="MeditaBem" src={selo} />
        <p className={classes.textFooter}>
          Copyright © 2024. Criado com carinho para você.
        </p>
      </SectionMain>
    </>
  );
};

export default FooterSectionLinks;
