import { makeStyles } from '@material-ui/core';
import React from 'react';
import BePartner from '../../../components/BePartner';
import ButtonMedita from '../../../components/ButtonMedita';
import FooterSection from '../../../components/FooterSection';
import FormPartner from '../../../components/FormPartner';
import SectionMain from '../../../components/SectionMain';
import { STYLE } from './style';

const useStyles = makeStyles(STYLE);

const RegisterPartnerPageDesktop = ({ formikProps }) => {
  const classes = useStyles();

  return (
    <SectionMain noPadding position='center'>
      <BePartner></BePartner>

      <SectionMain className={classes.container}>
        <FormPartner formikProps={formikProps} />
      </SectionMain>

      <SectionMain className={classes.container}>
        <ButtonMedita
          onClick={formikProps.handleSubmit}
          size='medium'
          className={classes.button}
        >
          PRONTO, ENVIAR
        </ButtonMedita>
      </SectionMain>

      <SectionMain>
        <FooterSection hideNewsletter/>
      </SectionMain>
    </SectionMain>
  );
};

export default RegisterPartnerPageDesktop;
