export const STYLE = {
  container: { padding: '10px 20px' },
  banner: {
    background: '#f8f9fa',
    height: 350,
    justifyContent: 'center',
    padding: '10px 0px',
    backgroundImage:
      'url(https://site.medita.com.br/imgs/banner_partner_mobile.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  bannerContent: {
    backgroundColor: '#ffffffbf',
    margin: '20px 0',
    padding: '20px 0px',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
  },
  subBanner: {
    justifyContent: 'center',
    paddingBottom: 80,
  },
  subBannerContent: {
    padding: '20px 2vw',
    background: '#ffc864',
    height: '250px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subBannerTitle: {
    padding: '0px 0px 20px',
  },
  image: {
    width: '100%',
    maxHeight: 350,
    maxWidth: 350,
    height: '100%',
    borderRadius: 30,
  },
  title: {
    flexDirection: 'column',
    letterSpacing: 1,
    justifyContent: 'center',
    padding: '0 0 30px',
    paddingTop: 10,
  },
  cursive: {
    color: '#008393',
  },
  inputs: {
    width: 310,
    height: 140,
    padding: '10px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
  },

  image: { height: 200, display: 'block', margin: '0 auto' },
  inputs: {
    height: 160,
    padding: '10px 0px',
    alignItems: 'center',
    display: 'contents',
  },
  section: {
    justifyContent: 'center',
    width: '100%',
  },
  modal: {
    margin: '40px 0 10px',
    padding: '40px 20px',
    position: 'sticky',
    background: '#F9F9F9',
    height: '600px',
    justifyContent: 'center',
  },

  titleMobile: {
    fontSize: '24px',
  },
  formBlock: {
    width: '100% !important',
    display: 'block',
  },
};
