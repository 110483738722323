import React, { useState, useEffect } from "react";
import FooterSection from "../../components/FooterSection";
import SectionMain from "../../components/SectionMain";
import PartnersPanelChangePasswordDesktop from "./change_password";
import PartnersPanelComunityDesktop from "./comunity";
import PartnersPanelDataDesktop from "./my_panel";
import PartnersPanelProfileDesktop from "./my_profile";

import api from "../../core/api";

import "./style.css";

const PartnersPanelDesktop = () => {
  const [state, setState] = useState({
    panel: <PartnersPanelDataDesktop />,
    activeButton: "my_panel",
  });
  const [name, setName] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    const tokenVerify = localStorage.getItem("token");
    const partner = localStorage.getItem("partner_id");
    if (!tokenVerify || !partner) window.location.href = "/";
  }, []);

  useEffect(() => {
    setName(localStorage.getItem("name"));
    setAvatarUrl(localStorage.getItem("avatarUrl"));
  }, []);

  return (
    <SectionMain noPadding>
      <div className="textos">
        <p>
          <b>Olá, {name}</b>
        </p>
        <p>
          Essa é sua área exclusiva do MeditaBem! <br />
          Edite seu perfil e tenha acesso as informações e métricas dos seus
          conteúdos.
        </p>
      </div>
      <div className="botoes">
        <button
          className={[
            state.activeButton === "my_panel" ? "active" : "",
            "my_panel",
          ].join(" ")}
          onClick={() =>
            setState({
              panel: <PartnersPanelDataDesktop />,
              activeButton: "my_panel",
            })
          }
        >
          Meu MeditaBem
        </button>
        {localStorage.getItem("plano") !== "S" &&
        localStorage.getItem("plano") !== "G" ? (
          <>
            {" "}
            <button
              className={[
                state.activeButton === "comunity" ? "active" : "",
                "comunity",
              ].join(" ")}
              onClick={() =>
                setState({
                  panel: <PartnersPanelComunityDesktop />,
                  activeButton: "comunity",
                })
              }
            >
              Comunidade
            </button>
            <button
              className={[
                state.activeButton === "my_profile" ? "active" : "",
                "my_profile",
              ].join(" ")}
              onClick={() =>
                setState({
                  panel: <PartnersPanelProfileDesktop />,
                  activeButton: "my_profile",
                })
              }
            >
              Meu perfil
            </button>
          </>
        ) : null}

        {localStorage.getItem("plano") !== "S" &&
        localStorage.getItem("plano") !== "G" ? (
          <button
            className={[
              state.activeButton === "change_password" ? "active" : "",
              "change_password",
            ].join(" ")}
            onClick={() =>
              setState({
                panel: <PartnersPanelChangePasswordDesktop />,
                activeButton: "change_password",
              })
            }
          >
            Alterar senha
          </button>
        ) : null}
      </div>

      <div class="image-container">
        <div class="image-cropper">
          <img
            src={
              avatarUrl != "undefined" || undefined
                ? avatarUrl
                : "https://avatars.dicebear.com/api/micah/user.svg?backgroundColor=black"
            }
            alt="avatar"
            class="profile-pic"
            width={"auto"}
          />
        </div>

        <p>{name}</p>
      </div>

      {state.panel}

      <SectionMain>
        <FooterSection hideNewsletter={localStorage.getItem("partner_id")} />
      </SectionMain>
    </SectionMain>
  );
};

export default PartnersPanelDesktop;
