import { useState, useEffect } from 'react';
import { arrayVideos } from '../core/arrayVideos';
import fetchPartnersData from '../core/fetchPartners';

const useVideosArray = () => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    function fetchData() {
      fetchPartnersData().then((partners) => setPartners(partners));
    }
    fetchData();
  }, []);

  return arrayVideos.map((video) => {
    const [partner] = partners.filter((partner) =>
      (partner.artisticName || '')
        .toLowerCase()
        .match(video.partnerName.toLowerCase())
    );
    return { ...video, partner };
  });
};

export default useVideosArray;
