import React, { useCallback, useEffect, useState } from "react";
import "./style.css";
import "./styleCard.css";
import { CircularProgress, Divider, makeStyles } from "@material-ui/core";
import code from "../../assets/images/code.png";
import android from "../../assets/images/logo_playstore.png";
import banner_first from "../../assets/images/banners/banner_home_top.png";
import apple from "../../assets/images/logo_applestore.png";
import pending from "../../assets/icons/pending.png";
import check from "../../assets/icons/check.png";
import error from "../../assets/icons/error.png";
import Title from "../Title";
import { format, parseISO } from "date-fns";

import ButtonMedita from "../ButtonMedita";
import useGoToPage from "../../hooks/useGoToPage";
import {
  amex_brand,
  card,
  diners_brand,
  maestro_brand,
  mastercard_brand,
  unionpay_brand,
  visa_brand,
  discover_brand,
  jcb_brand,
} from "../../assets/icons/svgs";
import api from "../../core/api";

const useStyles = makeStyles({
  container: {
    width: "100%",
    margin: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    fontWeight: "600",
    color: "#030102",
    fontSize: "25px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  pTitle: {
    fontWeight: "600",
    color: "#030102",
    fontSize: "30px",
  },
  pBody: {
    margin: 0,
    fontWeight: "400",
    color: "#030102",
    fontSize: "25px",
  },
  pInfos: {
    fontWeight: "600",
    color: "#030102",
    fontSize: "25px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  pDesc: {
    fontWeight: "400",
    color: "#030102",
    fontSize: "25px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  pDescfinish: {
    fontWeight: "400",
    color: "#030102",
    fontSize: "15px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  row: {
    width: "100%",
    fontWeight: "600",
    marginTop: "15px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "red",
  },

  CreditCard: {
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: "10px",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontSize: "40px",
  },
  stepCard: {
    display: "flex",
    marginTop: "15px",
  },
  stepLogo: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  },
  stepButton: {
    width: 25,
    height: 25,
    marginRight: "10px",
    display: "flex",
    padding: 10,
    fontSize: "1em",
    alignItems: "center",
    borderRadius: "100%",
    justifyContent: "center",
    backgroundColor: "blue",
    color: "#fff",
  },
  stepText: {
    fontWeight: "600",
    color: "#030102",
    fontSize: 18,
  },
  stepTotal: {
    fontWeight: "700",
    color: "#030102",
    fontSize: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
});

const statusPaymentInfos = [
  {
    color: "#F1A431",
    icon: pending,
    title: "Transação em Processamento",
    text: "Por favor, aguarde a confirmação!",
    status: "PENDENTE",
  },
  {
    color: "#F14831",
    icon: error,
    title: "Pagamento Recusado",
    text: "Por favor, entre em contato com seu cartão de crédito!",
    status: "RECUSADO",
  },
  {
    color: "#34A853",
    icon: check,
    title: "Obrigado!",
    text: "Sua assinatura foi confirmada!",
    status: "PAGO",
  },
];

const SuccessCard = ({ prevStep, nextStep, stepFree, subsResponse }) => {
  const goToPage = useGoToPage();
  const [formStep, setFormStep] = useState(stepFree || 0);
  const [dataSubs, setDataSubs] = useState(null);
  const [price, setPrice] = useState("");
  const [statusPayment, setPayment] = useState(statusPaymentInfos[0]);
  const [credImg, setCredImg] = useState(null);
  const [loading, setLoading] = useState(null);
  const id = localStorage.getItem("id");

  const GetUser = async () => {
    const { data } = await api.get(`users/${id}`);
    if (subsResponse) {
      FormDataAsaas(subsResponse);
    } else if (data.dataAsaas.value) {
      FormDataAsaas(data.dataAsaas);
    }
  };

  useEffect(() => {
    GetUser();
  }, []);

  const updPlans = useCallback(async (data) => {
    setInterval(async () => {
      if (statusPayment?.status !== "CONFIRMED") {
        await api
          .post(`subscriptions/asaas/${id}`, { subscription: data.id })
          .then(async (response) => {
            let planActiveUser = null;
            if (response.data.userSubsId) {
              try {
                const result = JSON.parse(response.data.userSubsId);
                const planBody = result.filter((r) => r.status === "PENDING");
                planActiveUser = planBody[planBody.length - 1].id;
              } catch (error) {}
            }

            if (
              response.data.payStatus === "CONFIRMED" ||
              planActiveUser === data.id
            ) {
              setLoading(true);
              setPayment(statusPaymentInfos[2]);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.error("err", err);
          });
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (dataSubs) updPlans(dataSubs);
  }, [dataSubs]);

  const FormDataAsaas = (dados) => {
    if (dados) {
      if (
        dados.value &&
        dados.value?.toString()?.replace(".", ",")?.split(",")[1].length < 2
      ) {
        setPrice(`${dados.value?.toString().replace(".", ",")}0`);
      } else {
        setPrice(dados.value?.toString().replace(".", ","));
      }

      const dadosSave = {
        name: dados.cycle === "MONTHLY" ? "Plano Mensal" : "Plano Anual",
        plan: dados.cycle === "MONTHLY" ? "Medita.Bem+" : "Medita.Sempre",
        dateCreated: format(
          parseISO(dados.dueDate || dados.dateCreated),
          "dd/MM/yyyy"
        ),
        nextDueDate: format(parseISO(dados.nextDueDate), "dd/MM/yyyy"),
        totally: dados.value,
        cardNumber: dados.creditCard?.creditCardNumber,
        cardBrand: dados.creditCard?.creditCardBrand,
        billingType: dados.billingType,
        status: dados.status,
        id: dados.id,
      };
      setDataSubs(dadosSave);

      if (dados.creditCard?.creditCardBrand) {
        switch (dados.creditCard?.creditCardBrand?.toLowerCase()) {
          case "american express":
            setCredImg(amex_brand);
            break;
          case "visa":
            setCredImg(visa_brand);
            break;
          case "diners":
            setCredImg(diners_brand);
            break;
          case "discover":
            setCredImg(discover_brand);
            break;
          case "jcb" || "jcb15":
            setCredImg(jcb_brand);
            break;
          case "maestro":
            setCredImg(maestro_brand);
            break;
          case "mastercard":
            setCredImg(mastercard_brand);
            break;
          case "unionpay":
            setCredImg(unionpay_brand);
            break;
          default:
            setCredImg(card);
            break;
        }
      }
    }
  };

  useEffect(() => {
    FormDataAsaas(subsResponse);
  }, [subsResponse]);

  const classes = useStyles();

  return (
    <div className="form" style={{ width: "100%" }}>
      <div>
        {formStep === 0 ? (
          <div
            className="form"
            style={{
              width: "700px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "75px",
                  height: "75px",
                  backgroundColor: statusPayment?.color || "#F1A431",
                  borderRadius: "100%",
                  border: "solid 10px #EDEDED",
                  zIndex: 999,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  width={"50%"}
                  height={"50%"}
                  src={statusPayment?.icon || pending}
                  alt="Logo Status"
                />
              </div>{" "}
            </div>

            <div
              style={{
                width: "75%",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                marginTop: "-40px",
                padding: 15,
                borderTopRightRadius: 15,
                borderTopLeftRadius: 15,
                backgroundColor: "#EDEDED",
              }}
              className="bodySuccessTop"
            >
              {dataSubs ? (
                <>
                  <p className={classes.pTitle}>
                    {statusPayment?.title}
                    <p className={classes.pBody}>{statusPayment?.text}</p>
                  </p>
                  <div>
                    {" "}
                    <p className={classes.pInfos}>{dataSubs.name}</p>
                    <div className={classes.container}>
                      <span className={classes.pDesc}>Plano</span>
                      <span className={classes.pInfos}>{dataSubs.plan}</span>
                    </div>
                    <div className={classes.container}>
                      <span className={classes.pDesc}>Início</span>
                      <span className={classes.pInfos}>
                        {dataSubs.dateCreated}
                      </span>
                    </div>
                    <div className={classes.container}>
                      <span className={classes.pDesc}>Renova em</span>
                      <span className={classes.pInfos}>
                        {dataSubs.nextDueDate}
                      </span>
                    </div>
                    <Divider
                      orientation="horizontal"
                      color="red"
                      width="100%"
                    />
                    <div className={classes.container}>
                      <span className={classes.pInfos}>Total</span>
                      <span className={classes.pInfos}>R$ {price} </span>
                    </div>
                    {dataSubs.cardNumber && (
                      <div
                        style={{
                          height: 70,
                          backgroundColor: "#fff",
                          padding: 10,
                          borderRadius: 20,
                          alignSelf: "center",
                          margin: 15,
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div id="ccicon">
                          <img
                            width={80}
                            height={"auto"}
                            src={credImg}
                            alt="Logo Status"
                          />
                        </div>

                        <div>
                          <Title size="large" fontWeight="700" align="left">
                            {dataSubs.billingType}
                          </Title>
                          <Title size="large" fontWeight="700" align="left">
                            {dataSubs.cardBrand} - {dataSubs.cardNumber}
                          </Title>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <p className={classes.pTitle}>Aguarde...</p>{" "}
                  <p className={classes.pTitle}>
                    <CircularProgress />
                  </p>
                </>
              )}
            </div>
            {(dataSubs || !loading) && (
              <>
                {" "}
                <div
                  style={{
                    width: "75%",
                    height: 150,
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                    padding: 15,
                    borderTop: "1px dashed #B8B8B8",
                    backgroundColor: "#EDEDED",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="bodySuccessBot"
                >
                  <span className={classes.pInfos}>
                    <img
                      width={135}
                      src={code}
                      alt="Código de barras"
                      style={{ padding: "20px" }}
                    />
                  </span>
                  <span className={classes.pInfos}>
                    <div
                      style={{
                        border: `3px solid ${statusPayment?.color}`,
                        color: statusPayment?.color,
                        borderRadius: "10px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "40px",
                      }}
                    >
                      {" "}
                      {statusPayment?.status}
                    </div>
                  </span>
                </div>
                <ButtonMedita
                  type="button"
                  onClick={() => setFormStep(1)}
                  size="medium"
                  style={{ marginTop: 15 }}
                >
                  Vamos praticar?
                </ButtonMedita>
              </>
            )}
          </div>
        ) : (
          <div>
            <Title size="xbig" fontWeight="700" color="#424242" align="left">
              VAMOS PRATICAR?
            </Title>
            <Title size="large" fontWeight="400" color="#424242" align="left">
              Para começar a usar o sistema MeditaBem você deve seguir as etapas
              abaixo!
            </Title>

            <div className={classes.stepCard}>
              <div className={classes.line}>
                <div className={classes.stepCard}>
                  <div className={classes.stepButton}>1</div>
                  <div>
                    <span className={classes.pDescfinish}>Etapa 1</span>
                    <span className={classes.pDescfinish}>
                      Baixe o aplicativo no seu celular através das lojas abaixo
                    </span>
                  </div>
                </div>
                <div className={classes.stepLogo}>
                  <img
                    width={75}
                    src={android}
                    alt="Logo Play Store"
                    style={{ padding: "20px", marginTop: "15px" }}
                  />
                  <div>
                    <span className={classes.stepText}>ANDROID</span>
                    <span className={classes.pDescfinish}>
                      <ButtonMedita
                        backgroundColor="#316570"
                        width={150}
                        onClick={() =>
                          goToPage(
                            "https://play.google.com/store/apps/details?id=com.newmeditabemapp"
                          )
                        }
                      >
                        CLIQUE AQUI
                      </ButtonMedita>
                    </span>
                  </div>
                </div>
                <div className={classes.stepLogo}>
                  <img
                    width={75}
                    src={apple}
                    alt="Logo Apple Store"
                    style={{ padding: "20px", marginTop: "15px" }}
                  />
                  <div>
                    <span className={classes.stepText}>IOS</span>
                    <span className={classes.pDescfinish}>
                      <ButtonMedita
                        backgroundColor="#316570"
                        width={150}
                        onClick={() =>
                          goToPage(
                            "https://apps.apple.com/br/app/meditabem/id1533068675?l=en"
                          )
                        }
                      >
                        CLIQUE AQUI
                      </ButtonMedita>
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.line}>
                <div className={classes.stepCard}>
                  <div className={classes.stepButton}>2</div>
                  <div>
                    <span className={classes.pDescfinish}>Etapa 2</span>
                    <span className={classes.pDescfinish}>
                      Após baixar o aplicativo basta fazer o login e desfrutar
                      de todos os recursos do Medita.Bem
                    </span>
                  </div>
                </div>
                <div className={classes.stepLogo}>
                  <img
                    width={400}
                    src={banner_first}
                    alt="Logo Apple Store"
                    style={{ padding: "20px", marginTop: "15px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessCard;
