import { Avatar, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import celular from "../../../../assets/images/home/celular-embaixo.png";
import SectionMain from "../../../../components/SectionMain";

const useStyles = makeStyles({
  containerThree: {
    background: "linear-gradient(-180deg, #6eb64b 0.00%, #009daa 100.00%)",
    // clipPath: "ellipse(100% 50%)",
    borderRadius: "0% 0% 150% 150%/0% 0% 40% 40%",
    paddingBottom: "10%",
  },
  leftSectionThree: {
    height: "100%",
    maxWidth: "80%",
    margin: "auto",
    marginTop: 50,
  },
  viewCenter: { width: "88%", margin: "auto", marginBottom: 50 },
  titleSectionThree: {
    fontFamily: "Century Gothic",
    fontSize: "32px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#ffffff",
  },
  descriptionThree: {
    fontFamily: "Century Gothic",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#ffffff",
  },
  divOne: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  divTwo: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  divThree: {
    fontFamily: "Century Gothic",
    fontSize: "25px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "right",
    alignSelf: "flex-end",
    color: "#fafafb",
    height: "30%",
    width: "80%",
  },
  divFour: {
    width: "100%",
    height: "20px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  divFive: {
    width: "10px",
    height: "10px",
    borderRadius: "4px",
    backgroundColor: "#faad13",
    marginBottom: 10,
  },
  size: {
    fontSize: "19px",
  },
  divRTwo: {
    fontFamily: "Century Gothic",
    fontSize: "25px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    alignSelf: "flex-start",
    color: "#fafafb",
    height: "30%",
    width: "80%",
  },
  divRThree: {
    width: "100%",
    height: "20px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  divRFour: {
    width: "10px",
    height: "10px",
    borderRadius: "4px",
    backgroundColor: "#faad13",
    marginBottom: 10,
  },
  imageThree: {
    width: "33%",
  },
});

const data = [
  {
    title: "Meditações",
    description:
      "Meditações guiadas por professores para acalmar a mente e desenvolver mais foco, equilíbrio e gratidão.",
  },
  {
    title: "MiniMedita",
    description:
      "Meditações, exercícios de yoga para crianças e músicas que ajudam a relaxar e dormir bem.",
  },
  {
    title: "Yoga",
    description:
      "Práticas leves e fáceis de fazer, conduzidas por professores de diversas linhas de Yoga.",
  },
  {
    title: "Mantras",
    description:
      "Afirmações poderosas, mantras e orações para o dia a dia, unindo mente e espírito.",
  },
  {
    title: "Músicas",
    description:
      "Relaxe ou energize-se com sons da natureza e músicas que equilibram seu corpo e mente.",
  },
  {
    title: "Exercícios",
    description:
      "Respiração, alongamento, pilates e muito mais para tonificar o corpo e melhorar sua saúde.",
  },
];

const SectionThree = () => {
  const classes = useStyles();

  return (
    <SectionMain noPadding className={classes.containerThree}>
      <SectionMain noPadding item className={classes.leftSectionThree}>
        <div className={classes.viewCenter}>
          <div className={classes.titleSectionThree}>
            Encontre alívio e bem-estar com o MeditaBem
            <br />
            <div style={{ width: "57%", margin: "auto" }}>
              <span className={classes.descriptionThree}>
                Estresse e mente sobrecarregada? Conheça as modalidades voltadas
                para o seu bem-estar e escolha as práticas que mais combinam com
                você e o seu estilo de vida.
              </span>
            </div>
          </div>
        </div>
        <div className={classes.divOne}>
          <div className={classes.divTwo}>
            {data.slice(0, 3).map((d) => (
              <div className={classes.divThree}>
                <div className={classes.divFour}>
                  <div className={classes.divFive} />
                </div>
                {d.title}
                <br />
                <span className={classes.size}>{d.description}</span>
              </div>
            ))}
          </div>

          <img
            src={celular}
            className={classes.imageThree}
            alt="Medita app celular"
          />

          <div className={classes.divTwo}>
            {data.slice(3, 6).map((d) => (
              <div className={classes.divRTwo}>
                <div className={classes.divRThree}>
                  <div className={classes.divRFour} />
                </div>
                {d.title}
                <br />
                <span className={classes.size}>{d.description}</span>
              </div>
            ))}
          </div>
        </div>
      </SectionMain>
    </SectionMain>
  );
};

export default SectionThree;
