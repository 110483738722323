export const colorTitle = (title) => {
  switch (title) {
    case "Meditações":
      return "#2f676e";
    case "MiniMedita":
      return "#fc8697";
    case "Yoga":
      return "#feaa16";
    case "Mantras":
      return "#fed904";
    case "Músicas":
      return "#37b0ed";
    case "Exercícios":
      return "#4dc03c";
    default:
      return "#282828";
  }
};
