import React, { useContext } from "react";
import { Hidden } from "@material-ui/core";
import SectionMain from "../../components/SectionMain";
import RegisterUserPageDesktop from "./desktop";
import RegisterUserPageMobile from "./mobile";
import { Formik } from "formik";
import { string, object } from "yup"; // for only what you need
import api from "../../core/api";
import ModalFormResult from "../../components/ModalFormResult";
import useGoToPage from "../../hooks/useGoToPage";
import { AuthContext } from "../../store/AuthContext";

const RegisterUserPage = () => {
  const validationSchema = () =>
    object().shape({
      name: string().required("Preencha o campo nome"),
      email: string()
        .email("Digite um e-mail válido")
        .required("Preencha o campo de e-mail"),
      password: string().required("Preencha o campo senha"),
    });

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("");
  const [modalBody, setModalBody] = React.useState("");
  const goToPage = useGoToPage();

  const openModal = (header, body) => {
    setIsOpen(true);
    setModalHeader(header);
    setModalBody(body);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const { setToken, setData } = useContext(AuthContext);

  const onSubmitForm = async (values) => {
    const form = { ...values, email: values.email.toLowerCase() };

    try {
      await api.post("/auth/register", form).then(async (res) => {
        if (res.status === 200) {
          const login = {
            email: values.email.toLowerCase(),
            password: values.password,
          };

          const responseLogin = await api.post("/auth/login", login);
          localStorage.setItem("token", responseLogin.data.token);
          localStorage.setItem("id", responseLogin.data.user.id);
          localStorage.setItem("name", responseLogin.data.user.name);
          localStorage.setItem("nickName", responseLogin.data.user.nickName);
          localStorage.setItem("email", responseLogin.data.user.email);
          localStorage.setItem("plano", responseLogin.data.user.premium);

          setToken(responseLogin.data.token);
          setData(responseLogin.data.user);

          // openModal(
          //   "Sucesso",
          //   "O seu cadastro foi realizado com sucesso ✅ 🧘‍♀️"
          // );
        }
      });
      return true;
    } catch (e) {
      console.error({ e });
      openModal(`Erro ${e.response.status}`, e.response.data.message);
      return false;
    }
  };

  return (
    <SectionMain noPadding position="center">
      <Hidden only={["xs", "sm"]}>
        <RegisterUserPageDesktop />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <RegisterUserPageMobile />
      </Hidden>
    </SectionMain>
  );
};

export default RegisterUserPage;
