import { format } from 'date-fns'
//import { ptBr } from 'date-fns/locale'

export const getToday = () => {
	return format(new Date(), "yyyy'-'MM'-'dd")
}

export const lastSevenDays = () => {
	return format(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), "yyyy'-'MM'-'dd")
}

export const lastThirtyDays = () => {
	return format(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), "yyyy'-'MM'-'dd")
}

export const lastNinetyDays = () => {
	return format(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000), "yyyy'-'MM'-'dd")
}

export const lastYearDays = () => {
	return format(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000), "yyyy'-'MM'-'dd")
}
