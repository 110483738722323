import { IconButton, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import banner from "../../../../assets/images/home/foto_news.png";
import SectionMain from "../../../../components/SectionMain";
import ButtonMedita from "../../../../components/ButtonMedita";
import { ArrowForward } from "@material-ui/icons";
import InputText from "../../../../components/InputText";
import api from "../../../../core/api";
import ModalFormResult from "../../../../components/ModalFormResult";
import { object, string } from "yup";
import { Formik } from "formik";

const useStyles = makeStyles({
  containerEight: {
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    width: "100%",
    height: "auto",
    minHeight: "700px",
  },
  img: {
    pointerEvents: "none",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "-1px",
    objectFit: "contain",
  },
  banner: {
    maxWidth: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "auto",
  },
  title: {
    fontFamily: "Century Gothic",
    fontSize: "30px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#009daa",
  },
  leftSection: {
    backgroundColor: "#F2F2F2",
    zIndex: 999,
    padding: 30,
    borderRadius: 20,
  },
  p: { textAlign: "left" },
  description: {
    fontFamily: "Century Gothic",
    fontSize: "25px",
    fontWeight: "300",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
  },
  sectionInput: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    background: "#fff",
    borderRadius: 30,
  },
  InputHome: {
    border: "none",
    marginTop: 20,
    fontFamily: "Century Gothic",
    fontSize: "16px",
    fontWeight: "300",
    fontStyle: "normal",
    textAlign: "left",
    color: "#1b1b1b",
  },
  button: {
    marginTop: 20,
    width: "100%",
    backgroundColor: "red",
    display: "flex",
    justifyContent: "space-between",
  },
  icon: { color: "#fff" },
});

const SectionEight = () => {
  const classes = useStyles();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loading, toggleLoading] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("");
  const [modalBody, setModalBody] = React.useState("");

  const openModal = (header, body) => {
    setIsOpen(true);
    setModalHeader(header);
    setModalBody(body);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const validationSchema = () =>
    object().shape({
      first_name: string().required("Preencha o campo nome"),
      email: string()
        .email("Digite um e-mail válido")
        .required("Preencha o campo de e-mail"),
    });

  const onSubmit = async (values) => {
    toggleLoading(true);
    const form = { email: values.email, first_name: values.first_name };

    try {
      if (form.email && form.first_name) {
        await api
          .post("/newsletter/website-subscription", form)
          .then((res) => {
            if (res.status === 200) {
              openModal(
                "Cadastro recebido",
                "Você agora recebe as dicas e promoções do MeditaBem com exclusividade."
              );
            }
          })
          .catch((err) => {
            throw new Error(err);
          });
      }
    } catch (e) {
      openModal(`Erro ${e.response.status}`, e.response.data.message);
    }

    toggleLoading(false);
  };

  return (
    <SectionMain noPadding className={classes.containerEight}>
      {/* <img alt="MeditaBem" src={banner} className={classes.img} /> */}
      <SectionMain noPadding className={classes.banner}>
        <div />
        <SectionMain noPadding item xs={6} className={classes.leftSection}>
          <div className={classes.title}>
            Faça parte da comunidade
            <br />
            <span className={classes.description}>
              <p className={classes.p}>
                Receba as novidades, fique por dentro das ações sociais apoiadas
                pelo MeditaBem e acompanhe promoções, novos parceiros e
                conteúdos de meditação e bem-estar.
              </p>
            </span>
          </div>
          <Formik
            validationSchema={validationSchema}
            initialValues={{ first_name: "", email: "" }}
            onSubmit={onSubmit}
          >
            {(formikProps) => (
              <form
                style={{ width: "100%" }}
                onSubmit={formikProps.handleSubmit}
              >
                <div className={classes.sectionInput}>
                  <InputText
                    value={formikProps.first_name}
                    errorMessage={
                      formikProps.touched.first_name &&
                      formikProps.errors.first_name
                    }
                    onChange={(e) =>
                      formikProps.setFieldValue("first_name", e.target.value)
                    }
                    disabled={loading}
                    label="Nome"
                    className={classes.InputHome}
                  />
                </div>
                <div className={classes.sectionInput}>
                  <InputText
                    value={formikProps.email}
                    onChange={(e) =>
                      formikProps.setFieldValue("email", e.target.value)
                    }
                    errorMessage={
                      formikProps.touched.email && formikProps.errors.email
                    }
                    type="email"
                    disabled={loading}
                    label="E-mail"
                    className={classes.InputHome}
                  />
                </div>
                <ButtonMedita
                  type="submit"
                  width={"100%"}
                  className={classes.button}
                >
                  <div />
                  Assinar
                  <IconButton size="large" className={classes.icon}>
                    <ArrowForward />
                  </IconButton>
                </ButtonMedita>
              </form>
            )}
          </Formik>
        </SectionMain>
      </SectionMain>
      <ModalFormResult
        isOpen={modalIsOpen}
        closeModal={closeModal}
        title={modalHeader}
        text={modalBody}
      ></ModalFormResult>
    </SectionMain>
  );
};

export default SectionEight;
