import { Avatar, Hidden, IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import contents from "../../../../assets/images/home/contents.png";
import center from "../../../../assets/images/home/centerTwo.png";
import SectionMain from "../../../../components/SectionMain";
import ButtonMedita from "../../../../components/ButtonMedita";
import { ArrowForward } from "@material-ui/icons";
import useGoToPage from "../../../../hooks/useGoToPage";

const useStyles = makeStyles({
  container: {},
  banner: {
    maxWidth: "95%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    marginTop: 20,
  },
  viewImage: {
    width: "70%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  title: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "center",
    color: "#36b37e",
  },
  titleTwo: {
    fontFamily: "Century Gothic",
    fontSize: "25px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#183b56",
  },
  description: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#5a7184",
  },
  root: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 20,
    marginRight: 10,
    textAlign: "center",
  },
  avatar: {
    width: "64px",
    height: "64px",
    margin: "auto",
    marginBottom: 5,
    borderRadius: "16px",
    background: "#6eb64b",
    background: "linear-gradient(180deg,  #6eb64b 0.00%, #009daa 100.00%)",
  },
  titleItems: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "center",
    color: "#183b56",
    marginTop: 20,
  },
  descItems: {
    width: "85%",
    fontFamily: "Century Gothic",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#5a7184",
  },
  viewCenter: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,
  },
  margin: { margin: "auto", marginTop: 20 },
  color: { color: "#fff" },
});

const SectionFour = () => {
  const goToPage = useGoToPage();
  const classes = useStyles();

  const token = localStorage.getItem("token");

  return (
    <SectionMain noPadding className={classes.container}>
      <SectionMain noPadding className={classes.banner}>
        <div>
          <div className={classes.title}>
            BEM-ESTAR A QUALQUER HORA <br />
            <span className={classes.titleTwo}>
              Viva uma vida mais plena e conectada
            </span>
            <br />
            <span className={classes.description}>
              Descubra como pequenas práticas diárias podem transformar sua
              vida.
            </span>
          </div>
          <div className={classes.viewCenter}>
            <div className={classes.root}>
              <Avatar
                className={classes.avatar}
                alt={`icon-contents`}
                src={contents}
              />
              <span className={classes.titleItems}>Acesso Total</span>
              <br />
              <span className={classes.descItems}>
                Tenha 14 dias de acesso ao conteúdo premium, sem custo.
              </span>
            </div>
            <div className={classes.root}>
              <Avatar
                className={classes.avatar}
                alt={`icon-contents`}
                src={contents}
              />
              <span className={classes.titleItems}>Conteúdos exclusivos</span>
              <br />
              <span className={classes.descItems}>
                Vídeos e áudios criados por especialistas para a sua jornada de
                bem-estar
              </span>
            </div>
          </div>
          {!token && (
            <ButtonMedita
              className={classes.margin}
              onClick={() => goToPage("/cadastre-se")}
            >
              Experimente Grátis
              <IconButton size="large" className={classes.color}>
                <ArrowForward />
              </IconButton>
            </ButtonMedita>
          )}
        </div>

        <img src={center} className={classes.viewImage} />
      </SectionMain>
    </SectionMain>
  );
};

export default SectionFour;
