import { makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { object, string } from "yup";
import api from "../../../core/api";
import useGoToPage from "../../../hooks/useGoToPage";
import usePartnerForm from "../../../hooks/usePartnerForm";
import ModalFormResult from "../../ModalFormResult";
import SectionMain from "../../SectionMain";

const useStyles = makeStyles({
  modal: {
    width: "380px",
    background: "#fff",
    margin: "200px auto",
    borderRadius: "13px",
    padding: "20px",
    background: "#eee",
    textAlign: "center",
  },
  divider: {
    height: "1px",
    background: "#CDCED2",
    margin: "10px",
  },
  iosLikeButton: {
    background: "none",
    border: "none",
    display: "block",
    margin: "0 auto",
    color: "#0076FF",
    fontSize: "17px",
    fontWeight: "600",
  },
  modalTitle: {
    fontWeight: "600",
    margin: "10px",
  },
});

const FormContactMinimalWrapper = ({ children, typeFooter = "client" }) => {
  const goToPage = useGoToPage();
  const handlePartnerForm = usePartnerForm()[1];
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("");
  const [modalBody, setModalBody] = React.useState("");

  const openModal = (header, body) => {
    setIsOpen(true);
    setModalHeader(header);
    setModalBody(body);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const validationSchema = () =>
    object().shape({
      first_name: string().required("Preencha o campo nome"),
      email: string()
        .email("Digite um e-mail válido")
        .required("Preencha o campo de e-mail"),
    });

  const onSubmit = async (values) => {
    const form = { email: values.email, first_name: values.first_name };
    if (typeFooter === "client") {
      try {
        if (form.email && form.first_name) {
          await api
            .post("/newsletter/website-subscription", form)
            .then((res) => {
              if (res.status === 200) {
                openModal(
                  "Cadastro recebido",
                  "Você agora recebe as dicas e promoções do MeditaBem com exclusividade."
                );
              }
            })
            .catch((err) => {
              console.error(err);
              openModal(`Erro ${err.status}`, err.message);

              throw new Error(err);
            });
        }
      } catch (e) {
        console.error(e);
        if (e) {
          openModal(`Erro ${e.status}`, e.message);
        } else {
          openModal(`Erro na solicitação`, "Tente novamente mais tarde");
        }
      }
    }

    if (typeFooter === "partner") {
      try {
        handlePartnerForm(form);
        goToPage("/ser-parceiro");
      } catch (e) {
        console.log({ e });
      }
    }
  };

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ first_name: "", email: "" }}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <SectionMain noPadding>
            {children({
              goToPage,
              formValues: formikProps.values,
              handleChangeInput: (key, value) =>
                formikProps.setFieldValue(key, value),
              formikProps,
            })}
          </SectionMain>
        )}
      </Formik>
      <ModalFormResult
        isOpen={modalIsOpen}
        closeModal={closeModal}
        title={modalHeader}
        text={modalBody}
      ></ModalFormResult>
    </div>
  );
};

export default FormContactMinimalWrapper;
