export const getSizeFont = (size) => {
	switch (size) {
		case 'xxbig':
			return '34px'
		case 'xbig':
			return '30px'
		case 'big':
			return '24px'
		case 'large':
			return '18px'
		case 'medium':
			return '16px'
		case 'small':
			return '14px'
		case 'xsmall':
			return '12px'
		default:
			return '14px'
	}
}
