import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { getSizeFont } from "../../utils/getSizeFont";
import { useAppContext } from "../../store/AppContext";

const useStyles = makeStyles({
  root: {
    fontSize: ({ fontSize }) => fontSize,
    color: ({ color }) => color,
    fontWeight: ({ fontWeight }) => fontWeight,
    textAlign: ({ align }) => align,
    textTransform: ({ capitalize }) => capitalize,
    letterSpacing: ({ letterSpacing }) => letterSpacing,
  },
});

const Title = ({
  size,
  align,
  capitalize,
  type = "regular",
  variant,
  color = "#282828",
  fontWeight,
  children,
  className,
  letterSpacing,
  ...props
}) => {
  const { theme, colorSchema } = useAppContext();
  const weight = { bold: "600", regular: "400" };
  fontWeight = fontWeight ? fontWeight : weight[type];
  color = variant ? colorSchema[variant] : color;
  capitalize = capitalize ? "capitalize" : null;
  const classes = useStyles({
    fontSize: getSizeFont(size),
    fontWeight,
    align,
    capitalize,
    letterSpacing,
    ...theme.light,
    color,
  });

  return (
    <Typography className={classes.root}>
      <span {...props} className={className}>
        {children}
      </span>
    </Typography>
  );
};

export default Title;
