import { Hidden } from "@material-ui/core";
import { Formik } from "formik";
import React, { useState } from "react";
import { object, string } from "yup"; // for only what you need
import ModalFormResult from "../../components/ModalFormResult";
import SectionMain from "../../components/SectionMain";
import api from "../../core/api";
import usePartnerForm from "../../hooks/usePartnerForm";
import RegisterPartnerPageDesktop from "./desktop";
import RegisterPartnerPageMobile from "./mobile";

const RegisterPartnerPage = () => {
  const partnerForm = usePartnerForm();
  const [response, setResponse] = useState();
  const [loading, changeLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("");
  const [modalBody, setModalBody] = React.useState("");

  const openModal = (header, body) => {
    setIsOpen(true);
    setModalHeader(header);
    setModalBody(body);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const defaultValues = {
    name: partnerForm.first_name ? partnerForm.first_name : "",
    email: partnerForm.email ? partnerForm.email : "",
    phone_first: "",
    document_number: "",
    occupation: "",
    street: "",
    number: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
    facebook: "",
    instagram: "",
    youtube: "",
    others: "",
    message: "",
  };

  const validationSchema = () =>
    object().shape({
      name: string().required("Preencha o campo, Nome"),
      email: string()
        .email("Digite um e-mail válido")
        .required("Preencha o campo, E-mail"),
      phone_first: string().required("Preencha o campo, Telefone"),
      document_number: string().required("Preencha o campo, CPF ou CNPJ"),
      occupation: string().required("Preencha o campo, Especialidade"),
    });

  const onSubmitForm = async (values = { document_number: "" }) => {
    const type = values.document_number.length === 11 ? "CPF" : "CNPJ";

    let form = {
      ...values,
      email: values.email.toLowerCase(),
      document: JSON.stringify({
        type: values.document_number.length === 11 ? "CPF" : "CNPJ",
        number: values.document_number,
      }),
      document_identity: JSON.stringify({
        type: "RG",
        number: "",
      }),
      address: JSON.stringify({
        postal_code: values.postal_code,
        street: values.street,
        number: values.number,
        state: values.state,
        city: values.city,
        country: values.country,
      }),
      social_midia: JSON.stringify({
        facebook: values.facebook,
        instagram: values.instagram,
        youtube: values.youtube,
        others: values.others,
      }),
      candidate_aproved: false,
      expertise: values.occupation,
    };

    delete form.document_number;
    try {
      const response = await api.post(`/partners`, form);

      if (response.status === 200) {
        openModal(
          "Obrigado pelo contato",
          "Recebemos seus dados. Logo entraremos em contato."
        );

        setResponse(response);

        //   form = {
        //     ...form,
        //     document: {
        //       type: values.document_number.length === 11 ? "CPF" : "CNPJ",
        //       number: values.document_number,
        //     },
        //     document_identity: {
        //       type: "RG",
        //       number: "",
        //     },
        //   };

        //   api.put(`/partners/${response.data.id}`, form);
      }
    } catch ({ response }) {
      openModal(
        `Erro na solicitação`,
        response?.data?.error || "Algo deu errado na sua solicitação"
      );
    }
  };

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        initialValues={defaultValues}
        onSubmit={(values) => {
          onSubmitForm(values);
        }}
      >
        {(formikProps) => (
          <SectionMain noPadding>
            <Hidden only={["xs", "sm"]}>
              <RegisterPartnerPageDesktop
                handleForm={formikProps.setFieldValue}
                values={formikProps.values}
                formikProps={formikProps}
              />
            </Hidden>
            <Hidden only={["md", "lg", "xl"]}>
              <RegisterPartnerPageMobile
                handleForm={formikProps.setFieldValue}
                values={formikProps.values}
                formikProps={formikProps}
              />
            </Hidden>
          </SectionMain>
        )}
      </Formik>
      <ModalFormResult
        isOpen={modalIsOpen}
        closeModal={closeModal}
        title={modalHeader}
        text={modalBody}
      ></ModalFormResult>
    </div>
  );
};

export default RegisterPartnerPage;
