import { makeStyles } from "@material-ui/core";
import React from "react";
import SectionMain from "../../../../components/SectionMain";
import ButtonMedita from "../../../../components/ButtonMedita";
import VideosCard from "../../../../components/VideosCard";
import useGoToPage from "../../../../hooks/useGoToPage";
import useVideosArray from "../../../../hooks/useVideosArray";

const useStyles = makeStyles({
  leftSectionTwo: {
    height: "100%",
    maxWidth: "80%",
    margin: "auto",
    marginTop: "5%",
  },
  view: { width: "88%", margin: "auto" },
  titleSectionTwo: {
    fontFamily: "Century Gothic",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#3bad76",
  },
  descriptionTwo: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#6f7072",
  },
  videos: {
    margin: "0 30px",
  },
  btn: {
    margin: "20px 0 40px",
    width: "170px !important",
  },
});

const SectionFive = () => {
  const classes = useStyles();
  const goToPage = useGoToPage();
  const videos = useVideosArray();

  return (
    <SectionMain noPadding>
      <SectionMain noPadding item className={classes.leftSectionTwo}>
        <div className={classes.view}>
          <div className={classes.titleSectionTwo}>
            Experimente o bem-estar que você merece
            <br />
            <div style={{ width: "60%", margin: "auto", marginBottom: 30 }}>
              <span className={classes.descriptionTwo}>
                Transforme a sua rotina de uma forma simples e leve, com
                meditações guiadas, técnicas de respiração, exercícios, práticas
                de yoga, músicas, terapias holísticas e muito mais.
              </span>
            </div>
          </div>
        </div>

        <VideosCard
          className={classes.videos}
          position={"center"}
          videos={videos.slice(0, 5)}
        />

        <SectionMain position="center">
          <ButtonMedita
            onClick={() => goToPage("/experimente")}
            className={classes.vbtn}
          >
            {"VER TODOS"}
          </ButtonMedita>
        </SectionMain>
      </SectionMain>
    </SectionMain>
  );
};

export default SectionFive;
