import React from "react";
import FooterSectionLinks from "./FooterSectionLinks";
import FooterSectionUI from "./FooterSectionUI";
import FooterSectionWrapper from "./FooterSectionWrapper";

const FooterSection = ({
  mobile,
  typeFooter = "client",
  hideNewsletter = false,
}) => {
  return (
    <div
      style={{ background: "#EFEFEF", width: "100%", marginBottom: "-15px" }}
    >
      <FooterSectionWrapper typeFooter={typeFooter}>
        {({
          goToPage,
          formValues,
          handleChangeInput,
          formikProps,
          loading,
        }) => (
          <React.Fragment>
            {!hideNewsletter && (
              <FooterSectionUI
                typeFooter={typeFooter}
                goToPage={goToPage}
                loading={loading}
                handleChangeInput={handleChangeInput}
                formValues={formValues}
                mobile={mobile}
                formikProps={formikProps}
              />
            )}
            <FooterSectionLinks />
          </React.Fragment>
        )}
      </FooterSectionWrapper>
    </div>
  );
};

export default FooterSection;
