import { makeStyles } from "@material-ui/core";
import React from "react";
import SectionMain from "../../../../components/SectionMain";
import CardsPlan from "./components/CardsPlan";

const useStyles = makeStyles({
  containerSeven: {
    backgroundColor: "#F3FAF7",
    borderRadius: "150% 150% 0% 0%/40% 40% 0% 0%",
    paddingBottom: "5%",
  },
  leftSectionSeven: {
    height: "100%",
    maxWidth: "80%",
    margin: "auto",
    marginTop: 50,
  },
  titleSectionSeven: {
    fontFamily: "Century Gothic",
    fontSize: "22px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#3bad76",
    marginTop: 10,
  },
  descriptionSeven: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7084",
  },
  sealView: {
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#E1F3EC",
    borderRadius: 40,
    margin: "auto",
  },
  sealText: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#36B37E",
  },
});

const SectionSeven = () => {
  const classes = useStyles();
  return (
    <div style={{ paddingTop: "20px" }}>
      <SectionMain noPadding className={classes.containerSeven}>
        <SectionMain noPadding item className={classes.leftSectionSeven}>
          <div className={classes.sealView}>
            <span className={classes.sealText}>Planos</span>
          </div>
          <div className={classes.titleSectionSeven}>
            Escolha o plano perfeito e comece a transformar seu estado de
            espirito
            <br />
            <span className={classes.descriptionSeven}>
              Descubra qual dos nossos planos mensais ou anuais vai ser o
              primeiro passo para uma vida mais tranquila, focada e equilibrada.
            </span>
          </div>
        </SectionMain>
        <CardsPlan />
      </SectionMain>
    </div>
  );
};

export default SectionSeven;
