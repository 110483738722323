import React from 'react'
import FormContactWrapper from './FormContactWrapper'
import FormContactUI from './FormContactUI'

const FormContact = ({ formikProps, mobile }) => {
	return (
		<FormContactWrapper formikProps={formikProps} mobile={mobile}>
			{({ keyObject, label, type, handleFormChange, value, errorMessage }, i) => (
				<FormContactUI
					mobile={mobile}
					key={i}
					keyObject={keyObject}
					label={label}
					type={type}
					handleFormChange={handleFormChange}
					value={value}
					errorMessage={errorMessage}
				/>
			)}
		</FormContactWrapper>
	)
}

export default FormContact
