import React from 'react'
import SectionMain from '../../components/SectionMain'
import { Hidden } from '@material-ui/core'
import CategoriesPageDesktop from './desktop'
import CategoriesPageMobile from './mobile'

const CategoriesPage = () => {
	return (
		<SectionMain noPadding>
			<Hidden only={[ 'xs', 'sm' ]}>
				<CategoriesPageDesktop />
			</Hidden>
			<Hidden only={[ 'md', 'lg', 'xl' ]}>
				<CategoriesPageMobile />
			</Hidden>
		</SectionMain>
	)
}

export default CategoriesPage
