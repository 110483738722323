import React from "react";
import sound from "../assets/images/icons/sound.png";
import video from "../assets/images/icons/video.png";
import Babi_Minamoto_Premium_Site from "../assets/images/thumbnails/Babi_Minamoto_Premium_Site.jpeg";
import Camila_Montadon_Premium_Site from "../assets/images/thumbnails/Camila_Montadon_Premium_Site.jpg";
import Cleyson_Dellcorso_Premium_Site from "../assets/images/thumbnails/Cleyson_Dellcorso_Premium_Site.jpg";
import minutos_11 from "../assets/images/thumbnails/videos/11_minutos.jpg";
import acervo_identidade from "../assets/images/thumbnails/videos/Acervo_identidade.jpg";
import acharya_vivek from "../assets/images/thumbnails/videos/Acharya_Pavitra_Pratica_Vivek.jpg";
import camila_esvaziando from "../assets/images/thumbnails/videos/Camila_Esvaziando.png";
import camila_alinhamento from "../assets/images/thumbnails/videos/Camila_Montandon_Alinhamento.jpg";
import respiracao_3_tempos from "../assets/images/thumbnails/videos/Celina_Cantidiano_Respiração_3_tempos.jpg";
import chuva from "../assets/images/thumbnails/videos/Chuva.jpg";
import daniele_respiracao from "../assets/images/thumbnails/videos/Daniele_respiracao.jpg";
import helo_asanas from "../assets/images/thumbnails/videos/Helo_Carneiro_Asanas.jpg";
import herica_12 from "../assets/images/thumbnails/videos/Herica_12.jpg";
import herica_pes from "../assets/images/thumbnails/videos/Herica_Pes.jpg";
import juliana_samavritti from "../assets/images/thumbnails/videos/Juliana_Borges_Praticando_Samavritti.jpg";
import julia_vaz_blocos from "../assets/images/thumbnails/videos/Julia_Vaz_Praticas_yoga_blocos.jpg";
import kakao_escolhendo from "../assets/images/thumbnails/videos/Kakao_Chao_Escolhendo.jpg";
import luciano_flores from "../assets/images/thumbnails/videos/Luciano_Flores.jpg";
import luciano_om from "../assets/images/thumbnails/videos/Luciano_Om.png";
import lu_cisne from "../assets/images/thumbnails/videos/Lu_Cisne.png";
import lu_leao from "../assets/images/thumbnails/videos/Lu_Leao.png";
import livre_de_ansiedade from "../assets/images/thumbnails/videos/Lyne_Johnson_Livre_de_ansiedade.jpg";
import mal_seculo from "../assets/images/thumbnails/videos/mal_seculo.jpg";
import curando_magoas from "../assets/images/thumbnails/videos/Marcello_Cotrim_Curando_Magoas.jpg";
import michele_lullaby from "../assets/images/thumbnails/videos/Michele_Lullaby.jpg";
import muted_balance from "../assets/images/thumbnails/videos/Muted_Balance.jpg";
import relaxamento_profundo from "../assets/images/thumbnails/videos/Nathalie_Gretillat_Relaxamento_profundo.jpg";
import passaros from "../assets/images/thumbnails/videos/Passaros.jpg";
import paula_facilidade from "../assets/images/thumbnails/videos/Paula_Millen_Facilidade.jpg";
import penelope_colica from "../assets/images/thumbnails/videos/Penelope_colica.png";
import roberta_matinal from "../assets/images/thumbnails/videos/Roberta_Matinal.png";
import sagrado_feminino from "../assets/images/thumbnails/videos/Rosana_Pianilino_Sagrado_Feminino.jpg";
import sophie_respirar from "../assets/images/thumbnails/videos/Sophie_respirar.jpg";
import utthita from "../assets/images/thumbnails/videos/utthita.png";
import yoga_kriya from "../assets/images/thumbnails/videos/Yoga_Kriya.png";
import yoga_svanasana from "../assets/images/thumbnails/videos/Yoga_por_Inteiro_Svanasana.png";
import young_ukulele from "../assets/images/thumbnails/videos/Young_Ukulele.jpg";

export const arrayVideos = [
  {
    id: "01",
    icon: <img src={sound} width={10} height={10} alt="icon-card" />,
    name: "Sagrado feminino: gratidão",
    videoURL:
      "https://medita.com.br/media/ROSANA_PIANILINO_SAGRADO_FEMININO.mp4",
    thumbnail: sagrado_feminino,
    category: "Meditações",
    partnerName: "Rosana Pianilino",
    description: (
      <span>
        Gratidão à linhagem familiar e ao Sagrado Feminino, para se conectar com
        a sua ancestralidade e essência divina.
      </span>
    ),
  },
  {
    id: "02",
    icon: <img src={sound} width={10} height={10} alt="icon-card" />,
    name: "Livre de Ansiedade",
    videoURL: "https://medita.com.br/media/LYNE_JOHNSON_LIVRE_DE_ANSIEDADE.mp4",
    thumbnail: livre_de_ansiedade,
    category: "Meditações",
    partnerName: "Lyne Johnson",
    description: (
      <span>
        Aprenda como libertar a ansiedade naturalmente e encontre o seu lugar
        seguro de paz interior. Aproveite a viagem e relaxe completamente.
      </span>
    ),
  },
  {
    id: "03",
    icon: <img src={sound} width={10} height={10} alt="icon-card" />,
    name: "Relaxamento profundo para se conectar com a gratidão",
    videoURL:
      "https://medita.com.br/media/NATHALIE_GRETILLAT_RELAXAMENTO_PROFUNDO.mp4",
    thumbnail: relaxamento_profundo,
    category: "Meditações",
    partnerName: "Nathalie Gretillat",
    description: (
      <span>
        Ouça esta meditação para atingir um estado de relaxamento profundo e se
        conectar com o sentimento de gratidão na sua vida.
      </span>
    ),
  },
  {
    id: "04",
    icon: <img src={sound} width={10} height={10} alt="icon-card" />,
    name: "Curando Mágoas",
    videoURL: "https://medita.com.br/media/MARCELLO_COTRIM_CURANDO_MAGOAS.mp4",
    thumbnail: curando_magoas,
    category: "Meditações",
    partnerName: "Marcello Cotrim",
    description: (
      <span>
        Nesta meditação você irá cortar elos emocionais e energéticos com
        situações do passado que causam ressentimentos gerando doenças e
        impedindo sua felicidade.
      </span>
    ),
  },
  {
    id: "05",
    icon: <img src={sound} width={10} height={10} alt="icon-card" />,
    name: "Respiração em 3 tempos",
    videoURL:
      "https://medita.com.br/media/CELINA_CANTIDIANO_RESPIRAÇÃO_3_TEMPOS.mp4",
    thumbnail: respiracao_3_tempos,
    category: "Meditações",
    partnerName: "Celina Cantidiano",
    description: (
      <span>
        Essa é uma prática de curta duração para momentos em que temos pouco
        tempo de pausa, mas desejamos parar um pouco para acalmar a mente.
        Aproveite essa pausa para voltar ao momento presente.
      </span>
    ),
  },
  {
    id: "06",
    icon: <img src={video} width={10} height={10} alt="icone" />,
    name: "Ásanas para fortalecimento muscular",
    videoURL: "https://medita.com.br/media/HELO_CARNEIRO_ASANAS.mp4",
    thumbnail: helo_asanas,
    category: "Yoga",
    partnerName: "Helô Carneiro",
    description: (
      <span>
        Aprenda diferentes posturas no yoga para fortalecimento muscular.
      </span>
    ),
  },
  {
    id: "07",
    icon: <img src={video} width={10} height={10} alt="icone" />,
    name: "Praticando Samavritti",
    videoURL: "https://medita.com.br/media/JULIANA_BORGES_SAMAVRITTI.mp4",
    thumbnail: juliana_samavritti,
    category: "Yoga",
    partnerName: "Juliana Borges",
    description: <span>Juliana Borges - Praticando Samavritti</span>,
  },
  {
    id: "08",
    icon: <img src={video} width={10} height={10} alt="icone" />,
    name: "Prática de Vivek Yoga",
    videoURL: "https://medita.com.br/media/ACHARYA_PAVITRA_PRATICA_VIVEK.mp4",
    thumbnail: acharya_vivek,
    category: "Yoga",
    partnerName: "Miyuki",
    description: (
      <span>
        Aula de 25 minutos de Vivek Yoga Integral para você praticar no
        dia-a-dia, pode ser feito por pessoas em todas as idades.
      </span>
    ),
  },
  {
    id: "09",
    icon: <img src={video} width={10} height={10} alt="icone" />,
    name: "Práticas de yoga usando blocos",
    videoURL: "https://medita.com.br/media/JULIA_VAZ_YOGA_BLOCOS.mp4",
    thumbnail: julia_vaz_blocos,
    category: "Yoga",
    partnerName: "Julia Vaz",
    description: (
      <span>
        Se você tem pouco tempo e dois blocos, esta é uma prática simples,
        rápida e de forma correta para adquirirmos em nossa rotina, aliviando as
        dores e os incômodos diários. A professora Julia Vaz indica praticar
        sempre de estômago vazio e de uma a duas vezes durante o dia, mas sempre
        com um intervalo de pelo menos duas horas. Vale lembrar que, esta
        prática é contraindicada para pessoas com pressão alta, problemas de
        pressão intraocular e gravidez a partir do terceiro trimestre. É
        importante consultar o seu médico caso sinta dores frequentemente ou
        antes de começar qualquer treinamento.
      </span>
    ),
  },
  {
    id: "10",
    icon: <img src={video} width={10} height={10} alt="icone" />,
    name: "Adho Mukha Svanásana",
    videoURL: "https://medita.com.br/media/YOGA_SVANASANA.mp4",
    thumbnail: yoga_svanasana,
    category: "Yoga",
    partnerName: "Yoga Por Inteiro",
    description: (
      <span>
        Adho Mukha Svanásana, é a postura do "cachorro olhando para baixo",
        conecta suas mãos e pés na terra. Nessa postura metade de seu corpo faz
        uma invertida, te auxiliando a olhar e vencer seus medos, além de seus
        benefícios físicos na coluna vertebral e posterior das pernas.
      </span>
    ),
  },
  {
    id: "11",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Facilidade, alegria e glória",
    videoURL: "https://medita.com.br/media/PAULA_MILLEN_FACILIDADE.mp4",
    thumbnail: paula_facilidade,
    category: "Mantras",
    partnerName: "Paula Millen",
    description: (
      <span>
        Conheça este mantra fácil e poderoso de Access Consciousness. Repita-o
        várias vezes ao acordar ou durante o dia e sinta sua vida fluir
        positivamente.
      </span>
    ),
  },
  {
    id: "12",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Escolhendo a vida",
    videoURL: "https://medita.com.br/media/KAKAO_ESCOLHENDO.mp4",
    thumbnail: kakao_escolhendo,
    category: "Mantras",
    partnerName: "Kakao Chao",
    description: (
      <span>
        Use este mantra para afirmar a saúde perfeita e conectar-se com a
        energia generativa do Universo.
      </span>
    ),
  },
  {
    id: "13",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Ho'oponopono da identidade própria",
    videoURL: "https://medita.com.br/media/ACERVO_IDENTIDADE.mp4",
    thumbnail: acervo_identidade,
    category: "Mantras",
    partnerName: "Acervo",
    description: (
      <span>
        Oração milenar havaiana de cura e perdão para liberar mágoas e
        transmutar energias negativas. Para potencializar seus efeitos, você
        pode recitá-la por 21 dias seguidos. Também pode repetir as palavras
        chave do Ho'oponopono durante o dia: Sinto muito, me perdoe, te amo, sou
        grato (a), inclusive visualizando uma pessoa ou situação em que deseja
        trabalhar o perdão e liberação de mágoas. Trilha sonora com música
        "Better Now - Piano Version" de Philip Daniel Zach
      </span>
    ),
  },
  {
    id: "14",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Alinhamento dos sete chacras",
    videoURL: "https://medita.com.br/media/CAMILA_ALINHAMENTO.mp4",
    thumbnail: camila_alinhamento,
    category: "Mantras",
    partnerName: "Camila Montandon",
    description: (
      <span>
        Ouça os mantras do alinhamento dos chacras ou, se preferir, repita
        visualizando a cor de cada chacra.
      </span>
    ),
  },
  {
    id: "15",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Om Mani Padme Hum",
    videoURL: "https://medita.com.br/media/LUCIANO_OM.mp4",
    thumbnail: luciano_om,
    category: "Mantras",
    partnerName: "Luciano Cesa",
    description: (
      <span>
        O mantra Om Mani Padme Hum foi gentilmente cedido por Luciano Cesa. Para
        saber mais sobre os seus trabalhos, acesse newagemusic.com.br/br/
      </span>
    ),
  },
  {
    id: "16",
    name: "Alongamento Matinal",
    videoURL: "https://medita.com.br/media/ROBERTA_MATINAL.mp4",
    thumbnail: roberta_matinal,
    category: "Exercícios",
    partnerName: "Roberta Zanardi",
    description: (
      <span>
        Cinco exercícios de alongamento matinal para começar o dia com mais
        disposição, que trazem mais flexibilidade e melhoram sua qualidade de
        vida.
      </span>
    ),
  },
  {
    id: "17",
    name: "Pés orgânicos: Exercícios para caminhar e correr",
    videoURL: "https://medita.com.br/media/HERICA_PES.mp4",
    thumbnail: herica_pes,
    category: "Exercícios",
    partnerName: "Hérica Sanfelice",
    description: (
      <span>
        Exercício para se movimentar com os pés descalços, pois é importante
        fortalecer as estruturas dos pés, tornozelos e pernas, que estão muitas
        vezes adaptados com o conforto dos calçados. (Música utilizada: Riddim
        Road - Jingle Punks)
      </span>
    ),
  },
  {
    id: "18",
    name: "Kriya",
    videoURL: "https://medita.com.br/media/YOGA_KRIYA.mp4",
    thumbnail: yoga_kriya,
    category: "Exercícios",
    partnerName: "Yoga por Inteiro",
    description: (
      <span>
        Kriyas são limpezas do organismo. Jala Neti é uma técnica de purificação
        das fossas nasais, onde utilizamos o "neti pot".
      </span>
    ),
  },
  {
    id: "19",
    name: "Esvaziando corpo e mente",
    videoURL: "https://medita.com.br/media/CAMILA_ESVAZIANDO.mp4",
    thumbnail: camila_esvaziando,
    category: "Exercícios",
    partnerName: "Camila Montandon",
    description: (
      <span>
        Quando soltamos o ar, soltamos também nossas tensões e outras coisas que
        nos incomodam. Aprenda como fazer e dar espaço para o novo.
      </span>
    ),
  },
  {
    id: "20",
    name: "Do-in para TPM e cólica",
    videoURL: "https://medita.com.br/media/PENELOPE_COLICA.mp4",
    thumbnail: penelope_colica,
    category: "Exercícios",
    partnerName: "Penélope Beolchi",
    description: (
      <span>
        Aprenda esta técnica de Do-in para aliviar os sintomas de TPM e cólica.
      </span>
    ),
  },
  {
    id: "21",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Aprendendo a respirar",
    videoURL: "https://medita.com.br/media/SOPHIE_RESPIRAR.mp4",
    thumbnail: sophie_respirar,
    category: "MiniMedita",
    partnerName: "Sophie Gretillat",
    description: <span>Prática de meditação para aprender a respirar. </span>,
  },
  {
    id: "22",
    icon: <img src={video} width={10} height={10} alt="icone" />,
    name: "Postura do Cisne",
    videoURL: "https://medita.com.br/media/LU_CISNE.mp4",
    thumbnail: lu_cisne,
    category: "MiniMedita",
    partnerName: "Lu Cruz",
    description: (
      <span>
        Esta postura alonga o tronco, ajuda a fortalecer o abdômen, estimula o
        apetite e previne a prisão de ventre. Pode ser feita a qualquer hora do
        dia. Faça a postura respeitando o limite do seu corpo e, com o tempo, o
        alongamento vai melhorando. Depois da postura do cisne, você pode fazer
        a postura da criança, relaxando as costas.{" "}
      </span>
    ),
  },
  {
    id: "23",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Concentrando na respiração",
    videoURL: "https://medita.com.br/media/DANIELE_RESPIRACAO.mp4",
    thumbnail: daniele_respiracao,
    category: "MiniMedita",
    partnerName: "Daniele Gonzales",
    description: (
      <span>
        Esta meditação simples trará clareza para que a criança, iniciante ou
        não, compreenda a importância da presença respiratória em todas as
        meditações.
        <br />
        <br />
        Trilha sonora: Garden Music by Kevin MacLeod
        <br />
        Link: https://filmmusic.io/song/3796-garden-music
        <br />
        License: http://creativecommons.org/licenses/by/4.0/
      </span>
    ),
  },
  {
    id: "24",
    icon: <img src={video} width={10} height={10} alt="icone" />,
    name: "Postura do Leão",
    videoURL: "https://medita.com.br/media/LU_LEAO.mp4",
    thumbnail: lu_leao,
    category: "MiniMedita",
    partnerName: "Lu Cruz",
    description: (
      <span>
        Neste vídeo você vai aprender e praticar a postura do leão, que na Yoga
        é chamada de Simhasana. Rugir como um leão faz você se sentir mais
        confiante, ajuda a relaxar os músculos do rosto e te deixa mais
        tranquilo.
      </span>
    ),
  },
  {
    id: "25",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Lullaby",
    videoURL: "https://medita.com.br/media/MICHELLE_LULLABY.mp4",
    thumbnail: michele_lullaby,
    category: "MiniMedita",
    partnerName: "Acervo",
    description: <span>Música de autoria de Michele Nobler. </span>,
  },
  {
    id: "26",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Chuva",
    videoURL: "https://medita.com.br/media/CHUVA.mp4",
    thumbnail: chuva,
    category: "Músicas",
    partnerName: "Acervo",
    description: <span>Sons de trovões e chuva leve.</span>,
  },
  {
    id: "27",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Balance",
    videoURL: "https://medita.com.br/media/MUTED_BALANCE.mp4",
    thumbnail: muted_balance,
    category: "Músicas",
    partnerName: "Acervo",
    description: <span>Música de autoria de Muted.</span>,
  },
  {
    id: "28",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Pássaros",
    videoURL: "https://medita.com.br/media/PASSAROS.mp4",
    thumbnail: passaros,
    category: "Músicas",
    partnerName: "Acervo",
    description: <span>Sons dos pássaros cantando.</span>,
  },
  {
    id: "29",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Cartoon Ukulele",
    videoURL: "https://medita.com.br/media/YOUNG_UKULELE.mp4",
    thumbnail: young_ukulele,
    category: "Músicas",
    partnerName: "Acervo",
    description: <span>Música de autoria de Young Rich Pixies.</span>,
  },
  {
    id: "30",
    icon: <img src={sound} width={10} height={10} alt="icone" />,
    name: "Riacho e Flores",
    videoURL: "https://medita.com.br/media/LUCIANO_FLORES.mp4",
    thumbnail: luciano_flores,
    category: "Músicas",
    partnerName: "Luciano Cesa",
    description: (
      <span>
        A música Riacho e flores foi gentilmente cedida por Luciano Cesa. Para
        saber mais sobre os seus trabalhos, acesse newagemusic.com.br/br/
      </span>
    ),
  },
];

export const premiumVideos = [
  {
    id: "33",
    icon: <img src={video} width={10} height={10} alt="icone" />,
    name: "Refúgio tranquilo: Descanse a mente e tome decisões",
    videoURL:
      "https://medita.com.br/media/MEDITA_CLEYSON DELLCORSO_REFÚGIO TRANQUILO_versão site premium.mp4",
    thumbnail: Cleyson_Dellcorso_Premium_Site,
    category: "Premium",
    partnerName: "Cleyson Dellcorso",
    description: (
      <span>
        Construa na sua imaginação um espaço de refúgio, um local de repouso
        mental e de tranquilidade, onde decisões podem ser tomadas. Este refúgio
        é um local para onde nos direcionamos mentalmente quando estamos
        estressados, cansados emocionalmente ou quando a pressão nas atividades
        profissionais for muito grande.{" "}
      </span>
    ),
  },
  {
    id: "35",
    icon: <img src={video} width={10} height={10} alt="icone" />,
    name: "Prática de vivek yoga",
    videoURL:
      "https://medita.com.br/media/MEDITA_BABI MINAMOTO_PRÁTICA DE VIVEK YOGA_versão site premium.mp4",
    thumbnail: Babi_Minamoto_Premium_Site,
    category: "Premium",
    partnerName: "Babi Minamoto",
    description: (
      <span>
        Esta aula é propícia para relaxar o corpo e acalmar o sistema nervoso. É
        indicada tanto para pessoas que praticam yoga há muito tempo ou até
        mesmo aqueles que nunca praticaram. Recomendada para trabalhar a parte
        muscular, desintoxicar o corpo, equilibrar as emoções e trazer um pouco
        de paz mental para que facilite nas decisões do dia a dia.{" "}
      </span>
    ),
  },
  {
    id: "36",
    icon: <img src={video} width={10} height={10} alt="icone" />,
    name: "Respiração para energizar o corpo",
    videoURL:
      "https://medita.com.br/media/MEDITA_CAMILA MONTANDON_RESPIRAÇÃO PARA ENERGIZAR O CORPO_versão site premium.mp4",
    thumbnail: Camila_Montadon_Premium_Site,
    category: "Premium",
    partnerName: "Camila Montandon",
    description: (
      <span>
        Durante o dia é comum termos picos de sonolência e cansaço, nesta hora,
        podemos realizar um exercício respiratório para dar mais energia ao
        corpo. Vamos praticar?
      </span>
    ),
  },
  {
    id: "37",
    name: "12 minutos para aliviar dores nas costas",
    videoURL:
      "https://medita.com.br/media/MEDITA_HÉRICA SANFELICE_12 MINUTOS PARA ALIVIAR DORES NAS COSTAS_versão site premium.mp4",
    thumbnail: herica_12,
    category: "Premium",
    partnerName: "Hérica Sanfelice",
    description: (
      <span>
        Exercício para se movimentar com os pés descalços, pois é importante
        fortalecer as estruturas dos pés, tornozelos e pernas, que estão muitas
        vezes adaptados com o conforto dos calçados. (Música utilizada: Riddim
        Road - Jingle Punks)
      </span>
    ),
  },
  {
    id: "38",
    name: "Uma prática contra o mal do século: A ansiedade",
    videoURL:
      "https://medita.com.br/media/MEDITA_JULIANA BORGES_UMA PRÁTICA CONTRA O MAL DO SÉCULO_versão site premium.mp4",
    thumbnail: mal_seculo,
    category: "Premium",
    partnerName: "Juliana Borges",
    description: (
      <span>
        Às vezes o que precisamos é uma prática de yoga que restaure nosso
        corpo, de dentro para fora, né? Essa prática vem para aqueles dias com
        doses de estresse, ansiedade ou muitas incertezas. Perfeita para fazer
        principalmente no final do dia, ela traz muito relaxamento, poses
        restaurativas deitadas, algumas poses de alongamento e relaxamento para
        coluna, ombros e para nossa alma. Deixe ir essa ansiedade e fique na
        melhor companhia possível: a sua! Poses bem fáceis de serem feitas, sem
        contra indicação. Aconselho você a separar uma mantinha, acender uma
        vela e, caso você goste, deixar um cheirinho de óleo essencial como o de
        Lavanda ou algum incenso que você goste no ambiente. Se entregue e deixe
        fluir. Tenho certeza que você será outra pessoa no final.
      </span>
    ),
  },
  {
    id: "39",
    name: "11 minutos para relaxar",
    videoURL:
      "https://medita.com.br/media/MEDITA_HELOÍSA LARANJA_11 MINUTOS PARA RELAXAR_versão site premium.mp4",
    thumbnail: minutos_11,
    category: "Premium",
    partnerName: "Heloísa Laranja",
    description: (
      <span>
        11 minutos de relaxamento profundo. Faça essa meditação guiada para se
        conectar com a sua luz interior e relaxar no seu dia a dia. Pratique
        sempre que puder e onde estiver.
      </span>
    ),
  },
  {
    id: "40",
    name: "Utthita Trikonásana",
    videoURL:
      "https://medita.com.br/media/MEDITA_YOGA POR INTEIRO_UTTHITA TRIKONASANA_versão site premium.mp4",
    thumbnail: utthita,
    category: "Premium",
    partnerName: "Yoga por Inteiro",
    description: (
      <span>
        Utthita Trikonásana, a postura do triângulo. Essa postura ajuda a
        diminuir o distresse, auxilia no alinhamento de coluna e melhora a
        digestão.
      </span>
    ),
  },
];
