export const TEXTS = {
  client: {
    title: "Faça parte da comunidade",
    footerText:
      "Receba as novidades, fique por dentro das ações sociais apoiadas pelo MeditaBem e acompanhe promoções, novos parceiros e conteúdos de meditação e bem-estar.",
    messageSuccess:
      "Cadastro realizado com sucesso! Aguarde as novidades, dicas de práticas, promoções e muito mais em seu e-mail.",
    buttonLabel: "Inscreva-se",
  },
  partner: {
    title: "ENTRE EM CONTATO CONOSCO",
    footerText:
      "Leve sua mensagem muito mais longe e acompanhe os resultados dos seus conteúdos em nossa plataforma exclusiva. Experimente!",
    messageSuccess:
      "Cadastro realizado com sucesso! Fique de olho em nossas novidades e conteúdos exclusivos para você.",
    buttonLabel: "Vamos conversar",
  },
};
