import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import SectionMain from "../../../components/SectionMain";
import api from "../../../core/api";
import "./style.css";
import { useAppContext } from "../../../components/singletons/AppContext";

import _ from "lodash";
import { Chart } from "react-google-charts";

const gerar_cor = (opacidade = 1) => {
  let r = Math.random() * 255;
  let g = Math.random() * 255;
  let b = Math.random() * 255;

  return `rgba(${r}, ${g}, ${b}, ${opacidade})`;
};

const useStyles = makeStyles({
  barColorPrimary: { backgroundColor: "#53b53f" },
  colorPrimary: { backgroundColor: "transparent" },
  ageColumn: { width: "150px" },
  valueColumn: { width: "80px" },
  noPadding: { padding: "0 !important" },
  cardHeader: {
    textAlign: "center",
    background: "#DDD",
    color: "#777",
  },
  card: {
    borderRadius: "20px",
  },
  cardHeaderContent: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  downloadCount: {
    fontSize: "70px",
    lineHeight: "70px",
    textAlign: "center",
    fontWeight: "600",
    margin: "20px 0 0",
  },
  downloadLabel: {
    color: "#888",
    textAlign: "center",
    display: "block",
  },
  genderIcon: {
    marginRight: "10px",
    width: "14px",
    height: "14px",
    display: "inline-block",
    borderRadius: "5px",
    position: "relative",
    top: "1px",
  },
  genderRow: {
    marginBottom: "10px",
  },
  cardChart: {
    padding: "10px 30px",
  },
  genderRowGroup: {
    paddingTop: "50px",
  },
});

const participantsByAge = [
  {
    label: "Meditações",
    percentage: 25.47,
  },
  {
    label: "Yoga",
    percentage: 87.03,
  },
  {
    label: "Exercícios",
    percentage: 25.47,
  },
  {
    label: "Mantras",
    percentage: 87.03,
  },
  {
    label: "Minimedita",
    percentage: 25.47,
  },
  {
    label: "Músicas",
    percentage: 87.03,
  },
  {
    label: "Cursos",
    percentage: 25.47,
  },
];

const PartnersPanelComunityDesktop = () => {
  const classes = useStyles();
  const { objectives, categories, setAppState } = useAppContext();
  const [obj, setObj] = useState([]);
  const [cat, setCat] = useState([]);

  useEffect(() => {
    if (!obj || !obj.length) {
      fetchObjectives();
    }
    if (!categories || !categories.length) {
      fetchCategories();
    }
  }, []);

  const loadDataPie = (data) => {
    const values = _.groupBy(data, (value) => value.name);
    console.log("values", values);
    const result = _.map(values, (value, key) => {
      return [key, _.sumBy(values[key], (v) => v.value)];
    });
    console.log("result", result);
    return [["Objetivos", "Usuários"], ...result];
  };

  const fetchObjectives = async () => {
    const id = localStorage.getItem("id");
    const objectivesRequest = await api.get(`objectives`);
    if (objectivesRequest.status === 200) {
      let dados = objectivesRequest.data;
      const percentage = 100 / objectivesRequest.data.length;
      console.log(dados);
      setObj(dados);
      setAppState({
        objectives: dados,
        categories,
      });
    }
  };

  const fetchCategories = async () => {
    const categoriesRequest = await api.get("/categories");
    if (categoriesRequest.status === 200) {
      let dados = categoriesRequest.data;
      console.log(dados);
      setCat(dados);
      setAppState({
        objectives,
        categories: dados,
      });
    }
  };

  return (
    <SectionMain noPadding>
      {/* <Chart chartType="PieChart" data={objectives} width="100%"  height="100%" options={{title : "Objetivos por usuários"}} /> */}

      <Grid container className="panel-community-container" spacing={10}>
        <Grid item xs={3}>
          <Card elevation={0} variant="outlined" className={classes.card}>
            <CardHeader
              title="Downloads"
              className={classes.cardHeader}
              classes={{ title: classes.cardHeaderContent }}
            />
            <CardContent>
              <h2 className={classes.downloadCount}>10.040</h2>
              <small className={classes.downloadLabel}>
                usuários no app MeditaBem
              </small>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={0} variant="outlined" className={classes.card}>
            <CardHeader
              title="Objetivos do usuário"
              className={classes.cardHeader}
              classes={{ title: classes.cardHeaderContent }}
            />
            <CardContent>
              <Grid container>
                <Grid item xs={6} className={classes.cardChart}>
                  <PieChart
                    data={obj.map(({ name, id }) => ({
                      value: 100 / obj.length,
                      color: "green",
                      title: name,
                    }))}
                  />
                </Grid>
                <Grid item xs={6} className={classes.genderRowGroup}>
                  {obj.map(({ name, id }) => (
                    <div key={id} className={classes.genderRow}>
                      <span
                        className={classes.genderIcon}
                        style={{ backgroundColor: "#fff" }}
                      />
                      <strong>{100 / obj.length}%</strong> - {name}
                    </div>
                  ))}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card elevation={0} variant="outlined" className={classes.card}>
            <CardHeader
              title="Principais categorias"
              className={classes.cardHeader}
              classes={{ title: classes.cardHeaderContent }}
            />
            <CardContent classes={{ root: classes.noPadding }}>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {cat.map((participant) => (
                      <TableRow key={participant.name}>
                        <TableCell align="right" className={classes.ageColumn}>
                          {participant.name}
                        </TableCell>
                        <TableCell align="left">
                          <LinearProgress
                            variant="determinate"
                            value={participant.percentage}
                            classes={{
                              barColorPrimary: classes.barColorPrimary,
                              colorPrimary: classes.colorPrimary,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.valueColumn}
                        >
                          {participant.percentage}%
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </SectionMain>
  );
};

export default PartnersPanelComunityDesktop;
