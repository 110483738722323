import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import banner_meditacao from "../../../assets/images/banners/Banner_home.png";
import gplayBadge from "../../../assets/images/google-play-badge.png";
import ButtonMedita from "../../../components/ButtonMedita";
import FooterSection from "../../../components/FooterSection";
import SectionMain from "../../../components/SectionMain";
import Title from "../../../components/Title";
import VideoPlayer from "../../../components/VideoPlayer";
import VideosCard from "../../../components/VideosCard";
import useGoToPage from "../../../hooks/useGoToPage";
import useVideosArray from "../../../hooks/useVideosArray";
import useVideosArrayPremium from "../../../hooks/useVideosArrayPremium";

const useStyles = makeStyles({
  container: { padding: "20px 2vw", justifyContent: "center" },

  title: {
    width: 210,
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 0 30px",
    paddingTop: 10,
  },
  inputs: { padding: 15 },
  cursive: { color: "#008393", letterSpacing: 2 },
  modal: {
    borderRadius: 30,
    border: "solid 1px #d8d8d8",
    maxHeight: "485px",
    padding: "15px 0",
    position: "sticky",
    top: "3vw",
    justifyContent: "center",
  },
  leftSectionCelular: {
    background: "#f8f9fb",
  },
  rightSectionCelular: {
    justifyContent: "center",
    background: `#6D8E89`,
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundSize: "cover",
    width: "100%",
    padding: "20px 50px",
  },
  txtMobile: {
    maxWidth: "96%",
    minWidth: "60%",
    margin: "0 auto",
    display: "block",
    textAlign: "center",
    fontSize: "17px",
  },
  link: {
    fontWeight: "700",
    color: "#fff",
  },
  imageLeftScreens: {
    maxWidth: "90%",
    maxHeight: "500px",
    display: "block",
    margin: "0 auto",
  },
  btnBeneficios: {
    background: "#fff",
  },
  badgeAppStores: {
    cursor: "pointer",
    maxWidth: "200px",
  },
  btnModal: {
    display: "block",
    margin: "20px auto 0",
    width: "290px",
    height: "60px",
  },
});

const VideoPageMobile = () => {
  const videos = useVideosArray().concat(useVideosArrayPremium());
  const goToPage = useGoToPage();
  const classes = useStyles();
  const id = window.location.search.split("?id=").pop();
  const video = videos.find((video) => video.id.includes(id));
  const videosSameCategory = videos.filter(
    ({ category, id }) => category == video.category && video.id !== id
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [videos]);
  return (
    <SectionMain>
      <SectionMain className={classes.container}>
        <VideoPlayer />
      </SectionMain>
      <SectionMain>
        <SectionMain className={classes.register}>
          <SectionMain position="flex-start" direction="column">
            <div className={classes.verticalCenter}>
              <SectionMain noPadding position="center">
                <Title
                  align="center"
                  size="small"
                  fontWeight="800"
                  className={classes.experimente}
                >
                  CONHEÇA O MEDITABEM
                </Title>
              </SectionMain>

              <SectionMain>
                <Title align="center" size="small">
                  Encontre centenas de áudios e vídeos: meditação, aulas de
                  yoga, mantras, exercícios, músicas, conteúdos para crianças e
                  muito mais. <br />
                  <br />
                  <br />
                  Baixe o app, teste grátis e comece a aproveitar agora.
                </Title>
              </SectionMain>

              <ButtonMedita
                onClick={() =>
                  goToPage(
                    "/medita-bem-planos"
                  )
                }
                className={classes.btnModal}
              >
                {"QUERO EXPERIMENTAR"}
              </ButtonMedita>
            </div>
          </SectionMain>
        </SectionMain>
      </SectionMain>

      <SectionMain className={classes.container}>
        <SectionMain className={classes.title}>
          <Title
            fontWeight="800"
            size="medium"
            align="center"
            capitalize="uppercase"
            letterSpacing={2}
          >
            {"RELACIONADOS"}
          </Title>
        </SectionMain>
        <VideosCard
          position={"center"}
          videos={videosSameCategory}
          scrollable={true}
        />
      </SectionMain>

      <SectionMain>
        <SectionMain item xs={12} className={classes.leftSectionCelular}>
          <img
            src={banner_meditacao}
            className={classes.imageLeftScreens}
            alt="Medita app frame mobile"
          />
        </SectionMain>
        <SectionMain item xs={12} className={classes.rightSectionCelular}>
          <Title color="#fff" align="center" className={classes.txtMobile}>
            <Title
              color="#fff"
              align="center"
              fontWeight="700"
              className={classes.titleMobile}
            >
              <h2>Escolher o que faz bem.</h2>
            </Title>
            Tempo para você, para acalmar a mente e focar no que te deixa leve.
            <br />
            <br />
            Baixe o MeditaBem e venha fazer parte dessa deliciosa jornada rumo a
            uma vida mais leve, conectada e feliz.
            <br />
            <br />
            Estilos variados. Temas, professores e conteúdos exclusivos.
            <br />
            <br />
            Pronto para aproveitar um novo universo de meditação e bem-estar?
            <br />
            <br />
            <br />
            <div
              className="flex-row center-b"
              style={{ justifyContent: "center", padding: "15px 0" }}
            >
              <img
                src={gplayBadge}
                className={classes.badgeAppStores}
                onClick={() =>
                  goToPage(
                    "https://play.google.com/store/apps/details?id=com.newmeditabemapp"
                  )
                }
              />
            </div>
          </Title>
        </SectionMain>
      </SectionMain>

      <SectionMain>
        <FooterSection mobile hideNewsletter={true} />
      </SectionMain>
    </SectionMain>
  );
};

export default VideoPageMobile;
